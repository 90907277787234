import React, { useEffect } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import PlayerName from '../../teampro/layout/PlayerName'
import { WithModal } from '../../modal/ModalProvider'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import I18n from 'i18n'
import { translateTrainingMoment, translateTrainingType, translateWeekday } from '../../teampro/Utils'
import moment from 'moment'
import { fullMomentDateFormatWithTime } from '../../common/Constants'
import classNames from 'classnames'
import PinCode from '../../pin_code/PinCode'
import TrainingSessionResultsTable from 'components/training_sessions/layout/TrainingSessionResultsTable'

const EditTrainingSession = (props) => {
  const { match: { params: { trainingSessionId } } } = props
  const trainingSession = props.trainingSession

  useEffect(() => {
    props.fetchTrainingSession(trainingSessionId)
    props.fetchTeams()
  }, [])

  useEffect(() => {
    window.M.updateTextFields()
  })

  if (trainingSession === undefined || props.teams === undefined || props.teams.length === 0) return <></>

  const weekday = translateWeekday(trainingSession.weekday)
  const trainingMoment = translateTrainingMoment(trainingSession.training_moment)
  const trainingType = translateTrainingType(trainingSession.training_type, props.teams)
  const createdAt = moment(trainingSession.created_at).format(fullMomentDateFormatWithTime)
  const scheduledAt = trainingSession.scheduled_at ? moment(trainingSession.scheduled_at).format(fullMomentDateFormatWithTime) : ''
  const addExtraQuestions = trainingSession.add_extra_questions
  const expiresAt = moment(trainingSession.expires_at).format(fullMomentDateFormatWithTime)
  const expired = moment(trainingSession.expires_at) < moment()
  const invitationState = I18n.t(`training_sessions.invitation_states.${trainingSession.invitation_state}`)

  const destroyFillOutSession = id => {
    props.destroyFillOutSession(id)
      .then(() => {
        props.flashMessages.push(
          I18n.t('training_sessions.flash_messages.fill_out_session_deleted'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.INFO)
        props.fetchTrainingSession(trainingSessionId)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const showDestroyFillOutSessionDialog = id => {
    const playerName = trainingSession.fill_out_sessions.filter(fillOutSession => fillOutSession.id === id)[0].player.name
    props.setConfirmationDialog({
      onConfirmation: () => destroyFillOutSession(id),
      target: I18n.t('training_sessions.edit_training_session.destroy_fill_out_session_prompt', { player: playerName }),
      action: 'custom',
      noQuotes: true
    })
  }

  const trainingSessionTimeString = trainingSession => {
    if (trainingSession.scheduled_at) {
      return scheduledAt
    }

    return createdAt
  }

  const trainingSessionString = () => {
    const timeString = trainingSessionTimeString(trainingSession)
    if (trainingSession.invitation_state === 'new') {
      return `${timeString} 🕑`
    }
    return timeString
  }

  const renderFillOutLink = (fillOutSession) => {
    return (
      <span className={fillOutSession.response === null ? 'text-muted' : 'blue-text'}>{I18n.t('training_sessions.edit_training_session.completed_at')}: {moment(fillOutSession.completed_at).format(fullMomentDateFormatWithTime)}</span>
    )
  }

  const showResponse = (fillOutSession) => {
    if (fillOutSession.response === null) return

    props.history.push(`/responses/${fillOutSession.response.id}/edit`)
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, {
          name: I18n.t('menu.training_sessions'),
          link: '/training-sessions'
        }, { name: trainingSessionString(trainingSession) }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('training_sessions.edit_training_session.edit_training_session', { name: trainingSessionString(trainingSession) })}</h4>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='created-at'
                type='text'
                className='validate'
                disabled
                value={createdAt}
              />
              <label htmlFor='created-at'>{I18n.t('training_sessions.new_training_session.created_at')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <input
                id='expires-at'
                type='text'
                className={classNames('validate', expired && 'expired')}
                disabled
                value={expiresAt}
              />
              <label htmlFor='expires-at'>{I18n.t('training_sessions.new_training_session.expires_at')}</label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='weekday'
                type='text'
                className='validate'
                disabled
                value={weekday}
              />
              <label htmlFor='weekday'>{I18n.t('training_sessions.new_training_session.weekday')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <input
                id='training-type'
                type='text'
                className='validate'
                disabled
                value={trainingType}
              />
              <label htmlFor='training-type'>{I18n.t('training_sessions.new_training_session.training_type')}</label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='training-moment'
                type='text'
                className='validate'
                disabled
                value={trainingMoment}
              />
              <label htmlFor='training-moment'>{I18n.t('training_sessions.new_training_session.training_moment')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <p>
                <label>
                  <input
                    type='checkbox'
                    checked={addExtraQuestions}
                    disabled
                  />
                  <span>{I18n.t('training_sessions.new_training_session.add_extra_questions')}</span>
                </label>
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='invitation-state'
                type='text'
                className='validate'
                disabled
                value={invitationState}
              />
              <label
                htmlFor='invitation-state'
              >{I18n.t('training_sessions.new_training_session.invitation_state')}
              </label>
            </div>
            {trainingSession.scheduled_at && (
              <div className='input-field col s12 m6'>
                <input
                  id='scheduled-at'
                  type='text'
                  className='validate'
                  disabled
                  value={scheduledAt}
                />
                <label htmlFor='scheduled-at'>{I18n.t('training_sessions.new_training_session.scheduled_at')}</label>
              </div>
            )}
          </div>
          <TrainingSessionResultsTable trainingSession={trainingSession} />
          <div className='row'>
            <div className='col s12'>
              <ul className='collection with-header'>
                <li className='collection-header'>
                  <h4>{I18n.t('training_sessions.edit_training_session.fill_out_sessions')}</h4>
                </li>
                {trainingSession.fill_out_sessions.length === 0 && (
                  <div className='col s12'>
                    <p><em>{I18n.t('training_sessions.edit_training_session.there_are_no_fill_out_sessions')}</em></p>
                  </div>
                )}
                {[...trainingSession.fill_out_sessions].sort((fA, fB) => sortByCompletedAt(fA, fB)).map(fillOutSession => {
                  const hasResponse = fillOutSession.response !== null
                  return (
                    <li key={fillOutSession.id} className='collection-item'>
                      <div>
                        <div
                          className={classNames('card-clickable', { pointer: hasResponse })}
                          onClick={() => showResponse(fillOutSession)}
                        >
                          <PlayerName player={fillOutSession.player} />
                          <p>
                            {fillOutSession.invited_at.length > 0 && <><span className='text-muted'>{I18n.t('training_sessions.edit_training_session.invited_at')}: {moment(fillOutSession.invited_at).format(fullMomentDateFormatWithTime)}</span><br /></>}
                            {fillOutSession.completed_at.length > 0 && renderFillOutLink(fillOutSession)}
                            {fillOutSession.completed_at.length <= 0 &&
                              <span className='not-yet-completed'>{I18n.t('training_sessions.edit_training_session.not_yet_completed')}</span>}
                          </p>
                        </div>
                        {hasResponse && (
                          <a
                            href={`/responses/${fillOutSession.response.id}/edit`}
                            className='secondary-content margin-left'
                            title={I18n.t('training_sessions.edit_training_session.edit_response')}
                            onClick={e => {
                              e.preventDefault()
                              showResponse(fillOutSession)
                            }}
                          >
                            <i className='material-icons'>edit</i>
                          </a>
                        )}
                        <a
                          href='#!'
                          className='secondary-content'
                          title={I18n.t('training_sessions.edit_training_session.delete_fill_out_session')}
                          onClick={e => {
                            e.preventDefault()
                            showDestroyFillOutSessionDialog(fillOutSession.id)
                          }}
                        >
                          <i className='material-icons'>delete</i>
                        </a>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </PinCode>
    </>
  )
}

const sortByCompletedAt = (fA, fB) => {
  if (!fA.completed_at && !fB.completed_at) {
    return 0
  } if (!fA.completed_at) {
    return -1
  } if (!fB.completed_at) {
    return 1
  }
  return 0
}

export default WithModal(WithFlashMessages(EditTrainingSession))
