import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithModal } from '../../modal/ModalProvider'
import I18n from 'i18n'
import PinCode from '../../pin_code/PinCode'
import TabPanel, { Tab } from 'components/common/TabPanel'
import TrainingSessionsPast from 'components/training_sessions/layout/TrainingSessionsPast'
import TrainingSessionsUpcoming from 'components/training_sessions/layout/TrainingSessionsUpcoming'

const TrainingSessionsOverview = (props) => {
  const [selectedTab, setSelectedTab] = useState('past')

  useEffect(() => {
    props.fetchTeams()
  }, [])

  const changeTab = (tabName) => {
    setSelectedTab(tabName)
    // remove the ?page=1 from the url
    props.history.push(props.location.pathname)
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.training_sessions') }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <TabPanel id='training-sessions-tabs' onShow={changeTab}>
                <Tab id='past' title={I18n.t('training_sessions.tabs.past.title')}>
                  {selectedTab === 'past' && <TrainingSessionsPast {...props} teams={props.teams} />}
                </Tab>
                <Tab id='upcoming' title={I18n.t('training_sessions.tabs.upcoming.title')}>
                  {selectedTab === 'upcoming' && <TrainingSessionsUpcoming {...props} teams={props.teams} />}
                </Tab>
              </TabPanel>
            </div>
          </div>
        </div>
      </PinCode>
    </>
  )
}

export default WithModal(WithFlashMessages(TrainingSessionsOverview))
