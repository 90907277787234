import React from 'react'
import I18n from 'i18n'

export default class EnableConfirm extends React.Component {
  render () {
    return (
      <>
        <p className='center'>{I18n.t('components.2fa.enable.success')}</p>
        <ul>
          {this.props.recovery_codes && this.props.recovery_codes.map(
            (code, index) => (<li key={index} className='center'><span>{code}</span></li>)
          )}
        </ul>
        <p className='center'>
          <button
            id='enable-confirm-submit'
            onClick={() => this.props.transition()}
            className='btn waves-effect waves-light text-background-color'
          >
            {I18n.t('components.2fa.enable.confirm')}
          </button>
        </p>
      </>
    )
  }
}
