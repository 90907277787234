import Teams from '../../common/types/Teams'
import Player from '../../common/types/Player'
import { Response } from '../../common/types/ResponsesPlayerResponsesDTO'
import { compact } from 'lodash'
import moment from 'moment'
import { fullMomentDateFormatWithTime } from '../../common/Constants'
import { average, standardDeviation } from '../../common/Math'
import Param from '../../common/types/Param'
import I18n from 'i18n'
import {
  AccelerationZone,
  HeartRateZone,
  PolarTrainingSession, PowerZone,
  SpeedZone
} from '../../common/types/PolarTrainingSessionsDTO'
import PolarVariable from '../../common/types/PolarVariable'
import FillOutSession from '../../common/types/FillOutSession'

export const findPlayer = (playerId: number, teams: Teams): Player | undefined => {
  let player: Player | undefined
  for (const team of teams) {
    player = team.players.find(cPlayer => cPlayer.id === playerId)
    if (player != null) break
  }
  return player
}

export interface Point {
  x: Date
  y: number
  formatted_date: string
  heart_rate_avg_percent?: number
  distance_meters?: number
  kilo_calories?: number
  sprint_counter?: number
  speed_avg_kmh?: number
  duration_ms?: number
  heart_rate_max?: number
  heart_rate_avg?: number
  heart_rate_min?: number
  heart_rate_max_percent?: number
  heart_rate_min_percent?: number
  speed_max_kmh?: number
  cadence_avg?: number
  cadence_max?: number
  training_load?: number
  cardio_load?: number
  muscle_load?: number
  heart_rate_zones?: HeartRateZone[]
  speed_zones_kmh?: SpeedZone[]
  acceleration_zones_ms2?: AccelerationZone[]
  power_zones?: PowerZone[]
}

interface PlayerData {
  data: Point[]
  nrResponses: number
}

export const getPlayerData = (playerId: number, responses: Response[], params: Param[], showIndividualZScores: boolean, givenPlayerMean?: number, givenPlayerStd?: number): PlayerData => {
  const playerValues: number[] = []
  const data: Point[] = compact(responses.filter(response => response.contents.playerId === playerId).map(response => {
    let value: number | undefined
    let found = false
    for (const param of params) {
      if (Object.keys(response.contents).includes(param)) {
        value = response.contents[param]
        found = true
        break
      }
    }
    if (!found || value === undefined) return null
    const responseDateMoment = moment(response.completed_at)
    playerValues.push(value)
    return {
      x: responseDateMoment.toDate(),
      y: value,
      formatted_date: responseDateMoment.locale(I18n.locale).format(fullMomentDateFormatWithTime)
    }
  }))
  if (playerValues.length > 0) {
    const playerMean = givenPlayerMean ?? average(playerValues)
    const playerStd = givenPlayerStd ?? standardDeviation(playerValues)
    if (showIndividualZScores) {
      if (playerStd > 0.0001) {
        for (const dataPoint of data) {
          dataPoint.y = (dataPoint.y - playerMean) / playerStd
        }
      } else {
        for (const dataPoint of data) {
          dataPoint.y = 0
        }
      }
    }
  }
  return { data: data, nrResponses: playerValues.length }
}

export const getPlayerValue = (fillOutSession: FillOutSession, params: Param[]): number | null => {
  let value: number | undefined
  let found = false
  for (const param of params) {
    if (Object.keys(fillOutSession.response?.contents ?? {}).includes(param)) {
      value = fillOutSession.response?.contents[param]
      found = true
      break
    }
  }
  if (!found || value === undefined) return null
  return value
}

export const getPlayerTrainingSessionData = (playerId: number, polarTrainingSessions: PolarTrainingSession[], params: PolarVariable[], showIndividualZScores: boolean, givenPlayerMean?: number, givenPlayerStd?: number): PlayerData => {
  const playerValues: number[] = []
  const data: Point[] = compact(polarTrainingSessions.filter(polarTrainingSession => polarTrainingSession.player_id === playerId).map(polarTrainingSession => {
    // We only use the first param ever
    const value: number = polarTrainingSession[params[0]]
    const trainingSessionDateMoment = moment(polarTrainingSession.trimmed_start_time)
    playerValues.push(value)
    return {
      x: trainingSessionDateMoment.toDate(),
      y: value,
      formatted_date: trainingSessionDateMoment.locale(I18n.locale).format(fullMomentDateFormatWithTime),
      heart_rate_avg_percent: polarTrainingSession.heart_rate_avg_percent,
      distance_meters: polarTrainingSession.distance_meters,
      kilo_calories: polarTrainingSession.kilo_calories,
      sprint_counter: polarTrainingSession.sprint_counter,
      speed_avg_kmh: polarTrainingSession.speed_avg_kmh,
      duration_ms: polarTrainingSession.duration_ms,
      heart_rate_max: polarTrainingSession.heart_rate_max,
      heart_rate_avg: polarTrainingSession.heart_rate_avg,
      heart_rate_min: polarTrainingSession.heart_rate_min,
      heart_rate_max_percent: polarTrainingSession.heart_rate_max_percent,
      heart_rate_min_percent: polarTrainingSession.heart_rate_min_percent,
      speed_max_kmh: polarTrainingSession.speed_max_kmh,
      cadence_avg: polarTrainingSession.cadence_avg,
      cadence_max: polarTrainingSession.cadence_max,
      training_load: polarTrainingSession.training_load,
      cardio_load: polarTrainingSession.cardio_load,
      muscle_load: polarTrainingSession.muscle_load,
      heart_rate_zones: polarTrainingSession.heart_rate_zones,
      speed_zones_kmh: polarTrainingSession.speed_zones_kmh,
      acceleration_zones_ms2: polarTrainingSession.acceleration_zones_ms2,
      power_zones: polarTrainingSession.power_zones
    }
  }))
  if (playerValues.length > 0) {
    const playerMean = givenPlayerMean ?? average(playerValues)
    const playerStd = givenPlayerStd ?? standardDeviation(playerValues)
    if (showIndividualZScores) {
      if (playerStd > 0.0001) {
        for (const dataPoint of data) {
          dataPoint.y = (dataPoint.y - playerMean) / playerStd
        }
      } else {
        for (const dataPoint of data) {
          dataPoint.y = 0
        }
      }
    }
  }
  return { data: data, nrResponses: playerValues.length }
}

export interface PlotBand {
  color: string
  from: Date | number
  to: Date | number
}

export type Period = 'three_months' | 'six_months' | 'nine_months'
export const PERIODS: Period[] = ['three_months', 'six_months', 'nine_months']
export type GraphType = 'self_efficacy' | 'motivation' | 'mood' | 'performance' | 'enjoyment' | 'intensity' | 'recovery'

interface Graphs {
  self_efficacy: Param[]
  motivation: Param[]
  mood: Param[]
  performance: Param[]
  enjoyment: Param[]
  intensity: Param[]
  recovery: Param[]
}

export const GRAPHS: Graphs = {
  recovery: ['v1', 'v9'],
  self_efficacy: ['v2', 'v10'],
  motivation: ['v3', 'v11'],
  mood: ['v4', 'v12'],
  intensity: ['v5', 'v8', 'v13'],
  performance: ['v6', 'v14'],
  enjoyment: ['v7', 'v15']
}
