import React from 'react'
import Spinner from './Spinner'

interface Props {
  ready: boolean
  children?: React.ReactNode
  failed?: boolean
  transparent: boolean
}

const SpinnerWrapper: React.FunctionComponent<Props> = ({ ready, children, transparent, failed = false }) => {
  return (
    <>
      {!failed &&
        <>
          <Spinner ready={ready} transparent={transparent} />
          {ready && children}
        </>}
    </>
  )
}

export default SpinnerWrapper
