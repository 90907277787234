import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import { useHistory } from 'react-router-dom'
import { WithSession } from '../session/SessionProvider'

// Note: this is not safe to anyone with a web inspector view
const EnterPinCode = (props) => {
  const [pinCode, setPinCode] = useState('')
  const history = useHistory()

  useEffect(() => {
    window.M.updateTextFields()
    props.verify('')
  })

  useEffect(() => {
    if (pinCode.length === 0) return

    props.verify(pinCode)
  }, [pinCode])

  const goBack = () => {
    if (props.goBack) {
      props.goBack()
    } else {
      history.goBack()
    }
  }

  return (
    <>
      <div className='row section'>
        <div className='input-field col s12 m6 push-m3'>
          <input id='pin-code' type='password' value={pinCode} onChange={e => setPinCode(e.target.value)} />
          <label htmlFor='pin-code'>{I18n.t('profile.edit.enter_pin_code')}</label>
          <span className='helper-text'>{I18n.t('pincode.forgot')}</span>
        </div>
      </div>
      <div className='row section'>
        <div className='input-field col s12 m6 push-m3'>
          <button type='submit' className='btn waves-effect waves-light' onClick={goBack}>
            {I18n.t('profile.edit.go_back')}
          </button>
        </div>
      </div>
    </>
  )
}

export default WithSession(EnterPinCode)
