import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import UpdatePassword from './layout/UpdatePassword'

const PasswordRoutes = (props) => {
  const { updatePasswordProps } = props
  return (
    <NotFoundWrapper slug='account'>
      <Route
        exact path='/account'
        render={props => (<UpdatePassword {...props} {...updatePasswordProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default PasswordRoutes
