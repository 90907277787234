import React from 'react'
import I18n from 'i18n'
import { smartTranslate } from '../Utils'

export default class Chips extends React.Component {
  componentDidUpdate (prevProps, prevState, snapshot) {
    const { fieldName, currentValue, defaultValue } = this.props
    const data = currentValue || defaultValue
    const options = {
      data: data ? data.map((elem) => ({ tag: smartTranslate(elem), value: elem })) : [],
      onChipAdd: this.onChipChange.bind(this),
      onChipDelete: this.onChipDelete.bind(this)
    }
    const elem = document.getElementById(fieldName)
    window.M.Chips.init(elem, options)
  }

  onChipChange (e) {
    const instance = window.M.Chips.getInstance(document.getElementById(this.props.fieldName))
    // If there is a value (untranslated original string for the server) we use
    // that. Otherwise it is a user defined tag and we know it has not been
    // translated
    this.props.onChange({ target: { value: instance.chipsData.map(elem => elem.value || elem.tag) } })
  }

  onChipDelete (e, data, a, b, c) {
    this.onChipChange(e)

    const instance = window.M.Chips.getInstance(document.getElementById(this.props.fieldName))
    if (instance.chipsData.length >= 1) {
      instance.selectChip(instance.chipsData.length - 1)
    }
  }

  addChip (e) {
    const instance = window.M.Chips.getInstance(document.getElementById(this.props.fieldName))
    instance.addChip({
      tag: e.target.value
    })

    e.target.value = ''
  }

  handleKeyDown (e) {
    if (e.key === ' ' && e.target.value.trim() !== '') {
      // by default, the spacebar causes the page to scroll down. Prevent this
      e.preventDefault()

      // set value of the form field as a new chip and reset form field's value
      this.addChip(e)
    }

    if (e.key === 'Backspace') {
      console.log(e)
    }

    if (e.key === 'Enter' || e.key === ' ' || e.keyCode === 13) {
      // persist the event such that it is still available after the timeout
      e.persist()
      // add a small timeout because addChip runs asynchronously
      setTimeout(() => e.target.focus(), 50)
    }
  }

  handleLoseFocus (e) {
    this.addChip(e)
  }

  render () {
    const errors = this.props.errors || []
    const { component, type, fieldName, formName } = this.props
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    return (
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            <label htmlFor={`${fieldName}-input`} className='active'>
              {I18n.t(`${formName}.${component}.${type}.${fieldName}`)}
            </label>
            <div className='chips' id={fieldName}>
              <input id={`${fieldName}-input`} className='input' onBlur={this.handleLoseFocus.bind(this)} onKeyDown={this.handleKeyDown.bind(this)} />
            </div>
            {errors.map(err => (
              <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
                {this.props.translateErrors ? I18n.t(err) : err}
              </span>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
