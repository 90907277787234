import React from 'react'
import { MyAxios as axios } from '../MyAxios'

export default class ApiConnector extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.host = '' // Defaults web server host (because of axios)

    // TODO: This is not defined?
    /* eslint-disable no-undef */
    this.file_reader = new FileReader()
    /* eslint-enable no-undef */
  }

  defaultFormProps (formName, extraProps) {
    const props = {
      handleChange: this.handleChange.bind(this),
      handleSubmit: this.handleSubmit.bind(this),
      getFields: this.getFields.bind(this),
      getErrors: this.getErrors.bind(this),
      nextStage: this.nextStage.bind(this),
      getStage: this.getStage.bind(this),
      setStage: this.setStage.bind(this),
      setFieldError: this.setFieldError.bind(this),
      setFormError: this.setFormError.bind(this),
      clearFieldError: this.clearFieldError.bind(this),
      clearErrors: this.clearErrors.bind(this),
      fetch: this.fetch.bind(this),
      reset: this.reset.bind(this),
      error: (this.state[formName] && this.state[formName].error) || '',
      errors: (this.state[formName] && this.state[formName].errors) || {},
      defaults: (this.state[formName] && this.state[formName].defaults) || {},
      values: (this.state[formName] && this.state[formName].fields) || {},
      choices: (this.state[formName] && this.state[formName].choices) || {},
      stage: (this.state[formName] && this.state[formName].stage) || 0,
      pictures: (this.state[formName] && this.state[formName].pictures) || 0
    }
    return { ...props, ...extraProps, formName }
  }

  setFieldError (formName, fieldName, message) {
    if (formName in this.state) {
      const newState = { ...this.state }
      newState[formName].errors[fieldName] = [message]
      this.setState(newState)
    }
  }

  clearErrors (formName) {
    if (formName in this.state) {
      const newState = { ...this.state }
      for (const field in this.state[formName].errors) {
        newState[formName].errors[field] = []
      }
      newState[formName].error = ''
      this.setState(newState)
    }
  }

  clearFieldError (formName, fieldName) {
    if (formName in this.state) {
      const newState = { ...this.state }
      newState[formName].errors[fieldName] = []
      this.setState(newState)
    }
  }

  setFormError (formName, message) {
    if (formName in this.state) {
      const newState = { ...this.state }
      newState[formName].error = message
      this.setState(newState)
    }
  }

  reset (formName) {
    const newState = { ...this.state }
    newState[formName] = { fields: {}, errors: {}, defaults: {}, choices: {} }
    this.setState(newState)
  }

  fetch (formName, endpoints, type, cb = null) {
    const headers = { 'Content-Type': 'application/json', ...this.props.authorizationHeaders() }

    /* function that fetches data from each endpoint and stores it in state */
    const totalEndpoints = Object.keys(endpoints).length
    let fetchedEndpoints = 0
    Object.keys(endpoints).forEach((endpoint) => {
      axios({ method: 'GET', url: this.host + endpoint, headers }).then((response) => {
        const newState = { ...this.state }
        const fields = this.mapApiSchemaToForm(response.data, formName, endpoint)
        if (!(formName in newState)) {
          newState[formName] = { fields: {}, errors: {}, defaults: {}, choices: {} }
        }

        if (type === 'default') {
          Object.keys(fields).forEach((key) => {
            if (endpoints[endpoint].includes(key)) {
              newState[formName].defaults[key] = fields[key]
              newState[formName].fields[key] = fields[key]
            }
          })
        } else if (type === 'choice') {
          Object.keys(fields).forEach((key) => {
            if (endpoints[endpoint].includes(key)) {
              newState[formName].choices[key] = fields[key]
            }
          })
        }
        this.setState(newState, () => {
          fetchedEndpoints += 1
          if (fetchedEndpoints === totalEndpoints && cb) {
            cb()
          }
        })
      }).catch((err) => {
        console.log(err)
        fetchedEndpoints += 1
        if (fetchedEndpoints === totalEndpoints && cb) {
          cb()
        }
      })
    })

    /* execute request for each enpoint */
  }

  getStage (formName) {
    /* check if stage in */
    if (formName in this.state && 'stage' in this.state[formName]) {
      return this.state[formName].stage
    }
    return 0
  }

  nextStage (formName) {
    const newState = { ...this.state }
    const usePreviousState = formName in this.state && 'stage' in this.state[formName]
    newState[formName].stage = usePreviousState ? this.state[formName].stage : 0
    newState[formName].stage += 1
    newState[formName].errors = {}
    this.setState(newState)
  }

  setStage (formName, stage) {
    const newState = { ...this.state }
    if (!(formName in this.state)) {
      newState[formName] = {}
    }
    newState[formName].stage = stage
  }

  handleChange (e, formName, field) {
    const preState = JSON.stringify({ ...this.state })
    /* copy over old form or initialize empty fields/errors. WARNING: THIS IS A REFERENCE NOT AN ACTUAL COPY */
    const newState = { ...this.state }
    if (!(formName in newState)) {
      newState[formName] = { fields: {}, errors: {}, defaults: {}, choices: {} }
    }
    /* if its a checkbox: toggle the value, else take value from the event target */
    if (!('target' in e)) {
      // a materialize select multiple field instance
      newState[formName].fields[field] = e.getSelectedValues()
    } else if (e.target.type === 'checkbox') {
      newState[formName].fields[field] = !newState[formName].fields[field]
    } else {
      newState[formName].fields[field] = e.target.value
    }
    /* clear the errors for the changed field: user started typing so probably knows that the error is there */
    if (newState[formName].errors) {
      newState[formName].errors[field] = []
    }

    /* update the state to reflect the changed field's value */
    if (preState !== JSON.stringify(newState)) {
      this.setState(newState)
    }
  }

  /* returns the field values for the requested form */
  getFields (formName) {
    return (this.state[formName] && this.state[formName].fields) || {}
  }

  /* returns the field errors for the requested form */
  getErrors (formName) {
    return (this.state[formName] && this.state[formName].errors) || {}
  }

  /* submits the field values in the form with <formName>
   *    Parameters:
   *      - e: the event that triggered this submit
   *      - formName: key used to access the forms values from state
   *      - httpMethod: method used to submit the form (see Axios documentation)
   *      - endpoint: the url to where the form data is submitted
   *      - handleSuccess: function that accepts a response. Will be invoked if the API endpoint returns a 2xx status
   */
  async handleSubmit (e, formName, httpMethod, endpoint, handleSuccess, handleError, fieldsToSubmit) {
    e.preventDefault()
    if (this.state[formName] === undefined) return
    const values = JSON.parse(JSON.stringify(this.state[formName]))
    // Remove picture field if it wasn't updated, upload it otherwise
    const imageFields = Object.values(e.currentTarget.form).filter(input => input.accept === 'image/*')
    const imagesToSubmit = []
    for (let i = 0; i < imageFields.length; i++) {
      const input = imageFields[i]
      const isPromise = (this.state[formName] &&
                         this.state[formName].fields &&
                         this.state[formName].fields[input.name] &&
                         typeof this.state[formName].fields[input.name].then === 'function')
      if (isPromise) {
        imagesToSubmit.push(this.state[formName].fields[input.name])
      } else {
        values.fields && delete values.fields[input.name]
      }
    }

    /* Wait for each image upload to complete */
    await Promise.all(imagesToSubmit).then((result) => {
      result.forEach(value => {
        values.fields[value[1]] = value[0].signed_id
      })
    })

    /* update state and submit form data */
    const newState = { ...this.state }
    for (const key in values.fields) {
      if (JSON.stringify(values.fields[key]) === JSON.stringify(values.defaults[key])) {
        delete values.fields[key]
      }
    }
    this.setState(newState)
    if (values.fields === undefined || Object.keys(values.fields).length === 0) {
      return
    }
    const requestBody = this.mapFormToApiSchema(values, formName)
    this.handleRequest(requestBody, httpMethod, endpoint, formName, handleSuccess, handleError)
  }

  /* selects the appropriate formMapper function on a component that extends the ApiConnector.
   * a formMapper function accepts an Object where keys are fieldNames and values are field values.
   * the mapper transforms this 'flat' structure to the structure that is expected by the Api endpoint. */
  mapFormToApiSchema (fields, formName) {
    return fields ? this[`${formName}FormMapper`](fields.fields) : {}
  }

  mapApiSchemaToForm (response, formName, endpoint) {
    return response ? this[`${formName}ApiMapper`](response, endpoint) : {}
  }

  /* performs an Axios request with the given parameters. handleSuccess is invoked with the response as its
   * first argument when the Api returns a 2xx status */
  handleRequest (body, method, endpoint, formName, handleSuccess, handleError) {
    const headers = { 'Content-Type': 'application/json', ...this.props.authorizationHeaders() }
    axios({ method, url: this.host + endpoint, headers, data: body }).then((response) => {
      const newState = { ...this.state }
      newState[formName] = { ...newState[formName], errors: {}, defaults: {}, error: '' } // reset form errors
      this.setState(newState)
      handleSuccess(response)
    }).catch((err) => {
      const newState = {}
      newState[formName] = this.state[formName] || { fields: {}, errors: {}, defaults: {}, choices: {} }
      const errors = { ...newState[formName].errors }
      err.response && err.response.data.errors && err.response.data.errors.forEach(e => Object.keys(e.detail).forEach(k => { errors[k] = e.detail[k] }))
      newState[formName].errors = errors
      newState[formName].error = err.response && err.response.data.error
      handleError(err)
      this.setState(newState)
    })
  }

  render () {
    return (
      <div>Implement me in a subclass</div>
    )
  }
}
