import React from 'react'
import FormFactory from '../../helpers/FormFactory'
import I18n from 'i18n'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'

class SignUp extends FormFactory {
  constructor (props) {
    super(props)
    this.form_fields = {
      email: {
        component: 'field',
        properties: {
          type: 'email'
        }
      },
      password: {
        component: 'field',
        properties: {
          type: 'password'
        }
      },
      password_confirm: {
        component: 'field',
        properties: {
          type: 'password'
        }
      },
      locale: {
        component: 'radio',
        propKey: 'locale',
        properties: {
          id: 'locale',
          name: 'locale',
          type: 'radio',
          choices: [
            { properties: { name: 'english', value: 'en' } },
            { properties: { name: 'dutch', value: 'nl' } }
          ]
        }
      }
    }
  }

  componentDidMount () {
    this.props.setHeight(824)
  }

  handleSuccess (response) {
    this.props.flashMessages.push(
      I18n.t(
        'devise.registrations.signed_up_but_unconfirmed',
        { email: this.props.getFields(this.props.formName).email }
      ),
      this.props.flashMessages.duration.LONG,
      this.props.flashMessages.levels.INFO
    )
    this.props.history.push('/')
  }

  handleError (err) {
    const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
    this.props.flashMessages.push(
      errorTitles,
      this.props.flashMessages.duration.LONG,
      this.props.flashMessages.levels.ERROR)
  }

  render () {
    return (
      <form>
        {this.renderFields()}
        {this.renderSubmit(`${this.props.formName}.submit`, {
          id: 'signup-button',
          className: 'btn waves-effect waves-light'
        })}
      </form>
    )
  }
}

export default WithFlashMessages(SignUp)
