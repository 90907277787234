import React from 'react'
import { Route } from 'react-router'
import VisualizationsOverview from './layout/VisualizationsOverview'
import NotFoundWrapper from '../router/NotFoundWrapper'

const VisualizationsRoutes = props => {
  const { overviewProps } = props
  return (
    <NotFoundWrapper slug='visualizations'>
      <Route
        exact path='/visualizations'
        render={props => (<VisualizationsOverview {...props} {...overviewProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default VisualizationsRoutes
