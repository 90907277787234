import I18n from 'i18n'
import { MyAxios as axios } from './MyAxios'
import moment from 'moment'
require('../../../public/javascripts/translations')

const localeMapping = {
  'en-US': 'en',
  'en-GB': 'en',
  'nl-NL': 'nl',
  'nl-BE': 'nl'
}

const browserLocale = window.navigator.userLanguage || window.navigator.language

/**
 * Default to NL locale if the requester is a headless browser.
 * This is required because for some reason Chromium does not want to change its preferred locale
 * A 'nl' locale is required for testing
 */
const DEFAULT_LOCALE = window.navigator.webdriver ? 'nl' : (browserLocale in localeMapping && localeMapping[browserLocale]) || 'nl'

I18n.locale = DEFAULT_LOCALE
moment.locale(DEFAULT_LOCALE)

const loadLocaleAsync = () => {
  return new Promise((resolve, reject) => {
    const locales = axios.get('/api/v1/locales')
    locales.then((result) => {
      const locale = result.data.current
      if (locale !== null) {
        moment.locale(locale)
        I18n.locale = locale
      } else {
        moment.locale(DEFAULT_LOCALE)
        I18n.locale = DEFAULT_LOCALE
      }
      resolve(I18n)
    })
  })
}

const loadFromGivenLocale = givenLocale => {
  const locale = givenLocale
  if (locale !== null) {
    moment.locale(locale)
    I18n.locale = locale
  } else {
    moment.locale(DEFAULT_LOCALE)
    I18n.locale = DEFAULT_LOCALE
  }
}

export const I18nLoader = {
  load: () => {
    // returns a promise, but since load() doesnt return anything we can just wait
    // for it to be resolved in the background
    loadLocaleAsync()
  },
  loadAsync: () => loadLocaleAsync(),
  loadFromGiven: (givenLocale) => loadFromGivenLocale(givenLocale)
}
