import React, { useEffect } from 'react'
import TabHeader from './TabHeader'

const TabPanel = ({ id, children, swipeable, duration, onShow, responsiveThreshold }) => {
  useEffect(() => {
    window.M.Tabs.init(document.getElementById(id), { swipeable, duration, responsiveThreshold })
  }, [])

  return (
    <div className='row'>
      <div className='col s12'>
        <ul id={id} className='tabs tabs-fixed-width'>
          <TabHeader tabs={children} onShow={onShow} />
        </ul>
      </div>
      {children}
    </div>
  )
}

export default TabPanel
