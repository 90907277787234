import React from 'react'
import NotFoundProvider from './NotFoundProvider'
import LoggedInRedirector from '../login_wrapper/LoggedInRedirector'
import TeamProRoutes from '../teampro/TeamProRoutes'
import TeamsController from '../teams/TeamsController'
import TrainingSessionsController from '../training_sessions/TrainingSessionsController'
import ResponsesController from '../responses/ResponsesController'
import PasswordController from '../password/PasswordController'
import ExportsController from '../exports/ExportsController'
import FillOutSessionController from '../fillout_session/FillOutSessionController'
import VisualizationsController from '../visualizations/VisualizationsController'
import SubAccountController from '../sub_account/SubAccountController'
import CallbackController from 'components/callback/CallbackController'

export default class LoggedInRouter extends React.Component {
  render () {
    return (
      <>
        <NotFoundProvider>
          <LoggedInRedirector />
          <TeamProRoutes />
          <TeamsController />
          <SubAccountController />
          <TrainingSessionsController />
          <ResponsesController />
          <VisualizationsController />
          <PasswordController />
          <ExportsController />
          <FillOutSessionController />
          <CallbackController />
        </NotFoundProvider>
      </>
    )
  }
}
