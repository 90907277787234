import React from 'react'
import I18n from 'i18n'

export const ErrorBoundaryContext = React.createContext()

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  componentDidCatch (error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // TODO: Log to appsignal
    // appsignal(error, info);
    console.log(error)
    console.log(info)
  }

  render () {
    if (this.state.hasError) {
      return (
        <p className='text-s text-muted'>
          {I18n.t(this.props.message)}
        </p>
      )
    }

    return this.props.children
  }
}

export const WithErrorBoundary = (Component, message) => props => {
  return (
    <ErrorBoundary message={message}><Component {...props} /></ErrorBoundary>
  )
}
