import React, { useState } from 'react'
import { MyAxios as axios } from '../MyAxios'
import TrainingSessionsRoutes from './TrainingSessionsRoutes'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'

const TrainingSessionsController = props => {
  const [trainingSessions, setTrainingSessions] = useState(undefined)
  const [trainingSessionsByDates, setTrainingSessionsByDates] = useState(undefined)
  const [pageInfo, setPageInfo] = useState(undefined)
  const [teams, setTeams] = useState([])
  const [trainingSession, setTrainingSession] = useState(undefined)
  const [month, setMonth] = useState(undefined)

  const fetchPastTrainingSessions = (page = 1) => {
    setTrainingSessions(undefined)
    setPageInfo(undefined)
    const result = getPastTrainingSessions(page)
    result
      .then(res => {
        setTrainingSessions(res.data.data)
        setPageInfo(res.data.page)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const fetchPastTrainingSessionsByDates = (startDate, endDate) => {
    setTrainingSessionsByDates(undefined)
    const result = getPastTrainingSessionsByDates(startDate, endDate)
    result
      .then(res => {
        setTrainingSessionsByDates(res.data)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const fetchFutureTrainingSessions = (page = 1) => {
    setTrainingSessions(undefined)
    setPageInfo(undefined)
    const result = getFutureTrainingSessions(page)
    result
      .then(res => {
        setTrainingSessions(res.data.data)
        setPageInfo(res.data.page)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const fetchFutureTrainingSessionsByDates = (startDate, endDate) => {
    setTrainingSessionsByDates(undefined)
    const result = getFutureTrainingSessionsByDates(startDate, endDate)
    result
      .then(res => {
        setTrainingSessionsByDates(res.data)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const fetchTrainingSession = (id) => {
    setTrainingSession(undefined)
    const result = getTrainingSession(id)
    result
      .then(res => { setTrainingSession(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const fetchTeams = () => {
    const result = getTeams()
    result
      .then(res => { setTeams(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const getTeams = () => {
    return axios.get('/api/v1/teams', { headers: { ...props.authorizationHeaders() } })
  }

  const destroyTrainingSession = id => {
    return axios.delete(`/api/v1/training_sessions/${id}`, { headers: { ...props.authorizationHeaders() } })
  }

  const getPastTrainingSessions = page => {
    return axios.get(`/api/v1/training_sessions?past=true&page=${page}`, { headers: { ...props.authorizationHeaders() } })
  }
  const getPastTrainingSessionsByDates = (startDate, endDate) => {
    return axios.get(`/api/v1/training_sessions/by_dates?past=true&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`, { headers: { ...props.authorizationHeaders() } })
  }

  const getFutureTrainingSessions = page => {
    return axios.get(`/api/v1/training_sessions?future=true&page=${page}`, { headers: { ...props.authorizationHeaders() } })
  }
  const getFutureTrainingSessionsByDates = (startDate, endDate) => {
    return axios.get(`/api/v1/training_sessions/by_dates?future=true&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`, { headers: { ...props.authorizationHeaders() } })
  }

  const getTrainingSession = id => {
    return axios.get(`/api/v1/training_sessions/${id}`, { headers: { ...props.authorizationHeaders() } })
  }

  const destroyFillOutSession = id => {
    return axios.delete(`/api/v1/fill_out_sessions/${id}`, { headers: { ...props.authorizationHeaders() } })
  }

  return (
    <TrainingSessionsRoutes
      overviewProps={{
        trainingSessions,
        trainingSessionsByDates,
        pageInfo,
        fetchPastTrainingSessions,
        fetchPastTrainingSessionsByDates,
        fetchFutureTrainingSessions,
        fetchFutureTrainingSessionsByDates,
        teams,
        fetchTeams,
        month,
        setMonth,
        destroyTrainingSession
      }}
      editProps={{
        trainingSession,
        fetchTrainingSession,
        teams,
        fetchTeams,
        destroyFillOutSession
      }}
    />
  )
}

export default WithFlashMessages(WithSession(TrainingSessionsController))
