import React, { useEffect } from 'react'
import Logo from './logo.svg'
import PlayerName from './PlayerName'
import { INDEPENDENT_OF_A_TRAINING, COACH } from '../Constants'
import I18n from 'i18n'
import { WithSession } from '../../session/SessionProvider'
import { Link } from 'react-router-dom'
import Locale from './Locale'
import { WithModal } from '../../modal/ModalProvider'
import { translateTrainingMoment, translateTrainingType, translateWeekday } from '../Utils'
import classNames from 'classnames'

const Header = (props) => {
  useEffect(() => {
    deleteDomObjects('.sidenav-overlay')
    deleteDomObjects('.drag-target')
    const elems = document.querySelectorAll('.sidenav')
    window.M.Sidenav.init(elems, {})
  }, [props.onManagerInterface])

  const renderPlayer = cplayer => {
    let extraProps = {}
    if (props.trainingType && props.setTrainingType) {
      extraProps = {
        setTrainingType: props.setTrainingType,
        trainingType: props.trainingType,
        teams: props.teams
      }
    }
    return (
      <li key={cplayer.id} className='player-selector'>
        <div className='collapsible-header'>
          <PlayerName player={cplayer} {...extraProps} />
        </div>
      </li>
    )
  }

  const getTrainingType = () => {
    if (props.player) {
      return props.getPlayerTrainingType(props.player)
    }
    return props.trainingType
  }

  const getTrainingMoment = () => {
    if (props.player) {
      return props.getPlayerTrainingMoment(props.player)
    }
    return props.trainingMoment
  }

  const hideOverlays = () => {
    document.querySelectorAll('.sidenav-overlay').forEach(sidenav => {
      sidenav.style.display = 'none'
      sidenav.style.opacity = 0
    })
    document.querySelectorAll('body').forEach(body => {
      body.style.overflow = null
    })
  }

  const deleteDomObjects = query => {
    document.querySelectorAll(query).forEach(elem => {
      elem.parentNode.removeChild(elem)
    })
  }

  const logOut = () => {
    hideOverlays()
    props.handleLogout()
  }

  const showLogOutDialog = e => {
    e.preventDefault()
    props.setConfirmationDialog({
      onConfirmation: logOut,
      target: I18n.t('menu.prompt.log_out'),
      action: 'custom',
      noQuotes: true
    })
  }

  const renderMenu = () => {
    if (!props.showOnlyLogOut) {
      return (
        <>
          <li><Link to='/teams' onClick={() => hideOverlays()}>{I18n.t('menu.edit_teams')}</Link></li>
          <li><Link to='/training-sessions' onClick={() => hideOverlays()}>{I18n.t('menu.training_sessions')}</Link>
          </li>
          <li><Link to='/responses' onClick={() => hideOverlays()}>{I18n.t('menu.responses')}</Link></li>
          <li><Link to='/visualizations' onClick={() => hideOverlays()}>{I18n.t('menu.visualizations')}</Link></li>
          <li><Link to='/exports' onClick={() => hideOverlays()}>{I18n.t('menu.export_data')}</Link></li>
          <li><Link to='/account' onClick={() => hideOverlays()}>{I18n.t('menu.account')}</Link></li>
          <li><Link to='/' onClick={showLogOutDialog}>{I18n.t('menu.log_out')}</Link></li>
        </>
      )
    }
    return <li><Link to='/' onClick={showLogOutDialog}>{I18n.t('menu.log_out')}</Link></li>
  }

  return (
    <>
      {props.onManagerInterface && (
        <>
          <nav>
            <div className='nav-wrapper'>
              <div className='row'>
                <div className='col s12'>
                  <span className='brand-logo'>Manager&nbsp;Dashboard</span>
                  <a href='#' data-target='mobile-menu' className='sidenav-trigger'>
                    <i className='material-icons'>menu</i>
                  </a>
                  <ul id='nav-mobile' className='right hide-on-med-and-down'>
                    {renderMenu()}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <ul className='sidenav' id='mobile-menu'>
            {renderMenu()}
          </ul>
        </>
      )}
      {!props.onManagerInterface && props.onGoToManagerInterface && (
        <i className='material-icons settings' onClick={props.onGoToManagerInterface}>settings</i>
      )}
      <div className='container'>
        <div className='row logobar'>
          <div className='col s4 m2 logo'>
            <img src={Logo} alt='' />
          </div>
          <div className='col s8 m4 slogan'>
            <span>{I18n.t('teampro.slogan.part_1')}</span>
            &nbsp;{I18n.t('teampro.slogan.part_2')}&nbsp;
            <span>{I18n.t('teampro.slogan.part_3')}</span>
          </div>
          <div className='col s12 m6 detailed'>
            {(props.trainingType || props.weekday || props.trainingMoment) && (
              <p className='text-m special-margin'>
                {props.trainingType && <span>{translateTrainingType(getTrainingType(), props.teams)} </span>}
                {props.weekday && <><span>{translateWeekday(props.weekday)}</span><br /></>}
                {props.trainingMoment && <span>{translateTrainingMoment(getTrainingMoment())}</span>}
                {props.extraQuestions && props.trainingMoment && getTrainingMoment() !== INDEPENDENT_OF_A_TRAINING && <span> + {I18n.t('teampro.manager.extra_questions')}</span>}
              </p>
            )}
          </div>
        </div>
        <Locale setLocale={props.setLocale} />
        {props.player && props.player.role !== COACH && (
          <div className='row'>
            <div className={classNames('col s12 player-col', { m6: (!props.trainingType || !props.setTrainingType) })}>
              {props.setPlayer && <span className='hint'>{I18n.t('teampro.player.click_below_to_select_different_player')}</span>}
              <ul className='collapsible' onClick={() => props.setPlayer && props.setPlayer(undefined)}>
                {renderPlayer(props.player)}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default WithModal(WithSession(Header))
