import React from 'react'
import Logo from './images/logo.png'
import I18n from 'i18n'

export default class FallbackComponent extends React.Component {
  render () {
    return (
      <div className='fallback-component-wrapper'>
        <div className='fallback-component-inner'>
          <div className='fallback-component'>
            <div id='bg_logo'>
              <img alt='Project RIS' className='logo login-logo' src={Logo} />
            </div>
            <div style={{ paddingTop: '20px' }}>
              <p>{I18n.t(`errors.${this.props.message || 'generic'}`)}</p><br />
              <p><a href='/'>{I18n.t('errors.return')}</a>.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
