import React from 'react'
import I18n from 'i18n'
import { WithSession } from '../session/SessionProvider'
import { WithModal } from '../modal/ModalProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'

import Enable from './layout/enable/Enable'
import EnableConfirm from './layout/enable/EnableConfirm'
import Disable from './layout/disable/Disable'
import DisableConfirm from './layout/disable/DisableConfirm'
import { MyAxios as axios } from '../MyAxios'

/* placeholder component that invokes the first transition */
class InitialState extends React.Component {
  componentDidMount () {
    this.props.transition()
  }

  componentDidUpdate (prevProps) {
    this.props.transition()
  }

  render () {
    return (<></>)
  }
}

class TwoFactor extends React.Component {
  constructor () {
    super()
    this.state = {
      currentState: 'init',
      currentProps: {}
    }
    this.stateMachine = {
      states: {
        init: (props) => (<InitialState {...props} />),
        fetchProvisioningUri: (props) => (<InitialState {...props} />),
        enable: (props) => (<Enable {...props} />),
        enableConfirm: (props) => (<EnableConfirm {...props} />),
        disable: (props) => (<Disable {...props} />),
        disableConfirm: (props) => (<DisableConfirm {...props} />),
        null: (props) => (<></>)
      },
      actions: {
        init: this.initAction.bind(this),
        fetchProvisioningUri: this.fetchProvisioningUriAction.bind(this),
        enable: this.enableAction.bind(this),
        disable: this.disableAction.bind(this),
        enableConfirm: this.enableConfirmAction.bind(this),
        disableConfirm: this.disableConfirmAction.bind(this),
        null: () => {}
      },
      transitions: {
        init: [
          {
            to: 'fetchProvisioningUri',
            condition: (props) => props && Object.keys(props).length > 0 && !props.otp_enabled
          }, {
            to: 'disable',
            condition: (props) => props && Object.keys(props).length > 0 && props.otp_enabled
          }, {
            to: 'null',
            condition: (props) => Object.keys(props).length === 0
          }
        ],
        fetchProvisioningUri: [
          {
            to: 'enable',
            condition: (props) => props && Object.keys(props).length > 0 && props.provisioning_uri
          },
          {
            to: 'null',
            condition: (props) => Object.keys(props).length === 0
          }
        ],
        enable: [
          {
            to: 'enable',
            condition: (props) => props && Object.keys(props).length > 0 && props.recovery_codes.length === 0
          }, {
            to: 'enableConfirm',
            condition: (props) => props && Object.keys(props).length > 0 && props.recovery_codes.length > 0
          }
        ],
        enableConfirm: [
          {
            to: 'null',
            condition: (props) => true
          }
        ],
        disable: [
          {
            to: 'disableConfirm',
            condition: (props) => props && Object.keys(props).length > 0 && props.disabled
          },
          {
            to: 'disable',
            condition: (props) => props && Object.keys(props).length > 0 && !props.disabled
          }
        ],
        disableConfirm: [
          {
            to: 'null',
            condition: (props) => true
          }
        ]
      }
    }
  }

  executeTransition (props, transitions, callback = () => {}) {
    const validTransitions = transitions.filter((transition) => transition.condition(props))
    validTransitions.length && this.setState({ currentState: validTransitions[0].to, currentProps: props }, () => { this.props.refetchSession(); callback() })
  }

  disableAction (props, transitions) {
    axios({
      method: 'POST',
      data: { two_factor_code: props.two_factor_code },
      headers: {
        'Content-Type': 'application/json',
        ...this.props.authorizationHeaders()
      },
      url: '/api/v1/users/disable-two-factor'
    }).then((response) => {
      props.disabled = true
      this.executeTransition(props, transitions)
    }).catch(() => {
      props.disabled = false
      props.error = I18n.t('components.2fa.invalid_otp')
      this.executeTransition(props, transitions)
    })
  }

  enableConfirmAction (props, transitions) {
    this.executeTransition({}, transitions, () => this.props.hide && this.props.hide())
  }

  disableConfirmAction (props, transitions) {
    this.executeTransition({}, transitions, () => this.props.hide && this.props.hide())
  }

  enableAction (props, transitions) {
    axios({
      method: 'POST',
      data: { two_factor_code: props.two_factor_code },
      headers: {
        'Content-Type': 'application/json',
        ...this.props.authorizationHeaders()
      },
      url: '/api/v1/users/validate-two-factor'
    }).then((response) => {
      props.recovery_codes = response.data.otp_backup_codes
      props.error = null
      this.executeTransition(props, transitions)
    }).catch(() => {
      props.recovery_codes = []
      props.error = I18n.t('components.2fa.invalid_otp')
      this.executeTransition(props, transitions)
    })
  }

  fetchProvisioningUriAction (props, transitions) {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...this.props.authorizationHeaders()
      },
      url: '/api/v1/users/enable-two-factor'
    }).then((response) => {
      props.provisioning_uri = response.data.otp_provisioning_uri
      this.executeTransition(props, transitions)
    }).catch(() => {
      this.executeTransition({}, transitions, () => {
        this.props.flashMessages && this.props.flashMessages.push(I18n.t('components.2fa.uri_error'), this.props.flashMessages.duration.SHORT, this.props.flashMessages.levels.ERROR)
        this.props.hide && this.props.hide()
      })
    })
  }

  initAction (props, transitions) {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...this.props.authorizationHeaders()
      },
      url: '/api/v1/users/is-two-factor-enabled'
    }).then((response) => {
      props.otp_enabled = response.data.otp_required_for_login
      this.executeTransition(props, transitions)
    }).catch(() => {
      this.executeTransition({}, transitions, () => {
        this.props.flashMessages && this.props.flashMessages.push(I18n.t('components.2fa.is_enabled_error'), this.props.flashMessages.duration.SHORT, this.props.flashMessages.levels.ERROR)
        this.props.hide && this.props.hide()
      })
    })
  }

  render () {
    const currentState = this.stateMachine.states[this.state.currentState]
    const currentAction = this.stateMachine.actions[this.state.currentState]
    const currentTransitions = this.stateMachine.transitions[this.state.currentState]
    const currentProps = this.state.currentProps
    return currentState({ transition: (data = {}) => { currentAction({ ...currentProps, ...data }, currentTransitions) }, ...currentProps })
  }
}

export default WithSession(WithModal(WithFlashMessages(TwoFactor)))
