import React from 'react'
import { Route } from 'react-router'
import EditTrainingSession from './layout/EditTrainingSession'
import TrainingSessionsOverview from './layout/TrainingSessionsOverview'
import NotFoundWrapper from '../router/NotFoundWrapper'

const TrainingSessionsRoutes = props => {
  const { overviewProps, editProps } = props
  return (
    <NotFoundWrapper slug='training-sessions'>
      <Route
        exact path='/training-sessions'
        render={props => (<TrainingSessionsOverview {...props} {...overviewProps} />)}
      />
      <Route
        exact path='/training-sessions/:trainingSessionId(\d+)/edit'
        render={props => (<EditTrainingSession {...props} {...editProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default TrainingSessionsRoutes
