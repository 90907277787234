import React, { useEffect } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import { WithModal } from '../../modal/ModalProvider'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import I18n from 'i18n'
import {
  changeProperty,
  trainingTypes,
  translateTrainingMoment,
  translateWeekday
} from '../../teampro/Utils'
import moment from 'moment'
import { fullMomentDateFormatWithTime } from '../../common/Constants'
import SyntaxHighlight from '../../common/SyntaxHighlight'
import Spinner from '../../common/Spinner'
import PinCode from '../../pin_code/PinCode'
import { WEEKDAYS } from 'components/teampro/Constants'

const EditResponse = props => {
  const { match: { params: { responseId } } } = props
  const response = props.response

  useEffect(() => {
    props.fetchResponse(responseId)
    props.fetchTeams()
  }, [])

  useEffect(() => {
    window.M.updateTextFields()
  })

  if (response === undefined || props.teams === undefined || props.teams.length === 0) {
    return (
      <>
        <BreadCrumbs
          crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, {
            name: I18n.t('menu.responses'),
            link: '/responses'
          }, { name: responseId }]}
        />
        <div className='container'>
          <Spinner transparent />
        </div>
      </>
    )
  }

  const openedAt = moment(response.opened_at).format(fullMomentDateFormatWithTime)
  const completedAt = moment(response.completed_at).format(fullMomentDateFormatWithTime)
  const trainingMoment = translateTrainingMoment(response.contents.trainingMoment)
  const addExtraQuestions = response.contents.extraQuestions
  const playerName = response.contents.name
  const playerJerseyNumber = response.contents.jerseyNumber

  const destroyResponse = id => {
    props.destroyResponse(id)
      .then(() => {
        props.flashMessages.push(
          I18n.t('responses.flash_messages.response_deleted'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.INFO)
        props.history.push('/responses')
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const showDestroyResponseDialog = id => {
    props.setConfirmationDialog({
      onConfirmation: () => destroyResponse(id),
      target: I18n.t('responses.overview.destroy_response_prompt'),
      action: 'custom',
      noQuotes: true
    })
  }

  const updateResponseProperty = (propertyObj) => {
    props.updateResponse(responseId, { response: propertyObj })
      .then(() => {
        props.flashMessages.push(
          I18n.t('responses.flash_messages.response_updated'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.INFO)
        props.fetchResponse(responseId)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const setWeekday = (newWeekDay) => {
    updateResponseProperty({ weekday: newWeekDay })
  }

  const setTrainingType = (newTrainingType) => {
    updateResponseProperty({ training_type: newTrainingType })
  }

  const responseString = () => {
    return `${response.id}`
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.responses'), link: '/responses' }, { name: responseString() }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('responses.show_response.show_response', { name: responseString() })}</h4>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='opened-at'
                type='text'
                className='validate'
                disabled
                value={openedAt}
              />
              <label htmlFor='opened-at'>{I18n.t('responses.attributes.opened_at')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <input
                id='completed-at'
                type='text'
                className='validate'
                disabled
                value={completedAt}
              />
              <label htmlFor='completed-at'>{I18n.t('responses.attributes.completed_at')}</label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <label className='active'>{I18n.t('responses.attributes.weekday')}</label>
              <select
                className='browser-default'
                id='weekday-selector'
                value={response.contents.weekday}
                onChange={() => changeProperty('weekday-selector', setWeekday)}
              >
                {WEEKDAYS.map(cWeekday => {
                  return (
                    <option key={cWeekday} value={cWeekday}>{translateWeekday(cWeekday)}</option>
                  )
                })}
              </select>
            </div>
            <div className='input-field col s12 m6'>
              <label className='active'>{I18n.t('responses.attributes.training_type')}</label>
              <select
                className='browser-default'
                id='training-type-selector'
                value={response.contents.trainingType}
                onChange={() => changeProperty('training-type-selector', setTrainingType)}
              >
                {trainingTypes(props.teams).map(([cTrainingType, cTrainingTypeLabel]) => {
                  return (
                    <option key={cTrainingType} value={cTrainingType}>{cTrainingTypeLabel}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='training-moment'
                type='text'
                className='validate'
                disabled
                value={trainingMoment}
              />
              <label htmlFor='training-moment'>{I18n.t('responses.attributes.training_moment')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <p>
                <label>
                  <input
                    type='checkbox'
                    checked={addExtraQuestions}
                    disabled
                  />
                  <span>{I18n.t('responses.attributes.add_extra_questions')}</span>
                </label>
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='player-name'
                type='text'
                className='validate'
                disabled
                value={playerName}
              />
              <label htmlFor='player-name'>{I18n.t('responses.attributes.player_name')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <input
                id='player-jersey-number'
                type='text'
                className='validate'
                disabled
                value={playerJerseyNumber}
              />
              <label htmlFor='player-jersey-number'>{I18n.t('responses.attributes.player_jersey_number')}</label>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <label>{I18n.t('responses.attributes.contents')}</label>
              <SyntaxHighlight data={response.contents} />
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <button
                className='waves-effect waves-light btn download-button btn-danger'
                onClick={() => showDestroyResponseDialog(response.id)}
              >
                <i className='material-icons'>delete</i>
                {I18n.t('responses.overview.delete_response')}
              </button>
            </div>
          </div>
        </div>
      </PinCode>
    </>
  )
}

export default WithModal(WithFlashMessages(EditResponse))
