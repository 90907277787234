import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import BreadCrumbs from '../../common/Breadcrumbs'
import { WithSession } from '../../session/SessionProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Step1 from './step_1.png'
import Step2 from './step_2.png'
import Step3 from './step_3.png'
import PinCode from '../../pin_code/PinCode'
import { MyAxios as axios } from '../../MyAxios'
import { fullMomentDateFormatWithTime } from '../../common/Constants'
import moment from 'moment'
import AddToken from './AddToken'
import { WithModal } from '../../modal/ModalProvider'
import Info from '../../common/Info'

const UpdatePassword = props => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [newPasswordValid, setNewPasswordValid] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [duringRefresh, setDuringRefresh] = useState(false)
  const [showHowToUse, setShowHowToUse] = useState(false)
  const [pinCode, setPinCode] = useState('')
  const [pinCode2, setPinCode2] = useState('')
  const [usePinCode, setUsePinCode] = useState(false)
  const [emptyIsAllowed, setEmptyIsAllowed] = useState(false)

  useEffect(() => {
    window.M.updateTextFields()
  })

  const handleUpdatePassword = () => {
    props.onUpdatePassword(oldPassword, newPassword, confirmationPassword)
  }

  useEffect(() => {
    if (props.myProfile.id) {
      setUsePinCode(props.myProfile.use_pin_code)
    }
  }, [props.myProfile])

  // Validate new password
  useEffect(() => {
    setNewPasswordValid(newPassword.length > 0 && confirmationPassword.length > 0 && newPassword === confirmationPassword)
    if (newPassword.length > 0 && confirmationPassword.length > 0 && newPassword !== confirmationPassword) {
      setPasswordError(I18n.t('profile.edit.reset_password.errors.passwords_must_match'))
    } else {
      setPasswordError('')
    }
  }, [newPassword, confirmationPassword])

  useEffect(() => {
    setDuringRefresh(false)
  }, [props.myProfile])

  const apiUrl = () => {
    return `${process.env.HOST_URL}/api/v1/responses/all.json`
  }

  const regenerateApiToken = () => {
    if (duringRefresh) return
    setDuringRefresh(true)
    props.onRegenerateApiToken()
  }

  const updatePinCode = () => {
    if (pinCode.length === 0) return

    props.onUpdateUser({ pin_code: pinCode })
  }

  const updateUsePinCode = (e) => {
    setUsePinCode(e.target.checked)
    props.onUpdateUser({ use_pin_code: e.target.checked })
  }

  useEffect(() => {
    axios.post('/api/v1/pin_codes/check', { pin_code: '' }, {
      headers: { ...props.authorizationHeaders() }
    }).then(res => {
      if (res.data?.verified) {
        setEmptyIsAllowed(true)
      }
    })
  }, [props.myProfile?.use_pin_code])

  const changePinCode = e => {
    setEmptyIsAllowed(e.target.value.length === 0)
    setPinCode(e.target.value)
  }

  const showDestroySubTokenDialog = subToken => {
    props.setConfirmationDialog({
      onConfirmation: () => props.destroyToken(subToken.id),
      target: `sub-account ${subToken.description}`,
      action: 'delete',
      noQuotes: true
    })
  }

  const authorizePolarAccount = `https://auth.polar.com/oauth/authorize?client_id=${process.env.POLAR_CLIENT_ID}&response_type=code&scope=team_read`

  const deauthorizePolarAccount = () => {
    props.setConfirmationDialog({
      onConfirmation: () => props.destroyPolarToken(),
      target: 'Polar account',
      action: 'delete_polar_token',
      noQuotes: true
    })
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.account') }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s6'>
              <p>{I18n.t('profile.edit.logged_in_as')} {props.myProfile.email}</p>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input id='old-password' type='password' value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
              <label htmlFor='old-password'>{I18n.t('profile.edit.reset_password.old_password')}</label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input id='new-password' type='password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
              <label htmlFor='new-password'>{I18n.t('profile.edit.reset_password.new_password')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <input id='confirm-new-password' type='password' value={confirmationPassword} onChange={e => setConfirmationPassword(e.target.value)} />
              <label htmlFor='confirm-new-password'>{I18n.t('profile.edit.reset_password.confirm_new_password')}</label>
              {
                passwordError.length > 0 && (
                  <span className='helper-text' data-error='wrong' data-success='right' style={{ color: 'red' }}>
                    {passwordError}
                  </span>
                )
              }
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <button
                className={`btn waves-effect waves-light ${newPasswordValid ? '' : 'button-disabled'}`}
                disabled={!newPasswordValid}
                onClick={handleUpdatePassword}
              >
                {I18n.t('profile.edit.reset_password.submit')}
              </button>
            </div>
          </div>
          <div className='row section'>
            <div className='col s12'>
              <h5>{I18n.t('profile.edit.pin_code')}</h5>
            </div>
            <div className='row'>
              <div className='input-field col s12 m6'>
                <p>
                  <label>
                    <input type='checkbox' checked={usePinCode} onChange={updateUsePinCode} />
                    <span>{I18n.t('profile.edit.use_pin_code')}</span>
                  </label>
                </p>
              </div>
            </div>
            {usePinCode && (
              <div className='row'>
                <div className='input-field col s12 m7'>
                  <input id='pin-code' className={classNames({ invalid: usePinCode && emptyIsAllowed })} type='password' value={pinCode} onChange={changePinCode} />
                  <label htmlFor='pin-code'>{I18n.t('profile.edit.enter_new_pin_code')}</label>
                </div>
                <div className='input-field col s12 m7'>
                  <input id='pin-code2' className={classNames({ invalid: usePinCode && emptyIsAllowed })} type='password' value={pinCode2} onChange={e => setPinCode2(e.target.value)} />
                  <label htmlFor='pin-code2'>{I18n.t('profile.edit.enter_new_pin_code_again')}</label>
                </div>
                <div className='input-field col s12 m6'>
                  <button type='submit' className='btn waves-effect waves-light' onClick={updatePinCode} disabled={pinCode.length === 0 || pinCode !== pinCode2}>
                    {I18n.t('profile.edit.update_pin_code')}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className='row section'>
            <div className='col s12'>
              <h5>{I18n.t('profile.edit.api_access')}</h5>
            </div>
            <div className='col s12'>
              <p>{I18n.t('profile.edit.username')}:<br /><tt>admin</tt></p>
              <p>{I18n.t('profile.edit.password')}:<br /><tt>{props.myProfile.api_token}</tt>
                <FontAwesomeIcon
                  icon='sync-alt'
                  className={classNames('pointer right-icon', duringRefresh && 'disabled-icon')}
                  onClick={regenerateApiToken}
                  title={I18n.t('profile.edit.regenerate_api_token')}
                />
              </p>
              <p>URL:<br /><tt>{apiUrl()}</tt></p>
            </div>
            <div className='col s12'>
              <button className='waves-effect waves-light btn' onClick={() => setShowHowToUse(!showHowToUse)}>How to use</button>
              {showHowToUse && (
                <ol>
                  <li><p>In Excel, click on Data &rarr; Get Data &rarr; From Other Sources &rarr; From Web</p><img className='full-width-image' src={Step1} /></li>
                  <li><p>Enter the following value in the URL textbox: <tt>{apiUrl()}</tt></p><img className='full-width-image' src={Step2} /></li>
                  <li><p>Click on Basic, then enter as User name <tt>admin</tt> and as Password <tt>{props.myProfile.api_token}</tt> and then click on Connect.</p><img className='full-width-image' src={Step3} /></li>
                </ol>
              )}
            </div>
          </div>
          <div className='row section'>
            <div className='col s12'>
              <h5>{I18n.t('profile.edit.sub_accounts')} <Info text={I18n.t('profile.edit.sub_account_tooltip')} tooltipId='sub-account-tooltip' className='wider-tooltip' /></h5>
            </div>
            <div className='col s12'>
              <table>
                <thead>
                  <tr>
                    <th>{I18n.t('sub_tokens.new_token.description')}</th>
                    <th>{I18n.t('sub_tokens.expires_at')}</th>
                    <th>{I18n.t('sub_tokens.last_used_at')}</th>
                    <th>{I18n.t('sub_tokens.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.myProfile?.sub_tokens && props.myProfile.sub_tokens.map(subToken => (
                    <tr key={subToken.id}>
                      <td>{subToken.description ? subToken.description : <em>{I18n.t('sub_tokens.no_description')}</em>}</td>
                      <td>{subToken.expires_at ? moment(subToken.expires_at).format(fullMomentDateFormatWithTime) : <em>{I18n.t('sub_tokens.never')}</em>}</td>
                      <td>{subToken.last_used_at ? moment(subToken.last_used_at).format(fullMomentDateFormatWithTime) : <em>{I18n.t('sub_tokens.never')}</em>}</td>
                      <td>
                        <a
                          href='#!'
                          className='warning-colored'
                          title={I18n.t('sub_tokens.destroy')}
                          onClick={e => {
                            e.preventDefault()
                            showDestroySubTokenDialog(subToken)
                          }}
                        >
                          <i className='material-icons'>delete</i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {(!props.myProfile?.sub_tokens || props.myProfile.sub_tokens.length === 0) && (
                <p>
                  <em>{I18n.t('sub_tokens.none')}</em>
                </p>
              )}
            </div>
          </div>
          <AddToken createToken={props.createToken} />
          {process.env.POLAR_ENABLED === 'true' && (
            <>
              <div className='row section'>
                <div className='col s12'>
                  <h5>{I18n.t('profile.edit.polar_team_pro_integration')}</h5>
                </div>
              </div>
              <div className='row' style={{ marginBottom: '40px' }}>
                <div className='col s12'>
                  {!props.myProfile.has_polar_token && (
                    <a className='waves-effect waves-light btn' href={authorizePolarAccount} rel='noopener noreferrer'>
                      <i className='material-icons'>link</i>{' '}
                      {I18n.t('profile.edit.authorize_polar_account')}
                    </a>
                  )}
                  {props.myProfile.has_polar_token && (
                    <button
                      className='waves-effect waves-light btn button-accent'
                      onClick={deauthorizePolarAccount}
                      style={{ color: 'white', backgroundColor: 'crimson' }}
                    >
                      <i className='material-icons'>delete</i>{' '}
                      {I18n.t('profile.edit.deauthorize_polar_account')}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </PinCode>
    </>
  )
}

export default WithModal(WithSession(UpdatePassword))
