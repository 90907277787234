import React, { useEffect, useState } from 'react'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import PlayerInterface from './layout/PlayerInterface'
import ManagerInterface from './layout/ManagerInterface'
import { COACH } from './Constants'
import Header from './layout/Header'
import { trainingMoments } from './Utils'
import { WithSession } from '../session/SessionProvider'
import Spinner from '../common/Spinner'
import { MyAxios as axios } from '../MyAxios'
import { I18nLoader } from '../I18nLoader'
import I18n from 'i18n'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'
import PinCode, { PIN_CODE } from '../pin_code/PinCode'

const useForceUpdate = () => {
  const [, setValue] = useState(0) // integer state
  return () => setValue(value => value + 1) // update the state to force render
}

const TeamProDashboard = (props) => {
  const [teams, setTeams] = useState([])
  const [onManagerInterface, setOnManagerInterface] = useState(true)
  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [player, setPlayer] = useState(undefined)
  const [trainingType, setTrainingType] = useState(undefined)
  const [weekday, setWeekday] = useState(undefined)
  const [trainingMoment, setTrainingMoment] = useState(undefined)
  const [responses, setResponses] = useState([])
  const [extraQuestions, setExtraQuestions] = useState(false)
  const [trainingSessionId, setTrainingSessionId] = useState(undefined)

  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const result = getTeams()
    result.then(res => { setTeams(res.data) })
  }, [])

  useEffect(() => {
    window.onbeforeunload = () => process.env.NODE_ENV === 'development' ? null : true
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  const getTeams = () => {
    return axios.get('/api/v1/teams', { headers: { ...props.authorizationHeaders() } })
  }

  useEffect(() => {
    // debugging
    if (process.env.NODE_ENV === 'development') {
      /*
      if (teams.length === 0) return
      setSelectedPlayers([teams[0].players[0], teams[0].players[1], teams[0].players[2]])
      setTrainingType('rehab_combi')
      setWeekday('wednesday')
      setTrainingMoment('independent_of_a_training')
      setExtraQuestions(false)
      setOnManagerInterface(false)
      setPlayer(teams[0].players[0])
      */
    }
  }, [teams])

  if (!props.myProfile || Object.keys(props.myProfile).length === 0) return <Spinner transparent />

  const createResponseOnBackend = (cPlayer, values, cb) => {
    const response = {
      opened_at: values.openedAt,
      completed_at: values.completedAt,
      contents: values
    }
    axios({
      method: 'POST',
      url: '/api/v1/responses',
      data: { response },
      headers: { ...props.authorizationHeaders() }
    }).then((_response) => {
      setResponses([...responses, values])
      props.flashMessages.push(
        I18n.t('teampro.player.answer_stored'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO
      )
      if (cPlayer.trainingType === undefined) {
        cPlayer.trainingType = trainingType
      }
      cPlayer.completed = true
      window.scrollTo(0, 0)
      setPlayer(undefined)
      if (cPlayer.role === COACH) {
        setOnManagerInterface(true)
      }
    }).catch(err => {
      console.error('Error creating response:')
      props.flashMessages.push(
        I18n.t('teampro.player.something_went_wrong_saving_questionnaire'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.ERROR
      )
      console.log(err)
      if (cb) cb()
    })
  }

  const setLocale = async (newLocale) => {
    const user = { locale: newLocale }
    axios({
      method: 'PATCH',
      url: '/api/v1/users',
      data: { user },
      headers: { ...props.authorizationHeaders() }
    }).then((_response) => {
      I18nLoader.loadAsync().then((_I18n) => {
        forceUpdate()
      })
    }).catch(err => {
      console.error('Error updating locale:')
      console.log(err)
    })
  }

  const handleQuestionnaireCompletion = (cPlayer, values, cb) => {
    if (cPlayer.completed) return
    createResponseOnBackend(cPlayer, values, cb)
  }

  const handleTogglePlayerSelection = (cPlayer) => {
    if (selectedPlayers.includes(cPlayer)) {
      setSelectedPlayers(selectedPlayers.filter(aplayer => aplayer !== cPlayer))
    } else {
      setSelectedPlayers([...selectedPlayers, cPlayer])
    }
  }

  const deSelectPlayers = (players) => {
    const newSelectedPlayers = selectedPlayers.filter(aplayer => !players.includes(aplayer))
    if (newSelectedPlayers.length !== selectedPlayers.length) {
      setSelectedPlayers(newSelectedPlayers)
    }
  }

  const selectPlayers = (players) => {
    const playersToAdd = players.filter(aplayer => !selectedPlayers.includes(aplayer))
    if (playersToAdd.length) {
      setSelectedPlayers([...selectedPlayers, ...playersToAdd])
    }
  }

  // TODO: refactor into something more generic for overriding player properties
  const getPlayerTrainingType = cPlayer => {
    if (cPlayer.trainingType === undefined) {
      return trainingType
    }
    return cPlayer.trainingType
  }

  const getPlayerTrainingMoment = cPlayer => {
    if (cPlayer.trainingMoment === undefined) {
      return trainingMoment
    }
    return cPlayer.trainingMoment
  }

  const setPlayerTrainingType = (cPlayer, cTrainingType) => {
    if (cPlayer.completed) {
      // don't do anything
    } else if (cTrainingType === trainingType) {
      cPlayer.trainingType = undefined
    } else {
      cPlayer.trainingType = cTrainingType
    }
  }

  const setPlayerTrainingMoment = (cPlayer, cTrainingMoment) => {
    if (cPlayer.completed) {
      // don't do anything
    } else if (cTrainingMoment === trainingMoment) {
      cPlayer.trainingMoment = undefined
    } else {
      cPlayer.trainingMoment = cTrainingMoment
    }
  }

  const getDisabledQuestionnaireKeys = cPlayer => {
    const team = teams.find(cTeam => cTeam.id === cPlayer.team_id)
    if (!team) {
      return []
    }

    return team.disabled_questionnaire_keys
  }

  const loadCoachQuestionnaire = () => {
    window.scrollTo(0, 0)
    setPlayer({ id: 0, jersey_number: '-1', name: 'Coach', role: COACH, trainingType: 'coaching', team_id: 0 })
    setOnManagerInterface(false)
  }

  const showCoachQuestionnaireButton = () => {
    return responses.filter(response => response.role === COACH).length === 0
  }

  const createTrainingSession = (scheduledDates) => {
    const trainingSession = {
      training_session: {
        weekday,
        training_moment: trainingMoment,
        training_type: trainingType,
        add_extra_questions: extraQuestions,
        players: selectedPlayers.filter(selectedPlayer => !selectedPlayer.completed).map(selectedPlayer => selectedPlayer.id),
        scheduled_at: scheduledDates
      }
    }
    axios.post('/api/v1/training_sessions', trainingSession, { headers: { ...props.authorizationHeaders() } }).then(obj => {
      props.flashMessages.push(
        I18n.t('teampro.manager.training_session_created'),
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.INFO
      )
      props.history.push(`/training-sessions/${obj.data.result[0].instance.id}/edit`)
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const createEmptyTrainingSession = () => {
    const trainingSession = {
      training_session: {
        weekday,
        training_moment: trainingMoment,
        training_type: trainingType,
        add_extra_questions: extraQuestions,
        players: selectedPlayers.map(selectedPlayer => selectedPlayer.id),
        scheduled_at: [new Date().toISOString()],
        invitation_state: 'finished_processing'
      }
    }
    axios.post('/api/v1/training_sessions', trainingSession, { headers: { ...props.authorizationHeaders() } }).then(obj => {
      setTrainingSessionId(obj.data.result[0].instance.id)
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const sendInvites = (scheduledDates) => {
    window.scrollTo(0, 0)
    createTrainingSession(scheduledDates)
  }

  const renderBody = () => {
    return (
      <div className='row selectors'>
        <div className='col s12'>
          {onManagerInterface && (
            <PinCode goBack={() => setOnManagerInterface(false)} afterVerifyCallback={forceUpdate}>
              <ManagerInterface
                teams={teams}
                selectedPlayers={selectedPlayers}
                trainingType={trainingType}
                trainingMoment={trainingMoment}
                weekday={weekday}
                onTogglePlayerSelection={cPlayer => handleTogglePlayerSelection(cPlayer)}
                selectPlayers={players => selectPlayers(players)}
                deSelectPlayers={players => deSelectPlayers(players)}
                setTrainingType={cTrainingType => setTrainingType(cTrainingType)}
                setWeekday={cWeekday => {
                  setTrainingMoment(undefined)
                  setWeekday(cWeekday)
                }}
                trainingMoments={trainingMoments(weekday)}
                setTrainingMoment={cTrainingMoment => setTrainingMoment(cTrainingMoment)}
                extraQuestions={extraQuestions}
                setExtraQuestions={value => setExtraQuestions(value)}
                responses={responses}
                showCoachQuestionnaireButton={showCoachQuestionnaireButton()}
                loadCoachQuestionnaire={() => loadCoachQuestionnaire()}
                onDone={() => {
                  window.scrollTo(0, 0)
                  window.localStorage.removeItem(PIN_CODE)
                  setOnManagerInterface(false)
                  createEmptyTrainingSession()
                }}
                onSendInvites={sendInvites}
              />
            </PinCode>
          )}
          {!onManagerInterface && (
            <PlayerInterface
              selectedPlayers={selectedPlayers}
              trainingType={trainingType}
              trainingMoment={trainingMoment}
              trainingSessionId={trainingSessionId}
              weekday={weekday}
              onQuestionnaireCompletion={(cPlayer, values, cb) => handleQuestionnaireCompletion(cPlayer, values, cb)}
              setPlayer={cPlayer => { window.scrollTo(0, 0); setPlayer(cPlayer) }}
              player={player}
              disabledQuestionnaireKeys={cPlayer => getDisabledQuestionnaireKeys(cPlayer)}
              onGoBack={() => setOnManagerInterface(true)}
              getPlayerTrainingType={cPlayer => getPlayerTrainingType(cPlayer)}
              setPlayerTrainingType={(cPlayer, cTrainingType) => setPlayerTrainingType(cPlayer, cTrainingType)}
              getPlayerTrainingMoment={cPlayer => getPlayerTrainingMoment(cPlayer)}
              setPlayerTrainingMoment={(cPlayer, cTrainingMoment) => setPlayerTrainingMoment(cPlayer, cTrainingMoment)}
              extraQuestions={extraQuestions}
              teams={teams}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className='team-pro'>
      <Header
        trainingType={trainingType}
        weekday={weekday}
        trainingMoment={trainingMoment}
        player={player}
        setPlayer={newPlayer => setPlayer(newPlayer)}
        onManagerInterface={onManagerInterface}
        onGoToManagerInterface={() => { setPlayer(undefined); setOnManagerInterface(true) }}
        getPlayerTrainingType={cPlayer => getPlayerTrainingType(cPlayer)}
        getPlayerTrainingMoment={cPlayer => getPlayerTrainingMoment(cPlayer)}
        teams={teams}
        extraQuestions={extraQuestions}
        setLocale={setLocale}
        showOnlyLogOut={props.myProfile?.use_pin_code && !window.localStorage.getItem(PIN_CODE)}
      />
      <div className='container'>
        <div className='row no-margin'>
          <div className='col s12'>
            <div className='divider' />
          </div>
        </div>
        {renderBody()}
      </div>
    </div>
  )
}

export default WithSession(WithFlashMessages(TeamProDashboard))
