import React, { useEffect } from 'react'
import { changeProperty, iconForRole, trainingTypes, translateTrainingMoment } from '../Utils'

const PlayerName = (props) => {
  useEffect(() => {
    const elem = document.getElementById(`playername-selector-${props.player.id}`)
    window.M.FormSelect.init(elem)
  }, [props.player.id])

  const renderIcon = () => {
    if (props.completed) {
      return <i className='material-icons completed'>check</i>
    }
    return <span className={`role ${props.player.role}`}>{iconForRole(props.player.role)}</span>
  }
  const trainingType = props.player.trainingType ? props.player.trainingType : props.trainingType
  const trainingMoment = props.player.trainingMoment ? props.player.trainingMoment : props.trainingMoment

  return (
    <div className='player-name'>
      {renderIcon()}
      <span className='jersey-number'>{props.player.jersey_number !== '-1' ? props.player.jersey_number : ''}</span>&nbsp;
      {props.player.name}
      {props.setTrainingMoment && (
        <select
          className='browser-default'
          id={`playername-moment-selector-${props.player.id}`}
          defaultValue={trainingMoment}
          onChange={() => changeProperty(`playername-moment-selector-${props.player.id}`, props.setTrainingMoment)}
        >
          {props.trainingMoments.map(cTrainingMoment => {
            return (
              <option key={cTrainingMoment} value={cTrainingMoment}>{translateTrainingMoment(cTrainingMoment)}</option>
            )
          })}
        </select>
      )}
      {props.setTrainingType && (
        <select
          className='browser-default'
          id={`playername-selector-${props.player.id}`}
          defaultValue={trainingType}
          onChange={() => changeProperty(`playername-selector-${props.player.id}`, props.setTrainingType)}
        >
          {trainingTypes(props.teams).map(([cTrainingType, cTrainingTypeLabel]) => {
            return (
              <option key={cTrainingType} value={cTrainingType}>{cTrainingTypeLabel}</option>
            )
          })}
        </select>
      )}
    </div>
  )
}

export default PlayerName
