import _ from 'lodash'

export function round (number, decimalPlaces = 0) {
  return Math.round(number * 10 ** decimalPlaces) / 10 ** decimalPlaces
}

export function smartRounding (value, decimalPlaces = 0) {
  const smartDecimalPlaces = (value % 1 !== 0 || decimalPlaces < 1) ? decimalPlaces : decimalPlaces - 1
  return round(value, smartDecimalPlaces)
}

export function unCorrectedStandardDeviation (values) {
  const avg = average(values)

  const squareDiffs = values.map(function (value) {
    const diff = value - avg
    const sqrDiff = diff * diff
    return sqrDiff
  })

  const avgSquareDiff = average(squareDiffs)

  const stdDev = Math.sqrt(avgSquareDiff)
  return stdDev
}

export function standardDeviation (numbersArr) {
  let total = 0
  for (const key in numbersArr) { total += numbersArr[key] }
  const meanVal = total / numbersArr.length
  let SDprep = 0
  for (const key2 in numbersArr) { SDprep += Math.pow((parseFloat(numbersArr[key2]) - meanVal), 2) }
  const SDresult = Math.sqrt(SDprep / (numbersArr.length - 1))
  return SDresult
}

export function average (data) {
  const sum = data.reduce(function (sum, value) {
    return sum + value
  }, 0)

  const avg = sum / data.length
  return avg
}

export const movingAverageKernel = (windowSize) => {
  return function (v, index, array) {
    return _.slice(array, Math.max(0, index + 1 - windowSize), index + 1)
  }
}

export const movingAverage = (array, windowSize) => {
  return _.chain(array)
    .map(movingAverageKernel(windowSize))
    .map(_.mean)
    .value()
}

export function bin (array, binSize) {
  const totalBins = Math.floor(array.length / binSize)
  const bins = []
  for (let iBin = 0; iBin < totalBins; iBin++) {
    const bin = _.slice(array, iBin * binSize, (iBin + 1) * binSize)
    bins.push(bin)
  }

  // Add remaining elements to last bin
  if (array.length % binSize !== 0) {
    const remainingElements = _.slice(array, totalBins * binSize)
    bins[totalBins - 1].push(...remainingElements)
  }

  return bins
}

export function binnedAverage (array, binSize) {
  const bins = bin(array, binSize)
  return bins.map((bin) => { return (average(bin)) })
}

export function sum (arr) {
  return arr.reduce((a, b) => a + b, 0)
}

export function unique (arr) {
  return arr.filter((v, i, s) => s.indexOf(v) === i)
}

/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export function getWeekNumber (d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo]
}

/* Divides an array (data) into zones according to the limits (limits) and returns the number of counts per zone.
 * Limits is an array of floats determining the bounds of each zone. The first value is the lower bound of the first
 * zone. The last value is the higher bound of the last zone. E.g. for a 3 zone division between 60% and 100% limits
 * could look like:
 * [60, 70, 80, 100]
 * Lower bounds are inclusive and upper bounds exclusive.
 */
export function getZoneCount (limits, data) {
  const zoneCount = []
  for (let idx = 1; idx < limits.length; idx += 1) {
    zoneCount.push(data.filter(d => d >= limits[idx - 1] && d < limits[idx]).length)
  }
  return zoneCount
}

export function getCumulativeDistribution (distributions) {
  const cumulative = [0]
  distributions.forEach(v => {
    cumulative.push(cumulative[cumulative.length - 2] + v)
  })
  cumulative.shift()
  return cumulative
}

// Gets an array were each element represents the counts of a bin an normalizes the counts such that they add up to 100%
export function normalizeHistogram (histogram) {
  const totalSamples = _.sum(histogram) || 1
  return histogram.map(h => h / totalSamples * 100)
}
