import React, { useEffect, useMemo } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import DownloadCSV from '../../teampro/layout/DownloadCSV'
import { questionnaires } from '../../teampro/Constants'
import ExportQuestionnaires from './ExportQuestionnaires'
import I18n from 'i18n'
import PinCode from '../../pin_code/PinCode'
import ExportRestructured from 'components/exports/layout/ExportRestructured'
import ExportPolarData from 'components/exports/layout/ExportPolarData'
import ExportCombined from 'components/exports/layout/ExportCombined'

const ExportData = props => {
  useEffect(() => {
    props.fetchTeams()
  }, [])

  const questionnaireData = useMemo(() => Object.values(questionnaires).flat(), [])
  const playerData = useMemo(() => {
    const result = []
    for (const team of props.teams) {
      for (const player of team.players) {
        result.push({
          id: player.id,
          jerseyNumber: (player.jersey_number === '-1' ? '' : player.jersey_number),
          name: player.name,
          role: player.role,
          teamId: team.id,
          teamName: team.name
        })
      }
    }
    return result
  }, [props.teams])
  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.export_data') }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h5>{I18n.t('exports.export_data')}</h5>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <DownloadCSV data={questionnaireData} filename='questionnaire_definitions' name={I18n.t('exports.questionnaire_definitions')} />
              {playerData.length > 0 && <DownloadCSV data={playerData} filename='teams_and_players' name={I18n.t('exports.teams_and_players')} />}
              <ExportPolarData />
            </div>
          </div>
          <ExportQuestionnaires export={props.exportQuestionnaires} />
          <ExportRestructured export={props.exportRestructured} />
          <ExportCombined export={props.exportCombined} />
        </div>
      </PinCode>
    </>
  )
}

export default ExportData
