import React from 'react'
import { Route } from 'react-router'
import ResponsesOverview from './layout/ResponsesOverview'
import EditResponse from './layout/EditResponse'
import NotFoundWrapper from '../router/NotFoundWrapper'

const ResponsesRoutes = props => {
  const { overviewProps, editProps } = props
  return (
    <NotFoundWrapper slug='responses'>
      <Route
        exact path='/responses'
        render={props => (<ResponsesOverview {...props} {...overviewProps} />)}
      />
      <Route
        exact path='/responses/:responseId(\d+)/edit'
        render={props => (<EditResponse {...props} {...editProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default ResponsesRoutes
