import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'

import TeamProDashboard from './TeamProDashboard'

const TeamProRoutes = (props) => {
  const { teamProDashboardProps } = props
  return (
    <NotFoundWrapper slug='team-pro'>
      <Route exact path='/' render={props => <TeamProDashboard {...teamProDashboardProps} {...props} />} />
    </NotFoundWrapper>
  )
}

export default TeamProRoutes
