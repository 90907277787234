import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import SubAccount from './SubAccount'

const SubAccountRoutes = props => {
  const { fillOutProps } = props
  return (
    <NotFoundWrapper slug='sub-account'>
      <Route
        exact path='/sub-account/:subTokenId([a-z0-9]+)/:subTokenToken([a-z0-9\-]+)'
        render={props => (<SubAccount {...props} {...fillOutProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default SubAccountRoutes
