import React from 'react'
import I18n from 'i18n'
import QRCode from 'qrcode.react'

export default class Enable extends React.Component {
  getCode () {
    return document.getElementById('2fa-code').value
  }

  render () {
    return (
      <div className='center row'>
        <div className='col s0 l3' />
        <div className='col s12 l6'>
          <p>{I18n.t('components.2fa.enable.title')}</p>
          <p>
            {this.props.provisioning_uri && (<QRCode value={this.props.provisioning_uri} size={256} />)}
          </p>
          {this.props.error && (<p>{this.props.error}</p>)}
          <div className='input-field'>
            <label htmlFor='2fa-code'>
              <span>{I18n.t('components.2fa.enable.label')}</span>
            </label>
            <input
              type='text'
              id='2fa-code'
              autoFocus
              onKeyPress={(e) => e.key === 'Enter' && this.props.transition({ two_factor_code: this.getCode() })}
            />
          </div>
          <div>
            <button
              id='enable-submit'
              onClick={() => this.props.transition({ two_factor_code: this.getCode() })}
              className='btn waves-effect waves-light text-background-color'
            >
              {I18n.t('components.2fa.enable.submit')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}
