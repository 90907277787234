import React, { useRef, useState } from 'react'
import Slider from './Slider'
import Radio from './Radio'
import Checkbox from './Checkbox'
import Textfield from './Textfield'
import Battery from './Battery'
import { scrollToRef } from '../Utils'

const Question = (props) => {
  const myRef = useRef(null)
  if (props.scrollTo) {
    setTimeout(() => scrollToRef(myRef), 50)
  }

  const [showErrors, setShowErrors] = useState(undefined)

  const { showErrors: propsShowErrors, type, ...theRest } = props
  if (propsShowErrors && !showErrors) {
    setShowErrors(true)
  }
  const sharedProps = {
    showErrors,
    setShowErrors: value => setShowErrors(value),
    myRef
  }
  switch (type) {
    case 'slider':
      return <Slider {...theRest} {...sharedProps} />
    case 'radio':
      return <Radio {...theRest} {...sharedProps} />
    case 'checkbox':
      return <Checkbox {...theRest} {...sharedProps} />
    case 'textfield':
      return <Textfield {...theRest} {...sharedProps} />
    case 'battery':
      return <Battery {...theRest} {...sharedProps} />
    default:
      console.error(`Unknown question type: ${type}`)
      return <></>
  }
}

export default Question
