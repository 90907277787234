import React from 'react'
import I18n from 'i18n'

// This can be extended to other actions (like edit, leave group ...)
const validActions = Object.freeze(['delete', 'leave', 'reject_connection', 'reject_group', 'delete_connection', 'delete_polar_token', 'delete_group', 'stop_protocol', 'custom'])

function validateAction (action, actionType) {
  const defaultAction = validActions[0]
  if (validActions.indexOf(action) < 0) {
    console.warn(`Action '${action}' is not recognized, defaulting to '${defaultAction}'`)
    action = defaultAction
  }
  return I18n.t(`components.confirmation_dialog.${actionType}.${action}`)
}

class ConfirmationDialog extends React.Component {
  onConfirmation () {
    this.props.onConfirmation()
    this.props.onClose()
  }

  render () {
    const actionBefore = validateAction(this.props.action, 'action_before')
    const actionAfter = validateAction(this.props.action, 'action_after')
    let targetString = `'${this.props.target}'`
    if (this.props.noQuotes) {
      targetString = `${this.props.target}`
    }
    return (
      <div id='confirmation-dialog' className='custom-modal-wrapper'>
        <div className='confirmation-dialog'>
          <div className='confirmation-dialog-title text-xl text-primary-color text-heavy text-center'>
            {`${I18n.t('components.confirmation_dialog.title')} ${actionBefore} ${targetString}${actionAfter.length > 0 ? ` ${actionAfter}` : ''}?`}
          </div>
          <div className='confirmation-dialog-buttons-wrapper'>
            <button
              id='confirmation-dialog-confirm'
              className='waves-effect waves-light btn'
              onClick={this.onConfirmation.bind(this)}
              style={{ minWidth: '150px' }}
            >
              {I18n.t('components.confirmation_dialog.confirm')}
            </button>
            <button
              id='confirmation-dialog-cancel'
              className='waves-effect waves-light btn'
              onClick={this.props.onClose}
              style={{ minWidth: '150px' }}
            >
              {I18n.t('components.confirmation_dialog.cancel')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmationDialog
