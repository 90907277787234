import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import { changeProperty } from '../../teampro/Utils'
import { ROLES, LOCALES } from '../../teampro/Constants'
import I18n from 'i18n'
import PinCode from '../../pin_code/PinCode'
import { WithModal } from 'components/modal/ModalProvider'
import PolarPlayerSelect from 'components/teams/layout/PolarPlayerSelect'

const EditPlayer = (props) => {
  const { match: { params: { playerId } } } = props
  let player
  let team
  for (const cTeam of props.teams) {
    const players = cTeam.players.filter(cPlayer => `${cPlayer.id}` === playerId)
    if (players.length > 0) {
      player = players[0]
      team = props.teams.filter(ccTeam => ccTeam.id === player.team_id)[0]
      break
    }
  }
  const [name, setName] = useState('')
  const [jerseyNumber, setJerseyNumber] = useState('')
  const [role, setRole] = useState('')
  const [locale, setLocale] = useState('')
  const [teamId, setTeamId] = useState('')
  const [mobilePhone, setMobilePhone] = useState('')
  const [selectedPolarPlayerIds, setSelectedPolarPlayerIds] = useState([])

  useEffect(() => {
    props.fetchTeams()
    props.fetchPolarPlayers()
  }, [])

  useEffect(() => {
    const elems = document.querySelectorAll('.fixed-action-btn')
    window.M.FloatingActionButton.init(elems, {})
  }, [])

  useEffect(() => {
    window.M.updateTextFields()
  })

  useEffect(() => {
    if (player === undefined) return
    setName(player.name)
    setJerseyNumber(player.jersey_number === '-1' ? '' : player.jersey_number)
    setRole(player.role)
    setLocale(player.locale)
    setMobilePhone(player.mobile_phone)
    setTeamId(player.team_id)
    setSelectedPolarPlayerIds(player.polar_players.map(polarPlayer => polarPlayer.id))
  }, [props.teams])

  if (player === undefined) return <></>

  const updatePlayer = e => {
    e.preventDefault()
    props.updatePlayer(player.id, { player: { jersey_number: jerseyNumber, name, role, locale, mobile_phone: mobilePhone, team_id: teamId, polar_player_ids: selectedPolarPlayerIds } })
      .then(() => {
        props.flashMessages.push(
          I18n.t('teams.flash_messages.player_updated'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.INFO)
        props.fetchTeams()
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.teams'), link: '/teams' },
          { name: team.name, link: `/teams/${team.id}/edit` }, { name: player.name }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('teams.edit_player.edit_player', { name: player.name })}</h4>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='name'
                type='text'
                className='validate'
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <label htmlFor='name'>{I18n.t('teams.new_player.name')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <input
                id='jersey-number'
                type='text'
                className='validate'
                value={jerseyNumber}
                onChange={e => setJerseyNumber(e.target.value)}
              />
              <label htmlFor='jersey-number'>{I18n.t('teams.new_player.jersey_number')}</label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <label className='active'>{I18n.t('teams.new_player.role')}</label>
              <select
                className='browser-default'
                id='role-selector'
                value={role}
                onChange={() => changeProperty('role-selector', setRole)}
              >
                {ROLES.map(role => {
                  return (
                    <option key={role} value={role}>{I18n.t(`teams.new_player.roles.${role}`)}</option>
                  )
                })}
              </select>
            </div>
            <div className='input-field col s12 m6'>
              <label className='active'>{I18n.t('teams.edit_player.team')}</label>
              <select
                className='browser-default'
                id='team-selector'
                value={teamId}
                onChange={() => changeProperty('team-selector', setTeamId)}
              >
                {props.teams.map(team => {
                  return (
                    <option key={team.id} value={team.id}>{team.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='mobile-phone'
                type='text'
                className='validate'
                value={mobilePhone}
                onChange={e => setMobilePhone(e.target.value)}
              />
              <label htmlFor='mobile-phone'>{I18n.t('teams.new_player.mobile_phone')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <label className='active'>{I18n.t('teams.new_player.locale')}</label>
              <select
                className='browser-default'
                id='locale-selector'
                value={locale}
                onChange={() => changeProperty('locale-selector', setLocale)}
              >
                {LOCALES.map(locale => {
                  return (
                    <option key={locale} value={locale}>{I18n.t(`teams.new_player.locales.${locale}`)}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              {props.polarPlayers !== undefined && props.teams !== [] && (
                <>
                  <label>{I18n.t('teams.edit_player.polar_player_select.label')}</label>
                  <PolarPlayerSelect
                    polarPlayers={props.polarPlayers}
                    selectedPolarPlayerIds={selectedPolarPlayerIds}
                    setSelectedPolarPlayerIds={setSelectedPolarPlayerIds}
                  />
                </>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <button type='submit' className='btn waves-effect waves-light' onClick={updatePlayer}>
                {I18n.t('teams.edit_player.update_player')}
              </button>
            </div>
          </div>
        </div>
      </PinCode>
    </>
  )
}

export default WithModal(WithFlashMessages(EditPlayer))
