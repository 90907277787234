import React, { useEffect, useMemo, useState } from 'react'
import { changeProperty } from '../../teampro/Utils'
import {
  datePickerDefaultOptions, emhSumField, emiSumField, fullMomentDateFormatWithTime, highchartsThemeElementary,
  matchFields, ostrcSumField,
  QUESTION_MAPPING,
  specialEventFields,
  trainingFields
} from '../../common/Constants'
import I18n from 'i18n'
import DatePicker from 'react-datepicker'
import classNames from 'classnames'
import { average, movingAverage, round, standardDeviation } from '../../common/Math'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import moment from 'moment'
import Teams, { Team } from '../../common/types/Teams'
import { Options } from 'highcharts'
import { useResponsesPlayerResponsesQuery } from '../../backend/Queries'
import Param from '../../common/types/Param'
import OptGroupLabel from '../../common/types/OptGroupLabel'
import SpinnerWrapper from '../../common/SpinnerWrapper'
import { findPlayer, getPlayerData, PlotBand, Point } from './Utils'
import { compact } from 'lodash'

/* eslint-disable @typescript-eslint/explicit-function-return-type, @typescript-eslint/strict-boolean-expressions, react/jsx-boolean-value */

const DATE_FORMAT = 'YYYY-MM-DD'

interface Props {
  teams: Teams
}

const INDIVIDUAL = 'individual'
const NONE = 'none'
const POPULATION = 'population'

type ZScoresVisibility = 'individual' | 'none' | 'population'

interface DailyAverage {
  [name: string]: number[]
}

interface Series {
  type: string
  color?: string
  data: Point[]
  dashStyle?: string
  marker: any
  id: string
  name: string
}

const OverviewDashboard: React.FunctionComponent<Props> = (props: Props) => {
  const minDate = useMemo(() => moment().subtract(1, 'year').startOf('day').toDate(), [])
  const maxDate = useMemo(() => moment(new Date()).endOf('day').toDate(), [])

  const [variable, setVariable] = useState<Param>('v1')
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<number[]>([])
  const [allAvg, setAllAvg] = useState<number>(NaN)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [showAverage, setShowAverage] = useState<boolean>(false)
  const [movingAverageWindow, setMovingAverageWindow] = useState<number>(1)
  const [pointMax, setPointMax] = useState<number>(10)
  const [showDailyAverage, setShowDailyAverage] = useState<boolean>(false)
  const [showZScores, setShowZScores] = useState<ZScoresVisibility>(NONE)
  const [mondays, setMondays] = useState<PlotBand[]>([])
  const [showPlotBands, setShowPlotBands] = useState<boolean>(false)
  const [minPlotBand, setMinPlotBand] = useState<number | string>(0)
  const [maxPlotBand, setMaxPlotBand] = useState<number | string>(10)
  const [minOpenToDate, setMinOpenToDate] = useState<Date | null>(null)
  const [maxOpenToDate, setMaxOpenToDate] = useState<Date | null>(null)

  Highcharts.theme = highchartsThemeElementary as Options
  Highcharts.setOptions(Highcharts.theme)

  const { data: responses = [], refetch, isSuccess, isError } = useResponsesPlayerResponsesQuery(selectedPlayerIds, startDate ?? minDate, endDate ?? maxDate, true)

  useEffect(() => {
    let newMinOpenToDate = null
    let newMaxOpenToDate = null
    for (const response of responses) {
      if (!response.completed_at) continue
      const completedAtMoment = moment(response.completed_at)
      if (!newMinOpenToDate) {
        newMinOpenToDate = completedAtMoment
      } else if (completedAtMoment < newMinOpenToDate) {
        newMinOpenToDate = completedAtMoment
      }
      if (!newMaxOpenToDate) {
        newMaxOpenToDate = completedAtMoment
      } else if (completedAtMoment > newMaxOpenToDate) {
        newMaxOpenToDate = completedAtMoment
      }
    }
    setMinOpenToDate(newMinOpenToDate ? newMinOpenToDate.startOf('day').toDate() : null)
    setMaxOpenToDate(newMaxOpenToDate ? newMaxOpenToDate.endOf('day').toDate() : null)
  }, [responses])

  useEffect(() => {
    // TODO: don't ignore errors for refetch
    void refetch()
  }, [startDate, endDate, selectedPlayerIds])

  useEffect(() => {
    (window as any).M.FormSelect.init(document.querySelectorAll('#variable-selector'));
    (window as any).M.FormSelect.init(document.querySelectorAll('#player-selector'))
  }, [props.teams])

  const renderFields = (fieldsArr: Param[], label: OptGroupLabel) => {
    return (
      <optgroup label={I18n.t(`visualizations.optgroups.${label}`)}>
        {fieldsArr.map(field => (
          <option
            value={field}
            key={field}
          >{I18n.t(`visualizations.question_mapping.${QUESTION_MAPPING[field]}`)}
          </option>
        ))}
      </optgroup>
    )
  }

  useEffect(() => {
    const elem = (document.getElementById('player-selector') as any)?.M_FormSelect
    if (elem) {
      elem._setValueToInput()
      elem._setSelectedStates()
    }
  }, [selectedPlayerIds])

  const series: Series[] = useMemo(() => {
    let cPointMax = 10
    const allValues: number[] = []
    const dailyAverage: DailyAverage = {}
    responses.forEach(response => {
      if (Object.keys(response.contents).includes(variable)) {
        allValues.push(response.contents[variable])
      }
    })
    const allStd = standardDeviation(allValues)
    const allAvg1 = average(allValues)
    const allModifiedValues: number[] = []
    const tSeries: Series[] = compact(selectedPlayerIds.map(playerId => {
      const player = findPlayer(playerId, props.teams)
      if (!player) {
        return null
      }
      const { data, nrResponses } = getPlayerData(playerId, responses, [variable], showZScores === INDIVIDUAL)
      // Calculate the max number of points in this series for the sliding window
      cPointMax = Math.max(cPointMax, nrResponses)
      if (showZScores === POPULATION) {
        if (allStd > 0.0001) {
          for (const dataPoint of data) {
            dataPoint.y = (dataPoint.y - allAvg1) / allStd
          }
        } else {
          for (const dataPoint of data) {
            dataPoint.y = 0
          }
        }
      }
      // Add values after z-score calculation to be included in the calculation for the average line
      for (const dataPoint of data) {
        allModifiedValues.push(dataPoint.y)
      }

      // Optionally calculate a moving daily average line.
      if (showDailyAverage) {
        for (let i = 0; i < data.length; i += 1) {
          const dateString = moment(data[i].x).format(DATE_FORMAT)
          if (!dailyAverage[dateString]) { dailyAverage[dateString] = [] }
          dailyAverage[dateString].push(data[i].y)
        }
      }

      // Apply moving average to the data. Note that we have the original values stored in `allModifiedValues`.
      if (movingAverageWindow > 1) {
        const movingAverageApplied = movingAverage(data.map(dataPoint => dataPoint.y), movingAverageWindow)
        for (let i = 0; i < data.length; i += 1) {
          data[i].y = movingAverageApplied[i]
        }
      }
      return {
        type: 'line',
        data: data,
        marker: { enabled: true },
        id: `${playerId}`,
        name: player.name
      }
    }))

    // Calculate the plot bands to mark mondays as green.
    const plotBandMondays: PlotBand[] = []
    const cMinDate = startDate ?? minDate
    const cMaxDate = endDate ?? maxDate
    for (let i = cMinDate; i <= cMaxDate; i = moment(i).add(1, 'days').toDate()) {
      const curMoment = moment(i)
      if (curMoment.isoWeekday() === 1) {
        plotBandMondays.push({
          color: '#e8f5e9',
          from: curMoment.startOf('day').toDate(),
          to: curMoment.endOf('day').toDate()
        })
      }
    }
    setMondays(plotBandMondays)
    setAllAvg(average(allModifiedValues))
    setPointMax(cPointMax)
    if (showDailyAverage) {
      const davg: Point[] = []
      const sortedDates = Object.keys(dailyAverage).sort()
      for (const cDate of sortedDates) {
        const cMoment = moment(cDate, DATE_FORMAT).add(12, 'hours')
        davg.push({
          x: cMoment.toDate(),
          y: average(dailyAverage[cDate]),
          formatted_date: cMoment.locale(I18n.locale).format(fullMomentDateFormatWithTime)
        })
      }
      if (movingAverageWindow > 1) {
        const movingAverageApplied = movingAverage(davg.map(dataPoint => dataPoint.y), movingAverageWindow)
        for (let i = 0; i < davg.length; i += 1) {
          davg[i].y = movingAverageApplied[i]
        }
      }
      tSeries.push({
        type: 'line',
        color: 'red',
        data: davg,
        dashStyle: 'LongDash',
        marker: { enabled: false },
        id: '-1',
        name: I18n.t('visualizations.labels.daily_average')
      })
    }
    return tSeries
  }, [selectedPlayerIds, showAverage, movingAverageWindow, showDailyAverage, variable, startDate, endDate, showZScores, props.teams, responses.length])

  const renderPlayerOptions = () => {
    return (
      <>
        {props.teams.map((team: Team) => {
          return (
            <optgroup label={team.name} key={team.name}>
              {team.players.map(player => (
                <option value={player.id} key={player.id}>{player.name}</option>
              ))}
            </optgroup>
          )
        })}
      </>
    )
  }

  useEffect(() => {
    (window as any).M.updateTextFields()
  }, [showPlotBands])

  let yPlotBands: PlotBand[] | null = []

  if (showZScores !== NONE) {
    yPlotBands.push({
      color: 'rgba(40, 167, 69, 0.1)', // bootstrap success 10%
      from: -1,
      to: 1
    })
    yPlotBands.push({
      color: 'rgba(220, 53, 69, 0.1)',
      from: -3,
      to: -2
    })
    yPlotBands.push({
      color: 'rgba(220, 53, 69, 0.1)',
      from: 2,
      to: 3
    })
    yPlotBands.push({
      color: 'rgba(255, 193, 7, 0.1)',
      from: -2,
      to: -1
    })
    yPlotBands.push({
      color: 'rgba(255, 193, 7, 0.1)',
      from: 1,
      to: 2
    })
  }

  if (showPlotBands && typeof (minPlotBand) !== 'string' && typeof (maxPlotBand) !== 'string') {
    yPlotBands.push({
      color: 'rgba(68, 170, 213, 0.1)',
      from: minPlotBand,
      to: maxPlotBand
    })
  }

  if (yPlotBands.length === 0) yPlotBands = null

  return (
    <>
      <div className='row'>
        <div className='input-field col s12 m4'>
          <select
            id='variable-selector'
            value={variable}
            onChange={() => changeProperty('variable-selector', setVariable)}
          >
            {/* <option value='' disabled={true} /> */}
            {renderFields(trainingFields as Param[], 'training')}
            {renderFields(matchFields as Param[], 'match')}
            {renderFields(specialEventFields as Param[], 'special_event')}
            {renderFields([ostrcSumField, emiSumField, emhSumField], 'compound')}
            {renderFields(['v0'], 'coach')}
          </select>
          <label>{I18n.t('visualizations.labels.variable')}</label>
        </div>
        <div className='input-field col s6 m2'>
          <DatePicker
            id='start-date' {...datePickerDefaultOptions} selected={startDate} onChange={date => setStartDate(date ? moment(date).startOf('day').toDate() : date)}
            selectsStart={true} startDate={startDate} endDate={endDate} minDate={minOpenToDate ?? minDate} maxDate={maxOpenToDate ?? maxDate}
            openToDate={startDate ?? minOpenToDate ?? minDate}
          />
          <label
            htmlFor='start-date'
            className={classNames({ active: startDate })}
          >{I18n.t('visualizations.labels.start_date')}
          </label>
        </div>
        <div className='input-field col s6 m2'>
          <DatePicker
            id='end-date' {...datePickerDefaultOptions} selected={endDate} onChange={date => setEndDate(date ? moment(date).endOf('day').toDate() : date)}
            selectsEnd={true}
            startDate={startDate} endDate={endDate} minDate={startDate ?? minOpenToDate ?? minDate} maxDate={maxOpenToDate ?? maxDate}
            openToDate={endDate ?? maxOpenToDate ?? maxDate}
          />
          <label
            htmlFor='end-date'
            className={classNames({ active: endDate })}
          >{I18n.t('visualizations.labels.end_date')}
          </label>
        </div>
        <div className='input-field col s12 m4'>
          <select
            id='player-selector'
            value={selectedPlayerIds.map(playerId => `${playerId}`)}
            onChange={() => {
              const selectedValues = [...Array.from((document.getElementById('player-selector') as HTMLSelectElement).options)]
                .filter((x) => x.selected)
                .map((x) => x.value)
              setSelectedPlayerIds(selectedValues.map(value => parseInt(value)))
            }}
            multiple={true}
          >
            {/* <option value='' disabled /> */}
            {renderPlayerOptions()}
          </select>
          <label>{I18n.t('visualizations.labels.players')}</label>
        </div>
      </div>
      <div className='row no-margin-bottom'>
        <div className='col s12 m3'>
          <p>
            <label>
              <input
                type='checkbox'
                checked={showAverage}
                onChange={e => setShowAverage(e.target.checked)}
              />
              <span>{I18n.t('visualizations.labels.show_average')} {!isNaN(allAvg) && `(${round(allAvg, 1)})`}</span>
            </label>
          </p>
        </div>
        <div className='col s12 m3'>
          <p>
            <label>
              <input
                type='checkbox'
                checked={showDailyAverage}
                onChange={e => setShowDailyAverage(e.target.checked)}
              />
              <span>{I18n.t('visualizations.labels.show_daily_average')}</span>
            </label>
          </p>
        </div>
        <div className='col s12 m6'>
          <p className='range-field no-margin-bottom'>
            <span
              className='abs-title'
            >{I18n.t('visualizations.labels.moving_average_window_size')}: {movingAverageWindow === 1 ? I18n.t('visualizations.labels.moving_average_window_none') : movingAverageWindow}
            </span>
            <input
              type='range'
              min={1}
              max={pointMax}
              value={movingAverageWindow}
              onChange={e => setMovingAverageWindow(parseInt(e.target.value))}
            />
          </p>
        </div>
      </div>
      <div className='row no-margin-bottom'>
        <div className='col s12 m3'>
          <p>
            <label>
              <input
                type='checkbox'
                checked={showPlotBands}
                onChange={e => setShowPlotBands(e.target.checked)}
              />
              <span>{I18n.t('visualizations.labels.show_plot_bands')}</span>
            </label>
          </p>
        </div>
        <div className='input-field col s6 m3'>
          {showPlotBands && (
            <>
              <input
                id='min-plot-band' type='number' value={minPlotBand}
                onChange={e => setMinPlotBand(!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : '')}
              />
              <label htmlFor='min-plot-band'>{I18n.t('visualizations.labels.min_plot_band')}</label>
            </>
          )}
        </div>
        <div className='input-field col s6 m3'>
          {showPlotBands && (
            <>
              <input
                id='max-plot-band' type='number' value={maxPlotBand}
                onChange={e => setMaxPlotBand(!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : '')}
              />
              <label htmlFor='max-plot-band'>{I18n.t('visualizations.labels.max_plot_band')}</label>
            </>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col s12 m4'>
          <p>
            <label>
              <input
                name='z-scores'
                type='radio'
                checked={showZScores === NONE}
                onChange={() => setShowZScores(NONE)}
              />
              <span>{I18n.t('visualizations.labels.z_scores.none')}</span>
            </label>
          </p>
        </div>
        <div className='col s12 m4'>
          <p>
            <label>
              <input
                name='z-scores'
                type='radio'
                checked={showZScores === POPULATION}
                onChange={() => setShowZScores(POPULATION)}
              />
              <span>{I18n.t('visualizations.labels.z_scores.population')}</span>
            </label>
          </p>
        </div>
        <div className='col s12 m4'>
          <p>
            <label>
              <input
                name='z-scores'
                type='radio'
                checked={showZScores === INDIVIDUAL}
                onChange={() => setShowZScores(INDIVIDUAL)}
              />
              <span>{I18n.t('visualizations.labels.z_scores.individual')}</span>
            </label>
          </p>
        </div>
      </div>
      <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
        {responses.length === 0 && (
          <p><em>{I18n.t('responses.overview.there_are_no_responses')}</em></p>
        )}
        {responses.length > 0 && (
          <>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                title: {
                  text: 'Verloop van fysieke en psychologische toestand'
                },
                subtitle: {
                  text: variable && I18n.t(`visualizations.question_mapping.${QUESTION_MAPPING[variable]}`)
                },
                chart: {
                  type: 'line',
                  zoomType: 'xy'
                },
                legend: {
                  enabled: true
                },
                tooltip: {
                  useHTML: true,
                  headerFormat: '',
                  pointFormat: '<span style="font-size: 0.75rem">{point.formatted_date}</span><br /><span style="color: {series.color}">⬤</span> {series.name}: <b>{point.y:.2f}</b>'
                },
                xAxis: {
                  title: {
                    text: ''
                  },
                  type: 'datetime',
                  min: !startDate ? null : startDate.getTime(),
                  max: !endDate ? null : endDate.getTime(),
                  dateTimeLabelFormats: {
                    day: '%d %b'
                  },
                  plotBands: mondays
                },
                yAxis: {
                  title: {
                    text: variable && I18n.t(`visualizations.question_mapping.${QUESTION_MAPPING[variable]}`)
                  },
                  min: showZScores === NONE ? 0 : null,
                  plotLines: showAverage && [{
                    color: '#FF0000',
                    width: 2,
                    value: allAvg
                  }],
                  plotBands: yPlotBands
                },
                series: series
              }}
            />
            <p>{I18n.t('visualizations.labels.zoom')}</p>
          </>
        )}
      </SpinnerWrapper>
    </>
  )
}

export default OverviewDashboard
