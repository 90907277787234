import React, { useEffect } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithModal } from '../../modal/ModalProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import I18n from 'i18n'
import PinCode from '../../pin_code/PinCode'

const TeamsOverview = (props) => {
  useEffect(() => {
    props.fetchTeams()
  }, [])

  useEffect(() => {
    const elems = document.querySelectorAll('.fixed-action-btn')
    window.M.FloatingActionButton.init(elems, {})
  }, [])

  const editTeam = id => {
    props.history.push(`/teams/${id}/edit`)
  }

  const destroyTeam = id => {
    props.destroyTeam(id).then(() => {
      props.flashMessages.push(
        I18n.t('teams.flash_messages.team_deleted'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO)
      props.fetchTeams()
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const showDestroyTeamDialog = id => {
    props.setConfirmationDialog({
      onConfirmation: () => destroyTeam(id),
      target: `team ${props.teams.filter(team => team.id === id)[0].name}`,
      action: 'delete',
      noQuotes: true
    })
  }

  const newTeam = (e) => {
    e.preventDefault()
    props.history.push('/teams/new')
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.teams') }]}
      />
      <div className='fixed-action-btn'>
        <a className='btn-floating btn-large red' onClick={newTeam}>
          <i className='large material-icons'>add</i>
        </a>
      </div>
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <ul className='collection with-header'>
                <li className='collection-header'><h4>{I18n.t('menu.teams')}</h4></li>
                {props.teams.length === 0 && (
                  <div className='col s12'>
                    <p><em>{I18n.t('teams.overview.there_are_no_teams_click_to_add')}</em></p>
                  </div>
                )}
                {props.teams.map(team => {
                  return (
                    <li key={team.id} className='collection-item'>
                      <div>
                        <span className='pointer' onClick={() => editTeam(team.id)}>{team.name}</span>
                        <a
                          href='#!'
                          className='secondary-content margin-left'
                          title={I18n.t('teams.overview.edit_team')}
                          onClick={e => {
                            e.preventDefault()
                            editTeam(team.id)
                          }}
                        >
                          <i className='material-icons'>edit</i>
                        </a>
                        <a
                          href='#!'
                          className='secondary-content'
                          title={I18n.t('teams.overview.delete_team')}
                          onClick={e => {
                            e.preventDefault()
                            showDestroyTeamDialog(team.id)
                          }}
                        >
                          <i className='material-icons'>delete</i>
                        </a>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </PinCode>
    </>
  )
}

export default WithModal(WithFlashMessages(TeamsOverview))
