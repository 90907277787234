import React, { useEffect, useState } from 'react'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import BreadCrumbs from '../../common/Breadcrumbs'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import I18n from 'i18n'
import PinCode from '../../pin_code/PinCode'

const NewTeam = (props) => {
  const [teamName, setTeamName] = useState('')

  useEffect(() => {
    window.M.updateTextFields()
  }, [])

  useEffect(() => {
    const elem = document.getElementById('name')
    if (elem) elem.focus()
  }, [])

  const createTeam = () => {
    props.createTeam({ team: { name: teamName } }).then(() => {
      props.flashMessages.push(
        I18n.t('teams.flash_messages.team_created'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO)
      props.history.push('/teams')
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.teams'), link: '/teams' },
          { name: I18n.t('menu.add_team') }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('menu.add_team')}</h4>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='name'
                type='text'
                className='validate'
                value={teamName}
                onChange={e => setTeamName(e.target.value)}
              />
              <label htmlFor='name'>{I18n.t('teams.new_team.name')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <button type='submit' className='btn waves-effect waves-light' onClick={createTeam}>
                {I18n.t('teams.new_team.create_team')}
              </button>
            </div>
          </div>
        </div>
      </PinCode>
    </>
  )
}

export default WithFlashMessages(NewTeam)
