import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import Spinner from 'components/common/Spinner'
import { WithFlashMessages } from 'components/flashmessages/FlashMessageProvider'
import I18n from 'i18n'
import { WithSession } from 'components/session/SessionProvider'

const RESOLVING = 'resolving'
const SUCCESS = 'success'
const FAILED = 'failed'

const Callback = props => {
  const [state, setState] = useState(RESOLVING)
  const search = useLocation().search
  const code = new URLSearchParams(search).get('code')

  useEffect(() => {
    props.createPolarToken(code).then(_response => {
      props.flashMessages.push(
        I18n.t('flashmessages.callback.successful'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO)
      props.refetchSession()
      setState(SUCCESS)
    }).catch(err => {
      console.log(err)
      props.flashMessages.push(
        I18n.t('flashmessages.callback.failed'),
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
      setState(FAILED)
    })
  }, [])

  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12' style={{ marginTop: '100px' }}>
          {code && (
            <>
              {state === RESOLVING && (
                <>
                  <p>{I18n.t('callback.resolving_authorization_code')}: {code} ...</p>
                  <Spinner transparent />
                </>
              )}
              {state === SUCCESS && (
                <>
                  <p>{I18n.t('flashmessages.callback.successful')}</p>
                </>
              )}
              {state === FAILED && (
                <>
                  <p>{I18n.t('flashmessages.callback.failed')}</p>
                </>
              )}
              {!code && (
                <p>ERROR: {I18n.t('callback.did_not_receive_a_code')}</p>
              )}
              {(state === SUCCESS || state === FAILED) && (
                <>
                  <Link to='/' className='waves-effect waves-light btn'>
                    {I18n.t('callback.go_back')}
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default WithSession(WithFlashMessages(Callback))
