import React from 'react'
import { withCookies } from 'react-cookie'
import { MyAxios as axios } from '../MyAxios'
import { withRouter } from 'react-router-dom'
import { I18nLoader } from '../I18nLoader'
import moment from 'moment'
import I18n from 'i18n'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'

export const SessionContext = React.createContext()

class SessionProviderCls extends React.Component {
  constructor (props) {
    super(props)
    let token = props.cookies.get('sessionToken')
    let aud = props.cookies.get('sessionAud')
    token = token !== 'null' ? token : null
    aud = aud !== 'null' ? aud : null
    this.state = {
      sessionToken: token,
      sessionAud: aud,
      myProfile: {}
    }

    this.cookie_options = { path: '/', maxAge: 30 * 24 * 3600, domain: process.env.SESSION_COOKIE_HOSTNAME }
  }

  removeBearerFromToken (token) {
    if (!token) {
      return undefined
    }

    if (token.includes('Bearer')) {
      return token.split(' ')[1]
    }
    return token
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.sessionToken !== this.state.sessionToken && this.isValid()) {
      this.fetchMyProfile()
    }
  }

  componentDidMount () {
    this.isValid() && this.fetchMyProfile()
  }

  authorizationHeaders () {
    if (!this.state.sessionAud) {
      return {
        Authorization: this.state.sessionToken
      }
    }
    return {
      Authorization: this.state.sessionToken,
      'JWT-AUD': this.state.sessionAud
    }
  }

  fetchMyProfile () {
    axios({
      method: 'GET',
      url: '/api/v1/users/me',
      headers: { ...this.authorizationHeaders(), 'Content-Type': 'application/json' }
    }).then((response) => {
      if (response.data.locale && response.data.locale.length > 0) {
        moment.locale(response.data.locale)
        I18n.locale = response.data.locale
      }
      this.setState({ myProfile: response.data })
    }).catch((err) => {
      // We only want to logout the user if we did get a reply from the backend
      // (like a 400 or 401). If the user's internet dropped for a second, we
      // don't want to log them out.
      console.log(err)
      const playerLogin = (this.props.location && this.props.location.pathname.match(/^\/fill-out\/[a-z0-9]+/))
      if (err.response && !playerLogin) {
        console.log('Error retrieving profile, logging out:')
        this.logout()
      }
    })
  }

  updateSession (sessionValues) {
    this.props.cookies.set('sessionToken', sessionValues.sessionToken, this.cookie_options)
    if (sessionValues.sessionAud) {
      this.props.cookies.set('sessionAud', sessionValues.sessionAud, this.cookie_options)
    } else {
      this.props.cookies.set('sessionAud', '', this.cookie_options)
    }
    this.setState({ ...sessionValues })
  }

  logout (redirect = true) {
    this.props.cookies.set('sessionToken', '', this.cookie_options)
    this.props.cookies.set('sessionAud', '', this.cookie_options)
    axios({ method: 'DELETE', url: '/api/v1/logout', headers: { ...this.authorizationHeaders(), 'Content-Type': 'application/json' } }).then((response) => {
      this.setState({ ...this.state, sessionToken: undefined, sessionAud: undefined }, () => {
        I18nLoader.loadAsync()
        if (redirect) {
          this.props.flashMessages.push(
            I18n.t('flashmessages.logout.successful'),
            this.props.flashMessages.duration.SHORT,
            this.props.flashMessages.levels.INFO
          )
          this.props.history.push('/')
        }
      })
    }).catch(err => {
      console.log(err)
      this.props.history.push('/')
      window.location.reload()
    })
  }

  isValid () {
    return !!this.state.sessionToken
  }

  render () {
    return (
      <SessionContext.Provider value={{
        handleLogout: this.logout.bind(this),
        updateSession: this.updateSession.bind(this),
        refetchSession: this.fetchMyProfile.bind(this),
        authorizationHeaders: this.authorizationHeaders.bind(this),
        session: {
          isValid: this.isValid.bind(this),
          sessionToken: this.removeBearerFromToken(this.state.sessionToken)
        },
        ...this.state
      }}
      >
        {this.props.children}
      </SessionContext.Provider>
    )
  }
}

export const SessionProvider = WithFlashMessages(withRouter(withCookies(SessionProviderCls)))

export const WithSession = Component => props => {
  return (
    <SessionContext.Consumer>
      {(context) => (<Component {...context} {...props} />)}
    </SessionContext.Consumer>
  )
}
