import React from 'react'
import { Redirect } from 'react-router'
import I18n from 'i18n'
import FormFactory from '../../helpers/FormFactory'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { Link } from 'react-router-dom'
import { I18nLoader } from '../../I18nLoader'
import { PIN_CODE } from '../../pin_code/PinCode'

class Login extends FormFactory {
  constructor (props) {
    super(props)
    this.form_fields = {
      email: {
        component: 'field',
        stage: {
          render: [0],
          submit: [0, 1]
        },
        properties: {
          type: 'email',
          autoComplete: 'on'
        }
      },
      password: {
        component: 'field',
        stage: {
          render: [0],
          submit: [0, 1]
        },
        properties: {
          type: 'password',
          autoComplete: 'on'
        }
      },
      otp_attempt: {
        component: 'field',
        stage: {
          render: [1],
          submit: [1]
        },
        properties: {
          type: 'text'
        }
      }
    }
    this.state = {}
  }

  componentDidMount () {
    this.props.setHeight(554)
  }

  handleSuccess (response) {
    if ('authorization' in response.headers) {
      this.props.updateSession({ sessionToken: response.headers.authorization, myProfile: response.data })
      I18nLoader.loadAsync().then((I18n) => {
        this.props.flashMessages.push(
          I18n.t(
            'flashmessages.login.successful'
          ),
          this.props.flashMessages.duration.SHORT,
          this.props.flashMessages.levels.INFO
        )
        // We get the location from the devise controller. If the user has not
        // logged in too many times before, we redirect him or her to their
        // profile page.
        window.localStorage.setItem(PIN_CODE, PIN_CODE)
        this.props.history.push('/')
      })
    } else {
      if (this.props.getStage(this.props.formName) === 1) {
        /* Backend responded with a 2xx status, but no authorization header
           was present which means that authentication failed. Since we are
           in the second stage (stage 0 = first stage), there must have been
           an invalid OTP code */
        this.handleInvalidOtp('login.otp_attempt.invalid')
      } else {
        this.props.nextStage(this.props.formName) /* go to next stage because 2FA steps required */
      }
    }
  }

  handleClick () {
    this.props.setStage(this.props.formName, 0)
  }

  handleFieldError (field, message) {
    this.props.setFieldError(this.props.formName, field, message)
  }

  handleError (error) {
    if (error.response.data.account_locked) {
      this.handleFieldError(this.props.getStage(this.props.formName) === 1 ? 'otp_attempt' : 'email', 'login.otp_attempt.locked')
    } else {
      this.props.clearFieldError(this.props.formName, 'email')
      if (this.props.getStage(this.props.formName) === 1) {
        this.handleFieldError('otp_attempt', 'login.otp_attempt.invalid')
      }
    }
  }

  render () {
    return (
      <>
        <form autoComplete='on'>
          {this.props.sessionToken !== 'null' && !!this.props.sessionToken && (<Redirect to='/' />)}
          {this.props.error && <div className='row'><div className='col s12'>{this.props.error}</div></div>}
          {this.renderFields()}
          <span>{this.renderSubmit(`${this.props.formName}.submit`, { id: 'login-button', className: 'btn waves-effect waves-light' })}</span>
          {this.props.stage !== 0 && <div className='row'><div className='col s12'><span><button id='vorige-stap-btn' className='btn waves-effect waves-light' onClick={() => this.handleClick()}>Vorige stap</button></span></div></div>}
        </form>
        <div className='row'>
          <div className='col s12'>
            <ul>
              {this.props.stage === 0 && (
                <li>
                  <Link id='login-forgot-password' to='/password'>
                    {I18n.t('login.link.forgot_password')}
                  </Link>
                </li>
              )}
              {this.props.stage === 1 && (
                <li>
                  <span>
                    {I18n.t('login.lost_authenticator')}
                  </span>
                </li>
              )}
              {this.props.stage === 0 && (
                <li>
                  <Link id='sign-up' to='/signup'>
                    {I18n.t('login.link.register')}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </>
    )
  }
}

export default WithFlashMessages(Login)
