import React from 'react'
import I18n from 'i18n'

export default class Field extends React.Component {
  componentDidUpdate (prevProps, prevState, snapshot) {
    window.M.updateTextFields()
  }

  render () {
    const errors = this.props.errors || []
    const { component, type, fieldName, formName, currentValue } = this.props
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    return (
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            <input
              name={fieldName}
              id={fieldName}
              type={type}
              value={currentValue || ''}
              {...fieldProps}
            />
            <label htmlFor={fieldName}>
              {I18n.t(`${formName}.${component}.${type}.${fieldName}`)}
            </label>
            {errors.map(err => (
              <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
                {this.props.translateErrors ? I18n.t(err) : err}
              </span>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
