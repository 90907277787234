import React from 'react'
import AccountRoutes from './AccountRoutes'
import ApiConnector from '../helpers/ApiConnector'
import { WithSession } from '../session/SessionProvider'

class Account extends ApiConnector {
  /* eslint-disable camelcase */
  reset_passwordFormMapper (fields) {
    return { user: fields }
  }
  /* eslint-enable camelcase */

  loginFormMapper (fields) {
    return { user: fields }
  }

  signupFormMapper (fields) {
    return { user: fields }
  }

  render () {
    return (
      <AccountRoutes
        sessionToken={this.props.sessionToken}
        updateSession={this.props.updateSession}
        resetPasswordProps={this.defaultFormProps(
          'reset_password', {
            translateErrors: false,
            submitMethod: 'POST',
            endpoint: '/api/v1/password',
            logout: this.props.handleLogout
          })}
        resetPasswordEditProps={this.defaultFormProps(
          'reset_password_edit', {
            translateErrors: false,
            submitMethod: 'PATCH',
            endpoint: '/api/v1/password',
            logout: this.props.handleLogout
          })}
        loginProps={this.defaultFormProps(
          'login', {
            translateErrors: true,
            submitMethod: 'POST',
            endpoint: '/api/v1/login'
          })}
        signupProps={this.defaultFormProps(
          'signup', {
            translateErrors: false,
            submitMethod: 'POST',
            endpoint: 'api/v1/signup'
          })}
        confirmationProps={this.defaultFormProps(
          'confirmation', {
            translateErrors: false,
            submitMethod: 'GET',
            endpoint: 'api/v1/confirmation'
          })}
      />
    )
  }
}

export default WithSession(Account)
