import React from 'react'
import I18n from 'i18n'

export default class DisableConfirm extends React.Component {
  render () {
    return (
      <>
        <p className='center'>{I18n.t('components.2fa.disable.success')}</p>
        <p className='center'>
          <button
            id='disable-confirm-submit'
            onClick={() => this.props.transition()}
            className='btn waves-effect waves-light text-background-color'
          >
            {I18n.t('components.2fa.disable.confirm')}
          </button>
        </p>
      </>
    )
  }
}
