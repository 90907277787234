import React from 'react'
import { MyAxios as axios } from '../MyAxios'
import PasswordRoutes from './PasswordRoutes'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import I18n from 'i18n'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'

const PasswordController = props => {
  const handleUpdatePassword = (oldPassword, newPassword, confirmationPassword) => {
    axios({
      method: 'PUT',
      url: '/api/v1/signup',
      headers: { ...props.authorizationHeaders() },
      data: {
        user: {
          current_password: oldPassword,
          password: newPassword,
          password_confirmation: confirmationPassword
        }
      }
    }).then(_res => {
      props.flashMessages.push(I18n.t('profile.edit.reset_password.success'), props.flashMessages.duration.SHORT, props.flashMessages.levels.INFO)
    }).catch(err => {
      let errorTitles
      if (Array.isArray(err.response.data.errors)) {
        errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      } else {
        errorTitles = Object.entries(err.response.data.errors).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      }
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const handleUpdateUser = (user) => {
    axios.patch('/api/v1/users', { user }, { headers: { ...props.authorizationHeaders() } }).then(_res => {
      props.flashMessages.push(I18n.t('profile.edit.update_success'), props.flashMessages.duration.SHORT, props.flashMessages.levels.INFO)
      props.refetchSession()
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const handleRegenerateApiToken = () => {
    axios({
      method: 'POST',
      url: '/api/v1/users/regenerate_api_token',
      headers: { ...props.authorizationHeaders() }
    }).then(_res => {
      props.flashMessages.push(I18n.t('profile.edit.api_token_success'), props.flashMessages.duration.SHORT, props.flashMessages.levels.INFO)
      props.refetchSession()
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const handleCreateToken = (subToken, cb) => {
    axios({
      method: 'POST',
      url: '/api/v1/sub_tokens',
      headers: { ...props.authorizationHeaders() },
      data: subToken
    }).then(res => {
      props.flashMessages.push(I18n.t('sub_tokens.flash_messages.sub_token_created'), props.flashMessages.duration.SHORT, props.flashMessages.levels.INFO)
      props.refetchSession()
      if (cb) {
        cb(res.data.result[0].instance)
      }
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const handleDestroyToken = id => {
    axios.delete(`/api/v1/sub_tokens/${id}`,
      {
        headers: { ...props.authorizationHeaders() }
      }).then(() => {
      props.flashMessages.push(
        I18n.t('sub_tokens.flash_messages.sub_token_deleted'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO)
      props.refetchSession()
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const handleDestroyPolarToken = () => {
    axios.delete('/api/v1/polar_tokens',
      {
        headers: { ...props.authorizationHeaders() }
      }).then(() => {
      props.flashMessages.push(
        I18n.t('flashmessages.polar_token.destroy.successful'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO)
      props.refetchSession()
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  return (
    <PasswordRoutes
      updatePasswordProps={{
        onUpdatePassword: handleUpdatePassword,
        onRegenerateApiToken: handleRegenerateApiToken,
        onUpdateUser: handleUpdateUser,
        createToken: handleCreateToken,
        destroyToken: handleDestroyToken,
        destroyPolarToken: handleDestroyPolarToken
      }}
    />
  )
}

export default WithFlashMessages(WithSession(PasswordController))
