import React, { useEffect } from 'react'
import Spinner from '../common/Spinner'

const SubAccount = props => {
  const { match: { params: { subTokenId, subTokenToken } } } = props

  useEffect(() => {
    props.fetchSession(subTokenId, subTokenToken)
  }, [])

  return (
    <>
      <p>Loading session...</p>
      <Spinner transparent />
    </>
  )
}

export default SubAccount
