import React, { CSSProperties } from 'react'
import I18n from 'i18n'
import Select from 'react-select'
import { uniq, uniqBy, flatten } from 'lodash'
import { PolarPlayers } from '../../common/types/PolarPlayers'

interface Props {
  polarPlayers: PolarPlayers
  selectedPolarPlayerIds: number[]
  setSelectedPolarPlayerIds: (selectedPolarPlayerIds: number[]) => void
}

const PolarPlayerSelect: React.FC<Props> = ({ polarPlayers, selectedPolarPlayerIds, setSelectedPolarPlayerIds }) => {
  const groupedOptions: GroupedOption[] = []
  const teamNames = uniq(polarPlayers.map(polarPlayer => polarPlayer.team_name))
  for (const teamName of teamNames) {
    groupedOptions.push({
      label: teamName,
      options: teamAthletes(teamName, polarPlayers)
    })
  }

  // used to be setselectedathletes
  const setSelectedPolarPlayers = (selectedPolarPlayers: readonly AthleteOption[]): void => {
    setSelectedPolarPlayerIds(selectedPolarPlayers.map(selectedPolarPlayer => selectedPolarPlayer.id))
  }

  const athleteOptions = makeAthleteOptions(selectedPolarPlayerIds, groupedOptions)

  // Note that we provide the `Select` component from `react-select` with three arguments.
  // The first one is the interface in which `Option`s are to be provided.
  // The second argument is `IsMulti`, which extends boolean (in our case, `true`, since we allow for selecting
  // multiple athletes.
  // The third argument is the `Group` type, which is to specify in which format the Group as a whole
  // should be provided. By default it only includes the athleteOptions, but we require that it has a label, icon, and
  // id as well.
  return (
    <Select<AthleteOption, true, GroupedOption>
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      isMulti
      closeMenuOnSelect={false}
      className='basic-multi-select'
      defaultValue={athleteOptions}
      value={athleteOptions}
      onChange={setSelectedPolarPlayers}
      placeholder={I18n.t('teams.edit_player.polar_player_select.select_polar_players')}
    />
  )
}

interface AthleteOption {
  // the profile id of the athlete
  readonly id: number
  readonly value: string // TODO: is the value needed?
  readonly label: string
  readonly role: string
}

interface GroupedOption {
  // the id of the group or -1 for the connections group
  // readonly id: number
  readonly label: string
  // readonly icon: string
  readonly options: AthleteOption[]
}

const groupStyles: CSSProperties = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between'
}

const athleteStyles: CSSProperties = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between'
}

const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center'
}

const optionTextStyles: CSSProperties = {
}

const makeAthleteOptions = (selectedPolarPlayerIds: number[], groupedOptions: GroupedOption[]): AthleteOption[] => {
  const athleteOptions = uniqBy(flatten(groupedOptions.map(groupedOption => groupedOption.options)), 'value')
  const result: AthleteOption[] = []
  // A bit inefficient, but the result should reflect the ordering of the ids in selectedPolarPlayerIds. So we need
  // to iterate over that.
  for (const selectedPolarPlayerId of selectedPolarPlayerIds) {
    const athleteOption = athleteOptions.find(athleteOption => athleteOption.id === selectedPolarPlayerId)
    if (athleteOption !== undefined) {
      result.push(athleteOption)
    }
  }
  return result
}

const teamAthletes = (teamName: string, polarPlayers: PolarPlayers): AthleteOption[] => {
  const result: AthleteOption[] = []
  const polarPlayersInTeam = polarPlayers.filter(polarPlayer => polarPlayer.team_name === teamName)
  for (const polarPlayer of polarPlayersInTeam) {
    result.push({
      id: polarPlayer.id,
      value: `${polarPlayer.id}`,
      label: `${polarPlayer.player_number} ${polarPlayer.first_name} ${polarPlayer.last_name} (${polarPlayer.identifier})`,
      role: polarPlayer.role
    })
  }
  return result
}

const formatGroupLabel = (data: GroupedOption): React.ReactElement => (
  <div
    style={groupStyles}
    data-value={data.label}
  >
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

const formatOptionLabel = (data: AthleteOption): React.ReactElement => (
  <div style={athleteStyles}>
    <span style={optionTextStyles}>{data.label}</span>
  </div>
)

export default PolarPlayerSelect
