import React from 'react'
import Account from '../account/Account'
import NotFoundProvider from './NotFoundProvider'
import FillOutSessionController from '../fillout_session/FillOutSessionController'
import SubAccountController from '../sub_account/SubAccountController'

export default class LoggedOutRouter extends React.Component {
  render () {
    return (
      <NotFoundProvider>
        <Account />
        <SubAccountController />
        <FillOutSessionController />
      </NotFoundProvider>
    )
  }
}
