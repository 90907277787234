import React, { useState } from 'react'
import { MyAxios as axios } from '../MyAxios'
import FillOutSessionRoutes from './FillOutSessionRoutes'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import I18n from 'i18n'
import { I18nLoader } from '../I18nLoader'

const useForceUpdate = () => {
  const [, setValue] = useState(0) // integer state
  return () => setValue(value => value + 1) // update the state to force render
}

const FillOutSessionController = props => {
  const [sessionToken, setSessionToken] = useState(undefined)
  const [teams, setTeams] = useState([])
  const [aud, setAud] = useState(undefined)
  const [player, setPlayer] = useState(undefined)
  const [weekday, setWeekday] = useState(undefined)
  const [trainingMoment, setTrainingMoment] = useState(undefined)
  const [trainingType, setTrainingType] = useState(undefined)
  const [addExtraQuestions, setAddExtraQuestions] = useState(undefined)
  const [disabledQuestionnaireKeys, setDisabledQuestionnaireKeys] = useState([])
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false)

  const forceUpdate = useForceUpdate()

  const fetchSession = invitationTokenId => {
    setAud(invitationTokenId)
    const result = getSession(invitationTokenId)
    result
      .then(res => {
        I18nLoader.loadFromGiven(res.data.player.locale)
        setSessionToken(res.headers.authorization)
        setPlayer(res.data.player)
        setWeekday(res.data.weekday)
        setTrainingMoment(res.data.training_moment)
        setTrainingType(res.data.training_type)
        setAddExtraQuestions(res.data.add_extra_questions)
        setDisabledQuestionnaireKeys(res.data.disabled_questionnaire_keys)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const getSession = invitationTokenId => {
    return axios.get(`/api/v1/fill_out_sessions/${invitationTokenId}`)
  }

  const setLocale = newLocale => {
    const player = { locale: newLocale }
    axios({
      method: 'PATCH',
      url: '/api/v1/locales/set_locale',
      data: { player },
      headers: { Authorization: sessionToken, 'JWT-AUD': aud }
    }).then(res => {
      I18nLoader.loadFromGiven(res.data.locale)
      forceUpdate()
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const fetchTeams = () => {
    const result = getTeams()
    result
      .then(res => { setTeams(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const getTeams = () => {
    return axios.get('/api/v1/teams', { headers: { Authorization: sessionToken, 'JWT-AUD': aud } })
  }

  const handleQuestionnaireCompletion = (_cPlayer, values, cb) => {
    const response = {
      opened_at: values.openedAt,
      completed_at: values.completedAt,
      contents: values
    }
    axios({
      method: 'POST',
      url: '/api/v1/responses/create_from_fill_out_session',
      data: { response },
      headers: { Authorization: sessionToken, 'JWT-AUD': aud }
    }).then((_response) => {
      props.flashMessages.push(
        I18n.t('teampro.player.answer_stored'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO
      )
      window.scrollTo(0, 0)
      setQuestionnaireCompleted(true)
    }).catch(err => {
      console.error('Error creating response:')
      props.flashMessages.push(
        I18n.t('teampro.player.something_went_wrong_saving_questionnaire'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.ERROR
      )
      console.log(err)
      if (cb) cb()
    })
  }

  return (
    <FillOutSessionRoutes
      fillOutProps={{
        sessionToken,
        teams,
        fetchSession,
        fetchTeams,
        disabledQuestionnaireKeys,
        player,
        weekday,
        trainingMoment,
        trainingType,
        addExtraQuestions,
        questionnaireCompleted,
        onQuestionnaireCompletion: handleQuestionnaireCompletion,
        setLocale
      }}
    />
  )
}

export default WithFlashMessages(FillOutSessionController)
