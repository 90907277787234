import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import ExportData from './layout/ExportData'

const ExportsRoutes = (props) => {
  const { exportDataProps } = props
  return (
    <NotFoundWrapper slug='exports'>
      <Route
        exact path='/exports'
        render={props => (<ExportData {...props} {...exportDataProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default ExportsRoutes
