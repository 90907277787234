import React, { useState } from 'react'
import { MyAxios as axios } from '../MyAxios'
import TeamsRoutes from './TeamsRoutes'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'

const TeamsController = props => {
  const [teams, setTeams] = useState([])
  const [polarPlayers, setPolarPlayers] = useState(undefined)

  const fetchTeams = () => {
    const result = getTeams()
    result
      .then(res => { setTeams(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const fetchPolarPlayers = () => {
    const result = getPolarPlayers()
    result
      .then(res => { setPolarPlayers(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const destroyTeam = id => {
    return axios.delete(`/api/v1/teams/${id}`, { headers: { ...props.authorizationHeaders() } })
  }

  const updateTeam = (id, team) => {
    return axios.patch(`/api/v1/teams/${id}`, team, { headers: { ...props.authorizationHeaders() } })
  }

  const createTeam = team => {
    return axios.post('/api/v1/teams', team, { headers: { ...props.authorizationHeaders() } })
  }

  const getTeams = () => {
    return axios.get('/api/v1/teams', { headers: { ...props.authorizationHeaders() } })
  }

  const getPolarPlayers = () => {
    return axios.get('/api/v1/polar_players', { headers: { ...props.authorizationHeaders() } })
  }

  const destroyPlayer = id => {
    return axios.delete(`/api/v1/players/${id}`, { headers: { ...props.authorizationHeaders() } })
  }

  const updatePlayer = (id, player) => {
    return axios.patch(`/api/v1/players/${id}`, player, { headers: { ...props.authorizationHeaders() } })
  }

  const createPlayer = player => {
    return axios.post('/api/v1/players', player, { headers: { ...props.authorizationHeaders() } })
  }

  return (
    <TeamsRoutes
      overviewProps={{
        teams,
        fetchTeams,
        destroyTeam
      }}
      editProps={{
        teams,
        fetchTeams,
        updateTeam,
        destroyPlayer
      }}
      newProps={{
        createTeam
      }}
      newPlayerProps={{
        teams,
        fetchTeams,
        createPlayer
      }}
      editPlayerProps={{
        teams,
        fetchTeams,
        fetchPolarPlayers,
        polarPlayers,
        updatePlayer
      }}
    />
  )
}

export default WithFlashMessages(WithSession(TeamsController))
