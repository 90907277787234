import I18n from 'i18n'

// Don't translate strings that were already translated. This function is needed because the database validations
// can return strings that are sometimes already translated (for the default activerecord stuff) and sometimes not
// (for our own custom validations). We chose not to translate our own custom validations so that translation
// occurs in the front-end as much possible.
export function smartTranslate (str) {
  let translatedStr = I18n.t(str, { defaults: [{ message: str }] })
  if (typeof translatedStr !== 'string') translatedStr = str

  if (translatedStr.substr(0, 10) === '[missing "' && translatedStr.substr(-14) === '" translation]') {
    return str
  }
  return translatedStr
}

export function smarterTranslate (str, defaultMessage) {
  let translatedStr = I18n.t(str, { defaults: [{ message: defaultMessage }] })
  if (typeof translatedStr !== 'string') translatedStr = str

  if (translatedStr.substr(0, 10) === '[missing "' && translatedStr.substr(-14) === '" translation]') {
    return defaultMessage
  }
  return translatedStr
}

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1).replace(/_/g, ' ')
}
