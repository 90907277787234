import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import { changeProperty } from '../../teampro/Utils'
import { LOCALES, ROLES } from '../../teampro/Constants'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import I18n from 'i18n'
import classNames from 'classnames'
import PinCode from '../../pin_code/PinCode'

const NewPlayer = (props) => {
  const [name, setName] = useState('')
  const [jerseyNumber, setJerseyNumber] = useState('')
  const [role, setRole] = useState('')
  const [locale, setLocale] = useState('')
  const [mobilePhone, setMobilePhone] = useState('')
  const { match: { params: { teamId } } } = props
  const team = props.teams.filter(team => `${team.id}` === teamId)[0]

  useEffect(() => {
    props.fetchTeams()
  }, [])

  useEffect(() => {
    window.M.updateTextFields()
  }, [])

  useEffect(() => {
    const elem = document.getElementById('name')
    if (elem) elem.focus()
  }, [props.teams])

  const createPlayer = () => {
    props.createPlayer({ player: { jersey_number: jerseyNumber, name, role, locale, mobile_phone: mobilePhone, team_id: teamId } }).then(() => {
      props.flashMessages.push(
        I18n.t('teams.flash_messages.player_created'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO)
      props.history.push(`/teams/${teamId}/edit`)
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  if (team === undefined) return <></>

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.teams'), link: '/teams' },
          { name: team.name, link: `/teams/${teamId}/edit` }, { name: I18n.t('menu.add_player') }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('teams.new_player.add_player')}</h4>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='name'
                type='text'
                className='validate'
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <label htmlFor='name'>{I18n.t('teams.new_player.name')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <input
                id='jersey-number'
                type='text'
                className='validate'
                value={jerseyNumber}
                onChange={e => setJerseyNumber(e.target.value)}
              />
              <label htmlFor='jersey-number'>{I18n.t('teams.new_player.jersey_number')}</label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <label className={classNames(role && 'active')}>{I18n.t('teams.new_player.role')}</label>
              <select
                className='browser-default'
                id='role-selector'
                value={role}
                onChange={() => changeProperty('role-selector', setRole)}
              >
                <option disabled value='' />
                {ROLES.map(role => {
                  return (
                    <option key={role} value={role}>{I18n.t(`teams.new_player.roles.${role}`)}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='mobile-phone'
                type='text'
                className='validate'
                value={mobilePhone}
                onChange={e => setMobilePhone(e.target.value)}
              />
              <label htmlFor='mobile-phone'>{I18n.t('teams.new_player.mobile_phone')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <label className={classNames(locale && 'active')}>{I18n.t('teams.new_player.locale')}</label>
              <select
                className='browser-default'
                id='locale-selector'
                value={locale}
                onChange={() => changeProperty('locale-selector', setLocale)}
              >
                <option disabled value='' />
                {LOCALES.map(locale => {
                  return (
                    <option key={locale} value={locale}>{I18n.t(`teams.new_player.locales.${locale}`)}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12'>
              <button type='submit' className='btn waves-effect waves-light' onClick={createPlayer}>
                {I18n.t('teams.new_player.create_player')}
              </button>
            </div>
          </div>
        </div>
      </PinCode>
    </>
  )
}

export default WithFlashMessages(NewPlayer)
