import React from 'react'
import classNames from 'classnames'

const Pagination = ({ pageInfo, history }) => {
  if (!pageInfo || pageInfo.total_pages < 2) return <></>
  const maxPagesToShow = 9
  // create an array with values [1 .. pageCount]
  let pagesToShow = Array.from(Array(pageInfo.total_pages + 1).keys())

  // remove page 0, since it is not a valid page
  pagesToShow.shift()

  if (pageInfo.total_pages > maxPagesToShow) {
    const currentPageIdx = pageInfo.current_page - 1
    // 1st page (index 0) is always shown, so start at least at index 1
    const minPageIdx = 1

    // last page (at index pageCount - 1) is always included, so end latest at pageCount - 2
    const maxPageIdx = pageInfo.total_pages - 1

    // prevent startIdx from going out of bounds on both sides
    const startIdx = Math.min(Math.max(currentPageIdx - Math.floor((maxPagesToShow - 2) / 2), minPageIdx), maxPageIdx)
    // we know startIdx is valid, so prevent startIdx + maxPagesToShow from going out of bounds on the right
    const endIdx = Math.min(startIdx + maxPagesToShow - 2, maxPageIdx)

    // if endIdx - startIdx < maxPagesToShow - 2, we need to crrect the startIdx for the difference
    // Since this case only occurs at the end of the page list, and we know that the number of pages is greater
    // than what will be shown, we can safely subtract the difference from the startIdx
    const correction = maxPagesToShow - 2 - (endIdx - startIdx)
    // apply the correction to startIdx, and add 1 to endIdx, since slice returns elements up to (but not including) the given end
    pagesToShow = pagesToShow.slice(startIdx - correction, endIdx)
  }
  // only add the first page if it is not yet in the list
  if (pagesToShow[0] !== 1) { pagesToShow.unshift(1) }
  // only add the last page if it is not yet in the list

  if (pagesToShow[pagesToShow.length - 1] !== pageInfo.total_pages) { pagesToShow.push(pageInfo.total_pages) }

  const myFetchPage = (e, page) => {
    e.preventDefault()
    history.push(`${history.location.pathname}?page=${page}`)
  }

  // render each page link, and add a first and last button if the current range does not include the first or last page
  return (
    <div className='row'>
      <div className='col s12 center-align'>
        <ul className='pagination'>
          <li className={classNames(pageInfo.current_page !== 1 && 'waves-effect', { disabled: pageInfo.current_page === 1 })} onClick={(e) => myFetchPage(e, pageInfo.current_page - 1)}>
            <span className='page-button'><i className='material-icons'>chevron_left</i></span>
          </li>
          {pagesToShow.map(page => {
            return (
              <li
                key={`pagination-${page}`}
                className={classNames('waves-effect', { active: page === pageInfo.current_page })}
                onClick={(e) => myFetchPage(e, page)}
              >
                <span className='page-button'>
                  {page}
                </span>
              </li>
            )
          })}
          <li className={classNames(pageInfo.current_page !== pageInfo.total_pages && 'waves-effect', { disabled: pageInfo.current_page === pageInfo.total_pages })} onClick={(e) => myFetchPage(e, pageInfo.current_page + 1)}>
            <span className='page-button'><i className='material-icons'>chevron_right</i></span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Pagination
