export const datePickerDefaultOptions = Object.freeze({
  dateFormat: 'dd-MM-yyyy',
  showYearDropdown: true,
  showMonthDropdown: true,
  isClearable: true
})

export const dateAndTimePickerDefaultOptions = Object.freeze({
  dateFormat: 'dd-MM-yyyy HH:mm',
  showYearDropdown: true,
  showMonthDropdown: true,
  showTimeSelect: true,
  timeIntervals: 10
})

export const shorterMomentDateFormatWithTime = 'DD-MM-YYYY HH:mm'
export const fullMomentDateFormatWithTime = 'dddd LLL'
export const fullMomentDateFormat = 'dddd LL'

// TODO: use default highcharts options from quest-front-end
export const hcOrange = '#fa8832'
export const hcDarkOrange = '#da6105'
export const hcLightGreen = '#daeada'
export const hcGreen = '#0a720a'
export const hcRed = '#be1111'
export const hcBlack = '#34393c'

export const hcLightGrey = '#F3F3F3'
export const hcDarkGrey = '#b2b2b2'
export const hcFadedRed = '#E46151'
export const hcBlue = '#41B5E9'

export const hcDayPartColor = '#08306b'
export const hcTypeColor = '#00441b'
export const hcSessionColor = '#67000d'

export const hcInactiveIconColor = '#dcdcdc'
export const hcTitleColor = '#333'
export const hcSubTitleColor = '#444'
export const hcAxisLabelColor = '#666'

export const hcTitleFont = '"Raleway", arial, sans-serif'
export const hcChartFont = '"Open Sans", arial, sans-serif'

export const hcTitleFontSize = 18
export const hcTitleLineHeight = 1
export const hcTitlefontWeight = 'bold'

export const hcAxisFontSize = 16.8 // For the labels, not for the ticks

export const highchartsThemeElementary = Object.freeze({
  chart: {
    style: {
      color: hcTitleColor,
      fontFamily: hcChartFont
    },
    height: 600
  },
  title: {
    style: {
      fontFamily: hcTitleFont,
      fontWeight: 'bold'
    }
  },
  subtitle: {
    style: {
      fontFamily: hcTitleFont,
      fontWeight: '100'
    }
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 0.7
        }
      }
    }
  },
  legend: {
    enabled: false,
    align: 'right',
    verticalAlign: 'bottom'
  },
  xAxis: {
    gridLineWidth: 1,
    gridLineColor: hcLightGrey,
    lineColor: hcLightGrey,
    minorGridLineColor: hcLightGrey,
    tickColor: hcLightGrey,
    tickWidth: 1
  },
  yAxis: {
    gridLineColor: hcLightGrey,
    lineColor: hcLightGrey,
    minorGridLineColor: hcLightGrey,
    tickColor: hcLightGrey,
    tickWidth: 1
  },
  credits: {
    enabled: false
  },
  tooltip: {
    crosshairs: false,
    outside: true
  },
  global: {
    useUTC: false
  }
})

// Visualizations
export const ostrcSumField = 'vOSTRC'
export const emiSumField = 'vEMI'
export const emhSumField = 'vEMH'

export const QUESTION_MAPPING = Object.freeze({
  v0: 'coaching',
  v1: 'emh_training1', // training 1
  v2: 'self_efficacy_training', // training
  v3: 'motivation_training', // training
  v4: 'mood_training', // training
  v5: 'emi_training1', // training 1
  v6: 'performance_training', // training
  v7: 'enjoyment_training', // training
  v8: 'emi_training2', // training 2
  v9: 'emh_match', // match
  v10: 'self_efficacy_match', // match
  v11: 'motivation_match', // match
  v12: 'mood_match', // match
  v13: 'emi_match', // match
  v14: 'performance_match', // match
  v15: 'enjoyment_match', // match
  v16: 'special_event', // special event
  v17: 'valence_spec_event',
  v18: 'relation_spec_event',
  v19: 'ostrc1',
  v20: 'ostrc2',
  v21: 'ostrc3',
  v22: 'ostrc4',
  [ostrcSumField]: 'ostrc_sum',
  [emiSumField]: 'emi_sum',
  [emhSumField]: 'emh_sum'
})

export const trainingFields = ['v1', 'v2', 'v3', 'v4', 'v5', 'v6', 'v7', 'v8']
export const matchFields = ['v9', 'v10', 'v11', 'v12', 'v13', 'v14', 'v15']
export const specialEventFields = ['v17'] // also 16 and 18
export const ostrcFields = ['v19', 'v20', 'v21', 'v22']
export const emiFields = ['v5', 'v8', 'v13']
export const emhFields = ['v1', 'v9']
