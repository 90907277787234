import axios from 'axios'

const defaultOptions = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
}

export const MyAxios = axios.create(defaultOptions)

// Fetches all the pages of a paginated response
export const getAllPages = async (url, headers, responseCallback = () => {}) => {
  let response = []
  let hasNext = true
  let nextPage = 1

  while (hasNext) {
    const res = await MyAxios({
      method: 'GET',
      url: `${url}?page=${nextPage}`,
      headers
    })
    response = response.concat(res.data.data)
    hasNext = res.data.page.has_next
    nextPage = res.data.page.current_page + 1

    responseCallback(res)
  }

  return response
}

export default MyAxios
