import React, { useEffect, useState } from 'react'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithModal } from '../../modal/ModalProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import I18n from 'i18n'
import moment from 'moment'
import { fullMomentDateFormatWithTime } from '../../common/Constants'
import { translateTrainingMoment, translateTrainingType, translateWeekday } from '../../teampro/Utils'
import { INDEPENDENT_OF_A_TRAINING } from '../../teampro/Constants'
import Spinner from '../../common/Spinner'
import Pagination from '../../common/Pagination'
import CalendarMonthView from 'react-calendar-month-view'
import { smartRounding } from '../../common/Math'
import ReactTooltip from 'react-tooltip'
import MyReactTooltip from '../../MyReactTooltip'

const TrainingSessionsUpcoming = (props) => {
  const page = new URLSearchParams(props.location.search).get('page') || '1'
  const [calendarView, setCalendarView] = useState(false)

  useEffect(() => {
    props.fetchFutureTrainingSessions(page)
  }, [page])

  useEffect(() => {
    if (props.month) handleMonthChange()
  }, [props.month])

  useEffect(() => {
    setTimeout(() => { ReactTooltip.rebuild() }, 200)
  }, [calendarView])

  const editTrainingSession = id => {
    props.history.push(`/training-sessions/${id}/edit`)
  }

  const destroyTrainingSession = id => {
    props.destroyTrainingSession(id).then(() => {
      props.flashMessages.push(
        I18n.t('training_sessions.flash_messages.training_session_deleted'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO)
      props.fetchFutureTrainingSessions(page)
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const showDestroyTrainingSessionDialog = id => {
    props.setConfirmationDialog({
      onConfirmation: () => destroyTrainingSession(id),
      target: I18n.t('training_sessions.overview.destroy_training_session_prompt'),
      action: 'custom',
      noQuotes: true
    })
  }

  const trainingSessionTimeString = trainingSession => {
    if (trainingSession.scheduled_at) {
      return moment(trainingSession.scheduled_at).format(fullMomentDateFormatWithTime)
    }

    return moment(trainingSession.created_at).format(fullMomentDateFormatWithTime)
  }

  const trainingTimeString = trainingSession => {
    if (trainingSession.scheduled_at) {
      return moment(trainingSession.scheduled_at).format('HH:mm')
    }

    return moment(trainingSession.created_at).format('HH:mm')
  }

  const trainingSessionString = trainingSession => {
    const timeString = trainingSessionTimeString(trainingSession)
    if (trainingSession.invitation_state === 'new') {
      return (
        <>
          {timeString}{' '}
          <i className='material-icons inline-icon'>schedule</i>
        </>
      )
    }
    const notFilledOutString = completionString(trainingSession)
    return (
      <>
        {timeString}{' '}
        {notFilledOutString && <span className='not-filled-out'>{notFilledOutString}</span>}
      </>
    )
  }

  const completionString = trainingSession => {
    const total = trainingSession.fill_out_sessions.length
    const completed = trainingSession.fill_out_sessions.filter(fillOutSession => fillOutSession.completed_at).length
    if (trainingSession.invitation_state !== 'finished_processing' || total === 0 || total === completed) {
      return ''
    }
    const notFilledOut = total - completed
    const notFilledOutPerc = smartRounding(100 * notFilledOut / total, 1)
    return `${I18n.t('training_sessions.overview.not_filled_out')}: ${notFilledOut} (${notFilledOutPerc}%)`
  }

  const trainingMomentString = trainingSession => {
    return translateTrainingMoment(trainingSession.training_moment)
  }

  const trainingTypeString = trainingSession => {
    return translateTrainingType(trainingSession.training_type, props.teams)
  }

  const trainingDayString = trainingSession => {
    return translateWeekday(trainingSession.weekday)
  }

  const extraQuestionsString = trainingSession => {
    if (trainingSession.add_extra_questions && trainingSession.training_moment && trainingSession.training_moment !== INDEPENDENT_OF_A_TRAINING) {
      return <span className='text-muted'> + {I18n.t('teampro.manager.extra_questions')}</span>
    }
    return <></>
  }

  const playerList = trainingSession => {
    if (!trainingSession.fill_out_sessions || trainingSession.fill_out_sessions.length === 0) return undefined

    return `<div>${trainingSession.fill_out_sessions.map(fillOutSession => fillOutSession.player.name).join(', ')}</div>`
  }

  const renderDay = day => {
    if (props.trainingSessionsByDates === undefined) return <></>

    const dayMoment = moment(day)
    const beginDay = moment(dayMoment.startOf('day').toDate())
    const endDay = moment(dayMoment.endOf('day').toDate())

    const onThisDay = props.trainingSessionsByDates.filter(trainingSession => {
      const trainingSessionDate = moment(trainingSession.scheduled_at || trainingSession.created_at)
      return trainingSessionDate >= beginDay && trainingSessionDate <= endDay
    })
    if (onThisDay.length === 0) return null
    return (
      <div className='text-xs calendar-cell'>
        {onThisDay.slice(0, 4).map(trainingSession => (
          <div
            className='training-session-calendar'
            key={trainingSession.id}
            data-for='training-sessions-tooltip'
            data-tip={playerList(trainingSession)}
            onClick={() => props.history.push(`/training-sessions/${trainingSession.id}/edit`)}
          >
            {trainingTimeString(trainingSession)}{' '}
            <span className='hide-on-phone'>
              {trainingMomentString(trainingSession)}, {trainingTypeString(trainingSession)}
            </span>
          </div>
        ))}
      </div>
    )
  }

  const handleMonthChange = () => {
    const monthBegin = moment(props.month).startOf('month').toDate()
    const monthEnd = moment(props.month).endOf('month').toDate()
    props.fetchFutureTrainingSessionsByDates(monthBegin, monthEnd)
  }

  return (
    <>
      <ul className='collection with-header'>
        <li className='collection-header'>
          <h4>{I18n.t('training_sessions.tabs.upcoming.title')} {props.pageInfo && props.pageInfo.current_page !== 1 && !calendarView && `- ${I18n.t('page')} ${props.pageInfo.current_page}`}</h4>
          {props.trainingSessions !== undefined && props.trainingSessions.length > 0 && (
            <div className='calendarview-toggle' onClick={() => setCalendarView(!calendarView)}>
              {I18n.t('training_sessions.overview.switch_to')}
              {calendarView && <i className='material-icons margin-left small'>dehaze</i>}
              {!calendarView && <i className='material-icons margin-left small'>date_range</i>}
            </div>
          )}
        </li>
        {(props.trainingSessions === undefined || props.trainingSessionsByDates === undefined) && (
          <Spinner transparent />
        )}
        {props.trainingSessions !== undefined && props.trainingSessions.length === 0 && (
          <div className='col s12'>
            <p><em>{I18n.t('training_sessions.overview.there_are_no_training_sessions')}</em></p>
          </div>
        )}
        <div style={{ display: calendarView ? 'flex' : 'none' }} className='calendarmonthview-container'>
          <CalendarMonthView
            renderDay={renderDay} width='100%'
            onMonthChange={(startDate) => props.setMonth(startDate)}
            dayTextStyle={{
              width: '25px',
              height: '25px',
              margin: '0',
              left: 'unset',
              top: '0',
              right: '0'
            }}
            inactiveDayStyle={{
              backgroundColor: '#F8F8F8'
            }}
            activeDayStyle={{
              backgroundColor: '#F0F0F0',
              borderWidth: '0'
            }}
          />
          <MyReactTooltip className='high-z' effect='solid' id='training-sessions-tooltip' html />
        </div>
        <div style={{ display: calendarView ? 'none' : 'block' }}>
          {props.trainingSessions !== undefined && props.trainingSessions.length > 0 && (
            props.trainingSessions.map(trainingSession => {
              return (
                <li key={trainingSession.id} className='collection-item'>
                  <div>
                    <div
                      className='pointer card-clickable'
                      onClick={() => editTrainingSession(trainingSession.id)}
                    >
                      <span className='title'>{trainingSessionString(trainingSession)}</span>
                      <p>
                        <span className='text-muted'>{trainingTypeString(trainingSession)}</span>{' '}
                        <span className='text-muted'>{trainingDayString(trainingSession)}
                        </span>{extraQuestionsString(trainingSession)}<br />
                        <span className='text-muted'>{trainingMomentString(trainingSession)}</span>
                      </p>
                    </div>
                    <a
                      href={`/training-sessions/${trainingSession.id}/edit`}
                      className='secondary-content margin-left'
                      title={I18n.t('training_sessions.overview.edit_training_session')}
                      onClick={e => {
                        e.preventDefault()
                        editTrainingSession(trainingSession.id)
                      }}
                    >
                      <i className='material-icons'>edit</i>
                    </a>
                    <a
                      href='#!'
                      className='secondary-content'
                      title={I18n.t('training_sessions.overview.delete_training_session')}
                      onClick={e => {
                        e.preventDefault()
                        showDestroyTrainingSessionDialog(trainingSession.id)
                      }}
                    >
                      <i className='material-icons'>delete</i>
                    </a>
                  </div>
                </li>
              )
            }))}
        </div>
      </ul>
      <div style={{ display: calendarView ? 'none' : 'block' }}>
        {props.trainingSessions !== undefined && (
          <Pagination
            pageInfo={props.pageInfo}
            history={props.history}
          />
        )}
      </div>

    </>
  )
}

export default WithModal(WithFlashMessages(TrainingSessionsUpcoming))
