import React, { useState, useEffect } from 'react'
import { WithSession } from '../../session/SessionProvider'
import I18n from 'i18n'
import DatePicker from 'react-datepicker'
import { datePickerDefaultOptions } from '../../common/Constants'
import classNames from 'classnames'

const AddToken = props => {
  const [description, setDescription] = useState('')
  const [expiresAt, setExpiresAt] = useState(undefined)
  const [token, setToken] = useState({})

  useEffect(() => {
    window.M.updateTextFields()
  })

  const afterCreated = (subToken) => {
    setToken(subToken)
    setDescription('')
    setExpiresAt(undefined)
  }

  const addToken = () => {
    setToken({})
    props.createToken({ sub_token: { description, expires_at: expiresAt } }, afterCreated)
  }

  const generateUrl = () => {
    return `${process.env.HOST_URL}/sub-account/${token.id}/${token.token}`
  }

  return (
    <>
      {token?.token && (
        <div className='row'>
          <div className='col s12'>
            <div className='card'>
              <div className='card-content'>
                <p>
                  {I18n.t('sub_tokens.url_to_log_in')}<br /><br />
                  <tt>{generateUrl()}</tt><br /><br />
                  <span className='warning-colored fat'>{I18n.t('sub_tokens.only_once_warning')}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='input-field col s12 m5'>
          <input
            id='description'
            type='text'
            className='validate'
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <label htmlFor='description'>{I18n.t('sub_tokens.new_token.description')}</label>
        </div>
        <div className='col s8 m3 input-field'>
          <DatePicker
            id='expires-at' {...datePickerDefaultOptions}
            selected={expiresAt}
            onChange={date => setExpiresAt(date)}
            minDate={new Date()}
          />
          <label htmlFor='expires-at' className={classNames({ active: expiresAt })}>{I18n.t('sub_tokens.new_token.expires_at')}</label>
        </div>
        <div className='input-field col s12 m4'>
          <button type='submit' className='btn waves-effect waves-light' onClick={addToken}>
            <i className='material-icons'>add</i>{' '}
            {I18n.t('sub_tokens.new_token.create')}
          </button>
        </div>
      </div>
    </>
  )
}

export default WithSession(AddToken)
