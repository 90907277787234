import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Callback from './Callback'

const CallbackRoutes = props => {
  const { callbackProps } = props
  return (
    <NotFoundWrapper slug='callback'>
      <Route
        path='/callback'
        render={props => (<Callback {...props} {...callbackProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default CallbackRoutes
