import React, { useState } from 'react'
import { MyAxios as axios } from '../MyAxios'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'
import VisualizationsRoutes from './VisualizationsRoutes'
import I18n from 'i18n'

const VisualizationsController = props => {
  const [teams, setTeams] = useState([])

  const fetchTeams = () => {
    const result = getTeams()
    result
      .then(res => { setTeams(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const getTeams = () => {
    return axios.get('/api/v1/teams', { headers: { ...props.authorizationHeaders() } })
  }

  const destroyResponse = id => {
    return axios.delete(`/api/v1/responses/${id}`, { headers: { ...props.authorizationHeaders() } })
  }

  const handleUpdateUser = (user) => {
    axios.patch('/api/v1/users', { user }, { headers: { ...props.authorizationHeaders() } }).then(_res => {
      props.flashMessages.push(I18n.t('profile.edit.update_success'), props.flashMessages.duration.SHORT, props.flashMessages.levels.INFO)
      props.refetchSession()
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  return (
    <VisualizationsRoutes
      overviewProps={{
        teams,
        fetchTeams,
        destroyResponse,
        onUpdateUser: handleUpdateUser
      }}
    />
  )
}

export default WithFlashMessages(WithSession(VisualizationsController))
