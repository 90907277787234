import React, { useState, useEffect } from 'react'
import Questionnaire from '../questionnaires/Questionnaire'
import PlayerName from './PlayerName'
import { INDEPENDENT_OF_A_TRAINING, COACH } from '../Constants'
import I18n from 'i18n'
import { allTrainingMoments } from '../Utils'

const PlayerInterface = (props) => {
  const [playerQuery, setPlayerQuery] = useState('')

  useEffect(() => {
    window.M.updateTextFields()
  })

  const filterForQuery = (players) => {
    if (!playerQuery.length) return players
    const regex = playerQuery.replace(' ', '.*')
    return players.filter(player =>
      (player.jersey_number !== '-1' && player.jersey_number.toLowerCase().match(regex)) ||
      player.name.toString().toLowerCase().match(regex)
    )
  }

  const renderPlayer = (cPlayer, handleClick) => {
    if (cPlayer.completed) {
      return (
        <li key={cPlayer.id} className='player-selector completed'>
          <div className='collapsible-header'>
            <PlayerName player={cPlayer} completed />
          </div>
        </li>
      )
    }
    return (
      <li key={cPlayer.id} className='player-selector' onClick={handleClick}>
        <div className='collapsible-header'>
          <PlayerName
            player={cPlayer}
            teams={props.teams}
            setTrainingType={cTrainingType => props.setPlayerTrainingType(cPlayer, cTrainingType)}
            trainingType={props.trainingType}
            setTrainingMoment={cTrainingMoment => props.setPlayerTrainingMoment(cPlayer, cTrainingMoment)}
            trainingMoment={props.trainingMoment}
            trainingMoments={allTrainingMoments()}
          />
        </div>
      </li>
    )
  }

  const handleClick = (e, cplayer) => {
    if (e.target && (e.target.classList.contains('select-dropdown') || e.target.classList.contains('browser-default'))) {
      // do nothing
    } else {
      e.preventDefault()
      props.setPlayer(cplayer)
    }
  }

  const renderPlayerSelect = () => {
    if (!props.selectedPlayers.length) return <p><em>{I18n.t('teampro.player.search.no_players_selected')}</em></p>
    const filteredPlayers = filterForQuery(props.selectedPlayers)
    return (
      <>
        <h4>{I18n.t('teampro.player.click_your_name')}</h4>
        <div className='row'>
          <div className='input-field col s12'>
            <i className='material-icons prefix'>search</i>
            <input
              type='text' id='autocomplete-input' className='autocomplete'
              value={playerQuery}
              onChange={e => setPlayerQuery(e.target.value.toLowerCase())}
            />
            <label htmlFor='autocomplete-input' className='no-select'>{I18n.t('teampro.player.search.prompt')}</label>
          </div>
        </div>
        {!filteredPlayers.length && <p><em>{I18n.t('teampro.player.search.no_players_matching_query')}</em></p>}
        {filteredPlayers.length > 0 && (
          <ul className='collapsible'>
            {filteredPlayers.map(cplayer => renderPlayer(cplayer, (e) => handleClick(e, cplayer)))}
          </ul>
        )}
      </>
    )
  }

  return (
    <>
      {!props.player && renderPlayerSelect()}
      {props.player && (
        <Questionnaire
          selectedPlayers={props.selectedPlayers}
          disabledQuestionnaireKeys={props.disabledQuestionnaireKeys(props.player)}
          initialValues={{
            playerId: props.player.id,
            jerseyNumber: props.player.jersey_number,
            teamId: props.player.team_id,
            name: props.player.name,
            role: props.player.role,
            extraQuestions: props.player.role !== COACH && props.getPlayerTrainingMoment(props.player) !== INDEPENDENT_OF_A_TRAINING && props.extraQuestions,
            trainingType: props.getPlayerTrainingType(props.player),
            weekday: props.weekday,
            trainingMoment: props.getPlayerTrainingMoment(props.player),
            openedAt: new Date().toISOString(),
            trainingSessionId: props.trainingSessionId
          }}
          onQuestionnaireCompletion={(values, cb) => { setPlayerQuery(''); props.onQuestionnaireCompletion(props.player, { ...values, completedAt: new Date().toISOString() }, cb) }}
        />
      )}
    </>
  )
}

export default PlayerInterface
