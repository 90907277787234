import React, { useEffect } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithModal } from '../../modal/ModalProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import I18n from 'i18n'
import moment from 'moment'
import { shorterMomentDateFormatWithTime } from '../../common/Constants'
import { translateTrainingMoment, translateTrainingType, translateWeekday } from '../../teampro/Utils'
import { INDEPENDENT_OF_A_TRAINING } from '../../teampro/Constants'
import PlayerName from '../../teampro/layout/PlayerName'
import Spinner from '../../common/Spinner'
import PinCode from '../../pin_code/PinCode'
import Pagination from '../../common/Pagination'

const ResponsesOverview = props => {
  const page = new URLSearchParams(props.location.search).get('page') || '1'

  useEffect(() => {
    props.fetchTeams()
  }, [])

  useEffect(() => {
    props.fetchResponses(page)
  }, [page])

  const editResponse = id => {
    props.history.push(`/responses/${id}/edit`)
  }

  const destroyResponse = id => {
    props.destroyResponse(id).then(() => {
      props.flashMessages.push(
        I18n.t('responses.flash_messages.response_deleted'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO)
      props.fetchResponses(page)
    }).catch(err => {
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      props.flashMessages.push(
        errorTitles,
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    })
  }

  const showDestroyResponseDialog = id => {
    props.setConfirmationDialog({
      onConfirmation: () => destroyResponse(id),
      target: I18n.t('responses.overview.destroy_response_prompt'),
      action: 'custom',
      noQuotes: true
    })
  }

  const openedAtString = response => {
    return moment(response.opened_at).format(shorterMomentDateFormatWithTime)
  }

  const completedAtString = response => {
    return moment(response.completed_at).format(shorterMomentDateFormatWithTime)
  }

  const playerString = response => {
    return (
      <PlayerName player={{
        name: response.contents.name,
        jersey_number: response.contents.jerseyNumber,
        role: response.contents.role,
        id: response.contents.playerId
      }}
      />
    )
  }

  const trainingMomentString = response => {
    return translateTrainingMoment(response.contents.trainingMoment)
  }

  const trainingTypeString = response => {
    return translateTrainingType(response.contents.trainingType, props.teams)
  }

  const trainingDayString = response => {
    return translateWeekday(response.contents.weekday)
  }

  const extraQuestionsString = response => {
    if (response.contents.extraQuestions && response.contents.trainingMoment && response.contents.trainingMoment !== INDEPENDENT_OF_A_TRAINING) {
      return <span className='text-muted'> + {I18n.t('teampro.manager.extra_questions')}</span>
    }
    return <></>
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.responses') }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('menu.responses')} {props.pageInfo && props.pageInfo.current_page !== 1 && `- ${I18n.t('page')} ${props.pageInfo.current_page}`}</h4>
              {props.responses === undefined && (
                <Spinner transparent />
              )}
              {props.responses !== undefined && props.responses.length === 0 && (
                <p><em>{I18n.t('responses.overview.there_are_no_responses')}</em></p>
              )}
              {props.responses !== undefined && props.responses.length > 0 && (
                <table className='highlight responsive-table'>
                  <thead>
                    <tr>
                      <th>{I18n.t('responses.attributes.id')}</th>
                      <th>{I18n.t('responses.attributes.opened_at')}</th>
                      <th>{I18n.t('responses.attributes.completed_at')}</th>
                      <th>{I18n.t('responses.attributes.player')}</th>
                      <th>{I18n.t('responses.attributes.training_type')}</th>
                      <th>{I18n.t('responses.attributes.training_moment')}</th>
                      <th>{I18n.t('responses.attributes.actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.responses.map(response => {
                      return (
                        <tr key={response.id}>
                          <td>{response.id}</td>
                          <td>{openedAtString(response)}</td>
                          <td>{completedAtString(response)}</td>
                          <td>{playerString(response)}</td>
                          <td>{trainingTypeString(response)} {trainingDayString(response)}</td>
                          <td>{trainingMomentString(response)}{extraQuestionsString(response)}</td>
                          <td>
                            <a
                              href={`/responses/${response.id}/edit`}
                              className='secondary-content margin-left'
                              title={I18n.t('responses.overview.show_response')}
                              onClick={e => {
                                e.preventDefault()
                                editResponse(response.id)
                              }}
                            >
                              <i className='material-icons'>zoom_in</i>
                            </a>
                            <a
                              href='#!'
                              className='secondary-content'
                              title={I18n.t('responses.overview.delete_response')}
                              onClick={e => {
                                e.preventDefault()
                                showDestroyResponseDialog(response.id)
                              }}
                            >
                              <i className='material-icons'>delete</i>
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
              {props.responses !== undefined && (
                <Pagination
                  pageInfo={props.pageInfo}
                  history={props.history}
                />
              )}
            </div>
          </div>
        </div>
      </PinCode>
    </>
  )
}

export default WithModal(WithFlashMessages(ResponsesOverview))
