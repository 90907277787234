import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import FillOutSession from './FillOutSession'

const FillOutSessionRoutes = props => {
  const { fillOutProps } = props
  return (
    <NotFoundWrapper slug='fill-out'>
      <Route
        exact path='/fill-out/:invitationTokenId([a-z0-9]+)'
        render={props => (<FillOutSession {...props} {...fillOutProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default FillOutSessionRoutes
