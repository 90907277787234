import React from 'react'
import { optionalTranslate } from '../Utils'

const Raw = (props) => {
  const renderRaw = () => {
    return <div dangerouslySetInnerHTML={{ __html: optionalTranslate(props.html) }} />
  }

  return (
    <div className='section'>
      <div className='row'>
        <div className='col s12'>
          {renderRaw()}
        </div>
      </div>
    </div>
  )
}

export default Raw
