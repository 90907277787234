import React from 'react'
import classNames from 'classnames'
import { optionalTranslate } from '../Utils'

const Checkbox = (props) => {
  const renderCheckbox = () => {
    const setChecked = (value, checked) => {
      let newValue = props.value
      if (newValue === undefined) {
        newValue = []
      }
      if (checked && !newValue.includes(value)) {
        newValue.push(value)
      } else if (!checked && newValue.includes(value)) {
        newValue = newValue.filter(curVal => curVal !== value)
      }
      if (newValue.length === 0) {
        newValue = undefined
      }
      props.setValue(newValue)
    }

    return (
      <>
        {props.options.map(option => {
          if (typeof option !== 'object') {
            option = { value: option, label: option }
          }
          return (
            <p key={option.value}>
              <label>
                <input
                  type='checkbox'
                  value={option.value}
                  checked={props.value !== undefined && props.value.includes(`${option.value}`)}
                  onChange={e => setChecked(e.target.value, e.target.checked)}
                />
                <span>{optionalTranslate(option.label)}</span>
              </label>
            </p>
          )
        })}
      </>
    )
  }
  const translatedLabel = optionalTranslate(props.label)

  return (
    <div className={classNames('section', translatedLabel.length === 0 && 'no-title')} ref={props.myRef}>
      <div className={classNames('row', props.required && props.value === undefined && props.showErrors && 'show-errors')} onClick={() => { if (props.showErrors) { props.setShowErrors(false) } }}>
        <div className='col s12 l5'>
          <div className='question-label'>{translatedLabel}</div>
        </div>
        <div className='col s12 l7'>
          {renderCheckbox()}
        </div>
      </div>
    </div>
  )
}

export default Checkbox
