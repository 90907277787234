import React from 'react'

const Tab = ({ id, children }) => {
  return (
    <div id={id} className='col s12'>
      {children}
    </div>
  )
}

export default Tab
