import React, { useState, useEffect } from 'react'
import Question from './Question'
import { INDEPENDENT_OF_A_TRAINING, COACH, MATCHDAY, questionnaires } from '../Constants'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import I18n from 'i18n'
import Raw from './Raw'

const Questionnaire = (props) => {
  const [values, setValues] = useState(props.initialValues)
  const [showErrors, setShowErrors] = useState(false)
  const [submitEnabled, setSubmitEnabled] = useState(true)
  const extraQuestionsKey = INDEPENDENT_OF_A_TRAINING

  useEffect(() => {
    for (const elem of document.querySelectorAll('input[type=range]:not(.initialized)')) {
      window.M.Range.init(elem)
      elem.classList.add('initialized')
    }
  }, [values])

  const hideThumbs = () => {
    for (const elem of document.querySelectorAll('.thumb.active')) {
      setTimeout(() => {
        elem.style.height = '0px'
        elem.style.width = '0px'
        elem.classList.remove('active')
      }, 500)
    }
  }

  useEffect(() => {
    document.addEventListener('touchend', hideThumbs)
    return () => {
      document.removeEventListener('touchend', hideThumbs)
    }
  }, [])

  let questionnaire = questionnaires[props.initialValues.trainingMoment]
  if (props.initialValues.extraQuestions) {
    questionnaire = questionnaire.concat(questionnaires[extraQuestionsKey])
  }
  if (props.initialValues.role === COACH) {
    const evaluationPeriod = (props.initialValues.weekday === MATCHDAY ? I18n.t('teampro.questionnaire.match') : I18n.t('teampro.questionnaire.week'))
    questionnaire = []
    for (const cPlayer of props.selectedPlayers) {
      questionnaire.push({
        type: 'slider',
        required: true,
        id: `v0_${evaluationPeriod}_${cPlayer.id}_perf`,
        min: 0,
        max: 100,
        label: { nl: `Hoe goed heeft ${cPlayer.name} gepresteerd in de afgelopen ${evaluationPeriod}?`, en: `How did ${cPlayer.name} perform in this ${evaluationPeriod}?` },
        minLabel: { nl: 'zeer slecht (ver onder zijn kunnen)', en: 'very bad (far below his capabilities)' },
        maxLabel: { nl: 'maximaal (op de top van zijn kunnen)', en: 'maximally (to the best of his capabilities)' },
        showOnlyIf: [
          { [`v0_${evaluationPeriod}_${cPlayer.id}_nvt`]: undefined }
        ]
      })
      questionnaire.push({
        type: 'checkbox',
        required: false,
        id: `v0_${evaluationPeriod}_${cPlayer.id}_nvt`,
        label: '',
        options: [
          { value: 1, label: { nl: `Niet van toepassing voor ${cPlayer.name}`, en: `Not applicable for ${cPlayer.name}` } }
        ]
      })
    }
  }

  // Hide disabled questions
  if (props.disabledQuestionnaireKeys) {
    questionnaire = questionnaire.filter(question => !question.id || !props.disabledQuestionnaireKeys.includes(question.id))
  }

  const findQuestionFor = (qid) => {
    return questionnaire.filter(question => question.id === qid && isVisible(question))
  }

  const conditionHolds = (condition) => {
    let result = true
    for (const [qid, value] of Object.entries(condition)) {
      result = result && ((values[qid] !== undefined && values[qid] === value) || (value === undefined && values[qid] === value)) && isVisible(findQuestionFor(qid))
    }
    return result
  }

  const isVisible = (question) => {
    if (!question.showOnlyIf) {
      return true
    }
    let result = false
    for (const condition of question.showOnlyIf) {
      result = (result || conditionHolds(condition))
    }
    return result
  }

  const requiredValues = questionnaire.filter(question => question.required && isVisible(question)).map(question => question.id)

  const setValue = (qid, value) => {
    const newValues = JSON.parse(JSON.stringify(values))
    newValues[qid] = value
    setValues(newValues)
  }

  const sharedPropsFor = (qid) => {
    return {
      value: values[qid],
      setValue: value => setValue(qid, value),
      showErrors
    }
  }

  const checkQuestionnaireCompletion = () => {
    if (!submitEnabled) return
    let complete = true
    for (const requiredValue of requiredValues) {
      if (values[requiredValue] === undefined) {
        complete = false
      }
    }
    if (!complete) {
      props.flashMessages.push(
        I18n.t('teampro.questionnaire.you_havent_answered_all_questions'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.ERROR
      )
      setShowErrors(true)
      setTimeout(() => setShowErrors(false), 250)
      return
    }
    setSubmitEnabled(false)
    props.onQuestionnaireCompletion(values, () => setSubmitEnabled(true))
  }

  const submitButton = () => {
    return (
      <div className='row section'>
        <div className='col s12'>
          <button
            onClick={() => checkQuestionnaireCompletion()}
            className='btn waves-effect waves-light submit-button'
            disabled={!submitEnabled}
          >
            {I18n.t('teampro.questionnaire.save')}
          </button>
        </div>
      </div>
    )
  }

  const renderQuestionnaire = () => {
    let hasSeenErroneousQuestion = false
    return (
      <div className='questionnaire'>
        {questionnaire.filter(question => isVisible(question)).map(question => {
          if (!question.id && question.type !== 'raw') {
            console.error('Warning, the following question does not have an id (skipping):')
            console.error(question)
            return <></>
          }
          if (!question.type) {
            console.error('Warning, the following question does not have a type (skipping):')
            console.error(question)
            return <></>
          }
          const { id, ...theRest } = question
          if (question.type !== 'raw') {
            const moreProps = {}
            if (!hasSeenErroneousQuestion && showErrors && question.required && values[question.id] === undefined) {
              hasSeenErroneousQuestion = true
              moreProps.scrollTo = true
            }
            return <Question key={id} {...sharedPropsFor(id)} {...theRest} {...moreProps} />
          }
          if (!question.html || question.html.length === 0) {
            console.error('ERROR: Question with no ID found:')
            console.error(question)
            return <></>
          }
          return <Raw key={JSON.stringify(question.html)} {...theRest} />
        })}
        {submitButton()}
      </div>
    )
  }

  return (
    <>
      {renderQuestionnaire()}
    </>
  )
}

export default WithFlashMessages(Questionnaire)
