import React from 'react'
import RisRouter from './Router'
import './IconLibrary'
import history from './common/History'
import Modal from 'react-modal'
import { I18nLoader } from './I18nLoader.jsx'
import { QueryClient, QueryClientProvider } from 'react-query'

export default class App extends React.Component {
  constructor (props) {
    super(props)
    I18nLoader.load()
    this.queryClient = new QueryClient()
    this.RegisterServiceWorker()
  }

  componentDidMount () {
    Modal.setAppElement('#app')
  }

  RegisterServiceWorker () {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
      })
    }
  }

  render () {
    return (
      <QueryClientProvider client={this.queryClient}>
        <div className='app' id='app'>
          <RisRouter history={history} />
        </div>
      </QueryClientProvider>
    )
  }
}
