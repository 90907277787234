import React from 'react'
import classNames from 'classnames'
import { optionalTranslate } from '../Utils'

const Textfield = (props) => {
  const translatedLabel = optionalTranslate(props.label)

  const setValue = (value) => {
    if (value === undefined || value.trim().length === 0) {
      props.setValue(undefined)
    } else {
      props.setValue(value)
    }
  }

  return (
    <div className={classNames('section', translatedLabel.length === 0 && 'no-title')} ref={props.myRef}>
      <div className={classNames('row', props.required && props.value === undefined && props.showErrors && 'show-errors')} onClick={() => { if (props.showErrors) { props.setShowErrors(false) } }}>
        <div className='col s12 l5'>
          <div className='question-label'>{translatedLabel}</div>
        </div>
        <div className='col s12 l7 input-field'>
          <input id={props.id} type='text' value={props.value ?? ''} onChange={e => setValue(e.target.value)} />
          {props.placeholder !== undefined && <label htmlFor={props.id}>{optionalTranslate(props.placeholder)}</label>}
        </div>
      </div>
    </div>
  )
}

export default Textfield
