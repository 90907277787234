import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'
import classNames from 'classnames'

const MyModal = props => {
  // Make sure that the dialog doesn't change the vertical scroll position,
  // and that the background of the modal dialog is not scrollable.
  useEffect(() => {
    if (props.isOpen) {
      const scrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.width = '100vw'
      document.body.style.top = `-${scrollY}px`
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.width = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [props.isOpen])

  function handleClick () {
    if (props.children && props.children.props && props.children.props.handleClose) {
      props.children.props.handleClose()
    }
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <Modal
      id='my-modal'
      className={classNames('custom-modal container', props.modalClassName)}
      overlayClassName={classNames('custom-modal-wrapper', props.overlayClassName)}
      shouldCloseOnOverlayClick
      onRequestClose={handleClick}
      style={{}} {...props}
    >
      <div className='row'>
        <div className='col s12'>
          <div className='modal-header'>
            <div className='close-button-wrapper'>
              <div
                onClick={handleClick}
                className='close-button-container center pointer-grow text-primary-color'
              >
                <FontAwesomeIcon icon='times' size='2x' />
              </div>
            </div>
          </div>
          <div className='modal-content'>
            {props.children}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MyModal
