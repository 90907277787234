import React, { useState } from 'react'
import EnterPinCode from './EnterPinCode'
import { WithSession } from '../session/SessionProvider'
import Spinner from '../common/Spinner'
import { MyAxios as axios } from '../MyAxios'
import { debounce } from 'lodash'

export const PIN_CODE = 'pinCode'
const DEBOUNCE_TIMEOUT = 200

const PinCode = (props) => {
  const [verified, setVerified] = useState(false)

  if (!props.myProfile.id) return <Spinner transparent />

  const verify = (pinCode) => {
    axios.post('/api/v1/pin_codes/check', { pin_code: pinCode }, {
      headers: { ...props.authorizationHeaders() }
    }).then(res => {
      if (res.data?.verified) {
        window.localStorage.setItem(PIN_CODE, PIN_CODE)
        setVerified(true)
        if (props.afterVerifyCallback) props.afterVerifyCallback()
      }
    })
  }

  const askForPinCode = !window.localStorage.getItem(PIN_CODE)
  if (!verified && askForPinCode && props.myProfile.use_pin_code) {
    return <EnterPinCode verify={debounce(verify, DEBOUNCE_TIMEOUT)} goBack={props.goBack} />
  }

  return props.children
}

export default WithSession(PinCode)
