import React from 'react'
import { Router, Route } from 'react-router-dom'

import { SessionProvider } from './session/SessionProvider'
import { CookiesProvider } from 'react-cookie'
import { FlashMessageProvider } from './flashmessages/FlashMessageProvider'
import { ModalProvider } from './modal/ModalProvider'
import Session from './session/Session'
import { BackendProvider } from './backend/BackendProvider'

class RisRouter extends Router {
  componentDidMount () {
    window.M.Tabs.init(document.getElementById('tabs'), {})
  }

  render () {
    return (
      <Router history={this.props.history}>
        <CookiesProvider>
          <FlashMessageProvider>
            <SessionProvider>
              <BackendProvider>
                <ModalProvider>
                  <Route path='/' render={(props) => <Session {...props} />} />
                </ModalProvider>
              </BackendProvider>
            </SessionProvider>
          </FlashMessageProvider>
        </CookiesProvider>
      </Router>
    )
  }
}

export default RisRouter
