import React, { useEffect, useState } from 'react'
import { INDEPENDENT_OF_A_TRAINING } from '../teampro/Constants'
import Questionnaire from '../teampro/questionnaires/Questionnaire'
import I18n from 'i18n'
import Header from '../teampro/layout/Header'

const FillOutSession = props => {
  const { match: { params: { invitationTokenId } } } = props

  const [trainingType, setTrainingType] = useState(undefined)

  useEffect(() => {
    props.fetchSession(invitationTokenId)
  }, [])

  useEffect(() => {
    if (props.sessionToken) {
      props.fetchTeams()
    }
  }, [props.sessionToken])

  useEffect(() => {
    if (props.trainingType) {
      setTrainingType(props.trainingType)
    }
  }, [props.trainingType])

  if (props.questionnaireCompleted) {
    return (
      <div className='team-pro'>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('fill_out_session.thanks_for_filling_out')}</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <p>{I18n.t('fill_out_session.you_may_now_close_this_page')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!props.teams || props.teams.length === 0) return <></>

  const renderQuestionnaire = () => {
    return (
      <Questionnaire
        selectedPlayers={[]} // only needed for coach questionnaire
        disabledQuestionnaireKeys={props.disabledQuestionnaireKeys}
        initialValues={{
          playerId: props.player.id,
          jerseyNumber: props.player.jersey_number,
          teamId: props.player.team_id,
          name: props.player.name,
          role: props.player.role,
          extraQuestions: props.trainingMoment !== INDEPENDENT_OF_A_TRAINING && props.addExtraQuestions,
          trainingType: props.trainingType,
          weekday: props.weekday,
          trainingMoment: props.trainingMoment,
          openedAt: new Date().toISOString()
        }}
        onQuestionnaireCompletion={(values, cb) => {
          props.onQuestionnaireCompletion(props.player, { ...values, completedAt: new Date().toISOString(), trainingType }, cb)
        }}
      />
    )
  }

  return (
    <div className='team-pro fill-out-session'>
      <Header
        trainingType={trainingType}
        setTrainingType={setTrainingType}
        weekday={props.weekday}
        trainingMoment={props.trainingMoment}
        player={props.player}
        setPlayer={null}
        onManagerInterface={false}
        onGoToManagerInterface={null}
        getPlayerTrainingType={() => trainingType}
        getPlayerTrainingMoment={() => props.trainingMoment}
        teams={props.teams}
        extraQuestions={props.addExtraQuestions}
        setLocale={props.setLocale}
      />
      <div className='container'>
        <div className='row no-margin'>
          <div className='col s12'>
            <div className='divider' />
          </div>
        </div>
        <div className='row selectors'>
          <div className='col s12'>
            {renderQuestionnaire()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FillOutSession
