import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faVolleyballBall,
  faExclamationTriangle,
  // faFutbol,
  faPlay,
  faPause,
  faCalendarWeek,
  faRetweet,
  faRunning,
  faSkating,
  faQuestion,
  faEdit,
  faEye,
  faDownload,
  faHeartbeat,
  faTrash,
  faTimes,
  faCheck,
  faPlus,
  faBell,
  faUser,
  faPen,
  faSignOutAlt,
  faInfoCircle,
  faChartLine,
  faChartBar,
  faCopy,
  faSyncAlt,
  faPoll,
  faUserCog,
  faBiking,
  faWalking,
  faHiking,
  faSwimmer,
  faDumbbell,
  faSlidersH,
  faUserPlus,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faFile,
  faShare,
  faSortDown,
  faSortUp,
  faSort,
  faExternalLinkAlt,
  faExpand,
  faCompress,
  faSync,
  faHashtag,
  faArrowCircleRight,
  faEnvelopeOpenText,
  faUsers,
  faFileCode,
  faFileCsv,
  faFileArchive
} from '@fortawesome/free-solid-svg-icons'

import {
  faFutbol
} from '@fortawesome/free-regular-svg-icons'
import {
  faStrava,
  faGoogle,
  faPython
} from '@fortawesome/free-brands-svg-icons'
// Initialize the icons
library.add(
  faExclamationTriangle,
  faRetweet,
  faCalendarWeek,
  faVolleyballBall,
  faFutbol,
  faRunning,
  faSkating,
  faPlay,
  faPause,
  faQuestion,
  faEdit,
  faEye,
  faDownload,
  faStrava,
  faHeartbeat,
  faGoogle,
  faTrash,
  faTimes,
  faCheck,
  faPlus,
  faBell,
  faUser,
  faPen,
  faSignOutAlt,
  faInfoCircle,
  faChartLine,
  faChartBar,
  faCopy,
  faSyncAlt,
  faPoll,
  faUserCog,
  faBiking,
  faWalking,
  faHiking,
  faSwimmer,
  faDumbbell,
  faPython,
  faSlidersH,
  faUserPlus,
  faShare,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faFile,
  faSortDown,
  faSortUp,
  faSort,
  faExternalLinkAlt,
  faExpand,
  faCompress,
  faSync,
  faHashtag,
  faArrowCircleRight,
  faEnvelopeOpenText,
  faUsers,
  faFileCode,
  faFileCsv,
  faFileArchive
)
