import React from 'react'
import I18n from 'i18n'

const POLAR_PLAYERS_EXPORT_URL = '/api/v1/polar_players/export.csv'
const POLAR_TRAINING_SESSIONS_EXPORT_URL = '/api/v1/polar_training_sessions/export.csv'

const ExportPolarData = _props => {
  return (
    <>
      <a className='waves-effect waves-light btn download-button' href={POLAR_PLAYERS_EXPORT_URL}>
        <i className='material-icons left'>file_download</i>
        {I18n.t('exports.polar_players')}
      </a>
      <a className='waves-effect waves-light btn download-button' href={POLAR_TRAINING_SESSIONS_EXPORT_URL}>
        <i className='material-icons left'>file_download</i>
        {I18n.t('exports.polar_training_sessions')}
      </a>
    </>
  )
}

export default ExportPolarData
