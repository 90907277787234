import React from 'react'
import { Parser } from 'json2csv'
import { CSVLink } from 'react-csv'

const DownloadCSV = (props) => {
  if (!props.data) {
    return <></>
  }

  const opts = { delimiter: ';' }
  const parser = new Parser(opts)
  const csvData = parser.parse(props.data).split(/\r?\n/)
  const headers = csvData[0].split(';')
  const data = csvData.slice(1).join('\n')
  return (
    <CSVLink
      data={data}
      headers={headers}
      filename={`${props.filename}.csv`}
      className='waves-effect waves-light btn download-button'
      target='_blank'
      enclosingCharacter=''
      separator=';'
    >
      <i className='material-icons left'>file_download</i>
      {props.name}
    </CSVLink>
  )
}

export default DownloadCSV
