import React from 'react'
import classNames from 'classnames'
import I18n from 'i18n'

const Locale = (props) => {
  const title = () => {
    if (I18n.locale === 'nl') {
      return 'Switch to English'
    }
    return 'Verander naar Nederlands'
  }

  return (
    <div
      className={classNames('flag', I18n.locale)}
      onClick={() => props.setLocale(I18n.locale === 'nl' ? 'en' : 'nl')}
      title={title()}
    >
      &nbsp;
    </div>
  )
}

export default Locale
