import React from 'react'
import classNames from 'classnames'
import { optionalTranslate } from '../Utils'
import battery1 from './images/battery_scale_1.png'
import battery2 from './images/battery_scale_2.png'
import battery3 from './images/battery_scale_3.png'
import battery4 from './images/battery_scale_4.png'
import battery5 from './images/battery_scale_5.png'
import battery6 from './images/battery_scale_6.png'
import battery7 from './images/battery_scale_7.png'

interface Props {
  id: string
  label: string
  options?: Array<string | { value: string, label: string }>
  value: string | undefined
  setValue: (value: string) => void
  required: boolean
  showErrors: boolean
  setShowErrors: (value: boolean) => void
  myRef: React.RefObject<HTMLDivElement>
}

const BATTERIES = [battery1, battery2, battery3, battery4, battery5, battery6, battery7]

const Battery: React.FunctionComponent<Props> = (props: Props) => {
  const renderBattery = (): React.ReactElement => {
    return (
      <>
        {[...Array(7).keys()].map((option: number) => {
          const checked = props.value !== undefined && props.value === `${option + 1}`
          return (
            <p key={option}>
              <label className={classNames({ checked })} onClick={() => props.setValue(`${option + 1}`)}>
                <img className='battery-icon' src={BATTERIES[option]} alt={`${option + 1}`} />
                <span>{option + 1}</span>
              </label>
            </p>
          )
        })}
      </>
    )
  }

  return (
    <div className='section' ref={props.myRef}>
      <div
        className={classNames('row', props.required && props.value === undefined && props.showErrors && 'show-errors')}
        onClick={() => { if (props.showErrors) { props.setShowErrors(false) } }}
      >
        <div className='col s12 l5'>
          <div className='question-label'>{optionalTranslate(props.label)}</div>
        </div>
        <div className='col s12 l7 battery-container'>
          {renderBattery()}
        </div>
      </div>
    </div>
  )
}

export default Battery
