import React from 'react'
import classNames from 'classnames'

const TabHeader = ({ tabs, onShow }) => (
  <>
    {
      React.Children.toArray(tabs).map(tab => {
        const { id, title, disabled } = tab.props
        return (
          <li key={`${id}-header`} onClick={() => onShow(id)} className={classNames('tab col s3', { disabled })}><a href={`#${id}`}>{title}</a></li>
        )
      })
    }
  </>
)

export default TabHeader
