import React, { useMemo } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { highchartsThemeElementary, QUESTION_MAPPING } from '../../common/Constants'
import I18n from 'i18n'
import { uniq, compact } from 'lodash'
import ResponsesIndexDTO from '../../common/types/ResponsesPlayerResponsesDTO'
import Teams from '../../common/types/Teams'
import Param from '../../common/types/Param'
import { Options } from 'highcharts'
import { findPlayer, getPlayerData, GRAPHS, GraphType, Period, PlotBand, Point } from './Utils'
import { ZScore } from '../../common/types/Player'

/* eslint-disable @typescript-eslint/explicit-function-return-type, @typescript-eslint/strict-boolean-expressions, react/jsx-boolean-value */

interface Props {
  graph: GraphType
  data: ResponsesIndexDTO
  showZScores: boolean
  teams: Teams
  zScorePeriod: Period
  dynamicZScore: boolean
  yMax?: undefined | number
  yMin?: undefined | number
}

const SmallGraph: React.FunctionComponent<Props> = (props: Props) => {
  Highcharts.theme = highchartsThemeElementary as Options
  Highcharts.setOptions(Highcharts.theme)

  const params: Param[] = useMemo(() => {
    return GRAPHS[props.graph]
  }, [props.graph])

  const series = useMemo(() => {
    const playerIds = uniq(props.data.map(response => response.contents.playerId))
    return compact(playerIds.map(playerId => {
      const player = findPlayer(playerId, props.teams)
      if (!player) {
        return null
      }
      let data: Point[]
      if (props.dynamicZScore) {
        const { data: newData } = getPlayerData(playerId, props.data, params, props.showZScores)
        data = newData
      } else {
        const zScoreInfo: ZScore = player.order_scores[props.zScorePeriod]?.[props.graph] ?? { mean: 0, std: 1 }
        const { data: newData } = getPlayerData(playerId, props.data, params, props.showZScores, zScoreInfo.mean, zScoreInfo.std)
        data = newData
      }
      return {
        type: 'line',
        data: data,
        marker: { enabled: true, radius: 3 },
        id: `${playerId}`,
        name: player.name
      }
    }))
  }, [props.data, params, props.showZScores, props.teams, props.graph, props.zScorePeriod, props.dynamicZScore])

  let yPlotBands: PlotBand[] | null = []

  if (props.showZScores) {
    yPlotBands.push({
      color: 'rgba(40, 167, 69, 0.1)', // bootstrap success 10%
      from: -1,
      to: 1
    })
    yPlotBands.push({
      color: 'rgba(220, 53, 69, 0.1)',
      from: -3,
      to: -2
    })
    yPlotBands.push({
      color: 'rgba(220, 53, 69, 0.1)',
      from: 2,
      to: 3
    })
    yPlotBands.push({
      color: 'rgba(255, 193, 7, 0.1)',
      from: -2,
      to: -1
    })
    yPlotBands.push({
      color: 'rgba(255, 193, 7, 0.1)',
      from: 1,
      to: 2
    })
  }

  if (yPlotBands.length === 0) yPlotBands = null

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        chart: {
          type: 'line',
          zoomType: 'xy',
          height: 250
        },
        legend: {
          enabled: true
        },
        tooltip: {
          useHTML: true,
          headerFormat: '',
          pointFormat: '<span style="font-size: 0.75rem">{point.formatted_date}</span><br /><span style="color: {series.color}">⬤</span> {series.name}: <b>{point.y:.2f}</b>'
        },
        xAxis: {
          title: {
            text: ''
          },
          type: 'datetime',
          /*
          min: !startDate ? null : startDate.getTime(),
          max: !endDate ? null : endDate.getTime(),
          */
          dateTimeLabelFormats: {
            day: '%d %b'
          }
        },
        yAxis: {
          title: {
            text: I18n.t(`visualizations.question_mapping.${QUESTION_MAPPING[params[0]]}`)
          },
          min: props.showZScores ? -3 : (props.yMin ?? 0),
          max: props.showZScores ? 3 : (props.yMax ?? 100),
          startOnTick: !props.showZScores,
          endOnTick: !props.showZScores,
          plotBands: yPlotBands
        },
        series: series
      }}
    />
  )
}

export default SmallGraph
