import React, { useState } from 'react'
import { MyAxios as axios } from '../MyAxios'
import ExportsRoutes from './ExportsRoutes'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'
import I18n from 'i18n'

const ExportsController = props => {
  const [teams, setTeams] = useState([])

  const fetchTeams = () => {
    const result = getTeams()
    result
      .then(res => { setTeams(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const getTeams = () => {
    return axios.get('/api/v1/teams', { headers: { ...props.authorizationHeaders() } })
  }

  const postQuestionnaires = (params) => {
    return axios.post('/api/v1/responses/export', params, { headers: { ...props.authorizationHeaders() } })
  }

  const exportQuestionnaires = (params) => {
    const result = postQuestionnaires(params)
    handleResult(result)
  }

  const postRestructured = (params) => {
    return axios.post('/api/v1/responses/export_restructured', params, { headers: { ...props.authorizationHeaders() } })
  }

  const exportRestructured = (params) => {
    const result = postRestructured(params)
    handleResult(result)
  }

  const postCombined = (params) => {
    return axios.post('/api/v1/responses/export_combined', params, { headers: { ...props.authorizationHeaders() } })
  }

  const exportCombined = (params) => {
    const result = postCombined(params)
    handleResult(result)
  }

  const handleResult = (result) => {
    result
      .then(_res => {
        props.flashMessages.push(
          I18n.t('exports.flash_messages.export_started'),
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.INFO)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  return (
    <ExportsRoutes
      exportDataProps={{
        teams,
        fetchTeams,
        exportQuestionnaires,
        exportRestructured,
        exportCombined
      }}
    />
  )
}

export default WithFlashMessages(WithSession(ExportsController))
