import {
  AFTER_MATCH,
  AFTER_THE_FIRST_TRAINING, INDEPENDENT_OF_A_TRAINING,
  ATTACKER, ATTACKER_ICON, BEFORE_MATCH,
  DEFENDER, DEFENDER_ICON, FRIDAY, AFTER_THE_LAST_TRAINING, BEFORE_THE_FIRST_TRAINING,
  KEEPER, KEEPER_ICON, MATCHDAY,
  MIDFIELDER, MIDFIELDER_ICON, MONDAY, THURSDAY, TUESDAY,
  UNKNOWN_ICON, WEDNESDAY, SATURDAY, SUNDAY, TRAINING_TYPES, TEAM_TRAINING_TYPES, questionnaires
} from './Constants'

import I18n from 'i18n'
import { smarterTranslate } from '../common/Utils'

export const iconForRole = (role) => {
  switch (role) {
    case KEEPER:
      return KEEPER_ICON
    case DEFENDER:
      return DEFENDER_ICON
    case MIDFIELDER:
      return MIDFIELDER_ICON
    case ATTACKER:
      return ATTACKER_ICON
    default:
      return UNKNOWN_ICON
  }
}

export const trainingMoments = weekday => {
  if (!weekday || !weekday.length) return []
  switch (weekday) {
    case MONDAY:
    case TUESDAY:
    case WEDNESDAY:
    case THURSDAY:
    case FRIDAY:
    case SATURDAY:
    case SUNDAY:
      if (process.env.MULTIPLE_TRAININGS === 'true') {
        return [BEFORE_THE_FIRST_TRAINING, AFTER_THE_FIRST_TRAINING, AFTER_THE_LAST_TRAINING, INDEPENDENT_OF_A_TRAINING]
      }
      return [BEFORE_THE_FIRST_TRAINING, AFTER_THE_LAST_TRAINING, INDEPENDENT_OF_A_TRAINING]
    case MATCHDAY:
      return [BEFORE_MATCH, AFTER_MATCH]
    default:
      return []
  }
}

export const trainingMomentHint = trainingMoment => {
  let message = I18n.t(`teampro.training_moment_hints.${trainingMoment}`)
  switch (trainingMoment) {
    case AFTER_THE_FIRST_TRAINING:
    case AFTER_MATCH:
      if (process.env.TRAINING_DURATION_QUESTIONS === 'true' || process.env.TDT_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.training_duration')}`
      }
      if (process.env.BATTERY_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.battery')}`
      }
      return message
    case AFTER_THE_LAST_TRAINING:
      if (process.env.HYDRATION_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.hydration')}`
      }
      if (process.env.TRAINING_DURATION_QUESTIONS === 'true' || process.env.TDT_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.training_duration')}`
      }
      if (process.env.BATTERY_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.battery')}`
      }
      return message
    case BEFORE_THE_FIRST_TRAINING:
      if (process.env.MUSCLE_SORENESS_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.muscle_soreness')}`
      }
      if (process.env.SLEEP_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.sleep')}`
      }
      if (process.env.HYDRATION_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.hydration')}`
      }
      if (process.env.BATTERY_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.battery')}`
      }
      return message
    case BEFORE_MATCH:
      if (process.env.BATTERY_QUESTIONS === 'true') {
        message += `, ${I18n.t('teampro.extra_questions.battery')}`
      }
      return message
    default:
      return message
  }
}

export const allTrainingMoments = () => {
  if (process.env.MULTIPLE_TRAININGS === 'true') {
    return [
      BEFORE_THE_FIRST_TRAINING,
      AFTER_THE_FIRST_TRAINING,
      AFTER_THE_LAST_TRAINING,
      INDEPENDENT_OF_A_TRAINING,
      BEFORE_MATCH,
      AFTER_MATCH
    ]
  }
  return [
    BEFORE_THE_FIRST_TRAINING,
    AFTER_THE_LAST_TRAINING,
    INDEPENDENT_OF_A_TRAINING,
    BEFORE_MATCH,
    AFTER_MATCH
  ]
}

export const changeProperty = (eid, cb) => {
  const elem = document.getElementById(eid)
  if (elem.value) {
    cb(elem.value)
  }
}

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 120)

export const optionalTranslate = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    return obj[I18n.locale]
  }
  return obj
}

export const trainingTypes = teams => {
  const result = []
  for (const cTeamTrainingType of TEAM_TRAINING_TYPES) {
    const translationKey = `teampro.training_types.${cTeamTrainingType}`
    for (const team of teams) {
      result.push([`${cTeamTrainingType}_${team.id}`, `${I18n.t(translationKey)}: ${team.name}`])
    }
  }
  for (const cTrainingType of TRAINING_TYPES) {
    result.push([cTrainingType, I18n.t(`teampro.training_types.${cTrainingType}`)])
  }
  return result
}

export const translateTrainingType = (trainingType, teams) => {
  for (const cTeamTrainingType of TEAM_TRAINING_TYPES) {
    if (trainingType.substring(0, cTeamTrainingType.length + 1) === `${cTeamTrainingType}_`) {
      const teamId = parseInt(trainingType.substring(cTeamTrainingType.length + 1))
      const cTeams = teams.filter(team => team.id === teamId)
      const translationKey = `teampro.training_types.${cTeamTrainingType}`
      if (cTeams.length === 0) {
        return smarterTranslate(translationKey, trainingType)
      }
      return `${smarterTranslate(translationKey, cTeamTrainingType)}: ${cTeams[0].name}`
    }
  }
  return smarterTranslate(`teampro.training_types.${trainingType}`, trainingType)
}

export const translateTrainingMoment = trainingMoment => {
  return smarterTranslate(`teampro.training_moments.${trainingMoment}`, trainingMoment)
}

export const translateWeekday = weekday => {
  return smarterTranslate(`teampro.weekdays.${weekday}`, weekday)
}

export const questionTitle = qId => {
  for (const questionnaire of Object.values(questionnaires)) {
    const question = questionnaire.filter(question => question.id === qId)[0]
    if (!question) continue

    return question.label[I18n.locale]
  }
  return ''
}
