import React from 'react'

export const FlashMessageContext = React.createContext()

export class FlashMessageProvider extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      messages: []
    }

    this.SHORT = 5000
    this.LONG = 10000

    this.INFO = 1
    this.WARNING = 2
    this.ERROR = 3
  }

  push (message, timeout, type) {
    const hasMessage = this.state.messages.filter((msg) => msg.message === message).length > 0

    if (hasMessage) {
      return
    }

    // Can we do an UNSAFE_componentWillUnmount that stops this timer? (no, because there are multiple)
    setTimeout(() => {
      const newState = { ...this.state }
      newState.messages = this.state.messages.map((msg) => { return msg.message === message && msg.type === type ? { ...msg, state: 'hide' } : msg })
      this.setState(newState)
    }, timeout)
    const newState = { ...this.state }
    newState.messages.push({ message, type, state: 'show' })
    this.setState(newState)
  }

  removeMessage (message, type) {
    const newState = { ...this.state }
    newState.messages = this.state.messages.filter((msg) => msg.type !== type && msg.message !== message)
    this.setState(newState)
  }

  render () {
    return (
      <FlashMessageContext.Provider value={{ flashMessages: { push: this.push.bind(this), duration: { SHORT: this.SHORT, LONG: this.LONG }, levels: { INFO: this.INFO, WARNING: this.WARNING, ERROR: this.ERROR }, messages: this.state.messages, removeMessage: this.removeMessage.bind(this) } }}>
        {this.props.children}
      </FlashMessageContext.Provider>
    )
  }
}

export const WithFlashMessages = Component => props => {
  return (
    <FlashMessageContext.Consumer>
      {(context) => (<Component {...context} {...props} />)}
    </FlashMessageContext.Consumer>
  )
}
