import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyReactTooltip from '../MyReactTooltip'

interface Props {
  text: string
  reuseTooltip?: boolean
  tooltipId: string
  inline?: boolean
}

// The two required properties are `text` and `tooltipId`.
// `tooltipId` should be unique within the page.
const Info = (props: Props): JSX.Element => {
  const { inline = false, tooltipId, text, reuseTooltip = false, ...theRest } = props
  if (inline) {
    return (
      <>
        <i className='material-icons information-icon' data-tip={text} data-for={tooltipId}>info</i>
        {!reuseTooltip && <MyReactTooltip id={tooltipId} effect='solid' {...theRest} />}
      </>
    )
  }

  return (
    <>
      <FontAwesomeIcon icon='info-circle' data-html='true' data-tip={text} data-for={tooltipId} className='information-icon' />
      {!reuseTooltip && <MyReactTooltip id={tooltipId} effect='solid' {...theRest} />}
    </>
  )
}

export default Info
