import React, { useEffect, useState } from 'react'
import {
  INDEPENDENT_OF_A_TRAINING,
  MATCHDAY,
  TEAM_ICON,
  TEAM_ICON_EMPTY,
  WEEKDAYS
} from '../Constants'
import ReactDatePicker from 'react-datepicker'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import PlayerName from './PlayerName'
import { changeProperty, trainingMomentHint, trainingTypes, translateTrainingMoment, translateWeekday } from '../Utils'
import classNames from 'classnames'
import { nl, enUS } from 'date-fns/locale'
import I18n from 'i18n'
import moment from 'moment'
import { WithModal } from '../../modal/ModalProvider'
import { WithSession } from 'components/session/SessionProvider'
import { dateAndTimePickerDefaultOptions } from '../../common/Constants'

const NOW = 'now'
const SCHEDULED = 'scheduled'

const ManagerInterface = (props) => {
  const [sendSmsWhen, setSendSmsWhen] = useState(NOW)
  const [scheduledDates, setScheduledDates] = useState([moment().set({ minute: 0, second: 0, millisecond: 0, hour: moment().hour() + 1 }).toDate()])

  useEffect(() => {
    const elems = document.querySelectorAll('.collapsible')
    window.M.Collapsible.init(elems, {})
  }, [props.teams])

  useEffect(() => {
    window.M.FormSelect.init(document.querySelectorAll('select'))
  }, [props.weekday])

  useEffect(() => {
    window.M.updateTextFields()
  }, [])

  useEffect(() => {
    if (props.myProfile !== {} && props.myProfile.polar_token_invalid) {
      props.flashMessages.push(
        I18n.t('flashmessages.polar_token.invalidated'),
        props.flashMessages.duration.LONG,
        props.flashMessages.levels.ERROR)
    }
  }, [props.myProfile])

  const renderPlayer = (player) => {
    return (
      <div key={player.id} className='with-checkbox'>
        <label>
          <input
            type='checkbox'
            checked={props.selectedPlayers.includes(player)}
            onChange={_e => props.onTogglePlayerSelection(player)}
          />
          <span>
            <PlayerName player={player} />
          </span>
        </label>
      </div>
    )
  }

  const renderAllNone = (team) => {
    const allSelected = team.players.every(teamPlayer => props.selectedPlayers.includes(teamPlayer))
    const noneSelected = !team.players.some(teamPlayer => props.selectedPlayers.includes(teamPlayer))
    return (
      <>
        <div className='row'>
          <div className='col s12 m6'>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={allSelected}
                  onChange={_e => props.selectPlayers(team.players)}
                />
                <span>{I18n.t('teampro.manager.whole_team')}</span>
              </label>
            </p>
          </div>
          <div className='col s12 m6'>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={noneSelected}
                  onChange={_e => props.deSelectPlayers(team.players)}
                />
                <span>{I18n.t('teampro.manager.no_one')}</span>
              </label>
            </p>
          </div>
        </div>
        <div className='divider' />
      </>
    )
  }

  const selectedPlayerCount = (team) => {
    return team.players.filter(teamPlayer => props.selectedPlayers.includes(teamPlayer)).length
  }

  const renderTeamName = (team) => {
    const selectedCount = selectedPlayerCount(team)
    if (selectedCount > 0) {
      return (
        <>
          <i className='material-icons'>{TEAM_ICON}</i>{team.name} ({selectedCount})
        </>
      )
    }
    return (
      <>
        <i className='material-icons'>{TEAM_ICON_EMPTY}</i>{team.name}
      </>
    )
  }

  const renderTeam = (team) => {
    return (
      <li key={team.id}>
        <div className='collapsible-header'>{renderTeamName(team)}</div>
        <div className='collapsible-body'>
          {team.players.length > 0 && renderAllNone(team)}
          {!team.players.length && <p><em>{I18n.t('teampro.manager.no_players_in_team')}</em></p>}
          {team.players.map(player => renderPlayer(player))}
        </div>
      </li>
    )
  }

  const checkIfPresent = (value, description) => {
    if (!value || !value.length) {
      props.flashMessages.push(
        `${I18n.t('teampro.manager.please_select')} ${description}`,
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.ERROR
      )
      window.scrollTo(0, 0)
      return false
    }
    return true
  }

  const requiredChecks = () => {
    return checkIfPresent(props.weekday, I18n.t('teampro.manager.validations.day_of_the_week')) &&
      checkIfPresent(props.trainingMoment, I18n.t('teampro.manager.validations.training_moment')) &&
      checkIfPresent(props.trainingType, I18n.t('teampro.manager.validations.training_type')) &&
      checkIfPresent(props.selectedPlayers, I18n.t('teampro.manager.validations.selected_players'))
  }

  const checkAndSubmit = () => {
    if (requiredChecks()) {
      props.flashMessages.push(
        I18n.t('teampro.manager.players_app_started'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO
      )
      window.scrollTo(0, 0)
      props.onDone()
    }
  }

  const checkAndSendInvites = () => {
    if (requiredChecks()) {
      showSendInvitesDialog()
    }
  }

  const showSendInvitesDialog = () => {
    props.setConfirmationDialog({
      onConfirmation: () => props.onSendInvites(sendSmsWhen === NOW ? [] : scheduledDates),
      target: I18n.t('teampro.manager.send_invites_confirmation', { count: props.selectedPlayers.length }),
      action: 'custom',
      noQuotes: true
    })
  }

  const checkAndCoach = () => {
    if (
      checkIfPresent(props.weekday, I18n.t('teampro.manager.validations.day_of_the_week')) &&
      checkIfPresent(props.trainingMoment, I18n.t('teampro.manager.validations.training_moment')) &&
      checkIfPresent(props.selectedPlayers, I18n.t('teampro.manager.validations.selected_players'))
    ) {
      props.flashMessages.push(
        I18n.t('teampro.manager.coach_questionnaire_loaded'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO
      )
      props.loadCoachQuestionnaire()
    }
  }

  const setWeekday = (weekday) => {
    if (weekday === MATCHDAY) {
      props.setTrainingType(undefined)
    }
    props.setWeekday(weekday)
  }

  const setScheduledDate = (idx, date) => {
    const newScheduledDates = [...scheduledDates]
    newScheduledDates[idx] = date
    setScheduledDates(newScheduledDates)
  }

  const addDate = () => {
    const newScheduledDates = [...scheduledDates, moment(scheduledDates.slice(-1)[0]).add(1, 'weeks').toDate()]
    setScheduledDates(newScheduledDates)
  }

  const removeDate = () => {
    if (scheduledDates.length === 1) return

    const newScheduledDates = [...scheduledDates]
    newScheduledDates.pop()
    setScheduledDates(newScheduledDates)
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <h5>{I18n.t('teampro.manager.questionnaire_settings')}</h5>
        </div>
      </div>
      <div className='row'>
        <div className='input-field col s12 m6'>
          <label className={classNames(props.weekday && 'active')}>{I18n.t('teampro.manager.select_day_of_the_week')}</label>
          <select
            className='browser-default'
            id='weekday-selector'
            value={props.weekday || ''}
            onChange={() => changeProperty('weekday-selector', setWeekday)}
          >
            <option value='' disabled />
            {WEEKDAYS.map(cWeekday => {
              return (
                <option key={cWeekday} value={cWeekday}>{translateWeekday(cWeekday)}</option>
              )
            })}
          </select>
        </div>
        {props.weekday && (
          <div className='input-field col s12 m6'>
            <label className={classNames(props.trainingMoment && 'active')}>{I18n.t('teampro.manager.set_training_moment')}</label>
            <select
              className='browser-default'
              id='training-moment-selector'
              value={props.trainingMoment || ''}
              onChange={() => changeProperty('training-moment-selector', props.setTrainingMoment)}
            >
              <option value='' disabled />
              {props.trainingMoments.map(cTrainingMoment => {
                return (
                  <option key={cTrainingMoment} value={cTrainingMoment}>{translateTrainingMoment(cTrainingMoment)}</option>
                )
              })}
            </select>
            <span className='helper-text'>{props.trainingMoment && trainingMomentHint(props.trainingMoment)}</span>
          </div>
        )}
      </div>
      <div className='row'>
        <div className='input-field col s12 m6'>
          <label className={classNames(props.trainingType && 'active')}>{I18n.t('teampro.manager.set_training_type')}</label>
          <select
            className='browser-default'
            id='training-type-selector'
            value={props.trainingType || ''}
            onChange={() => changeProperty('training-type-selector', props.setTrainingType)}
          >
            <option value='' disabled />
            {trainingTypes(props.teams).map(([cTrainingType, cTrainingTypeLabel]) => {
              return (
                <option key={cTrainingType} value={cTrainingType}>{cTrainingTypeLabel}</option>
              )
            })}
          </select>
        </div>
        <div className='input-field col s12 m6'>
          <label className='active'>{I18n.t('teampro.manager.options')}</label>
          <p>
            <label>
              <input
                type='checkbox'
                checked={props.extraQuestions}
                disabled={props.trainingMoment === INDEPENDENT_OF_A_TRAINING ? 'disabled' : undefined}
                onChange={e => props.setExtraQuestions(e.target.checked)}
              />
              <span>
                {I18n.t('teampro.manager.add_extra_questions')}
                <span className='helper-text'>{I18n.t(`teampro.training_moment_hints.${INDEPENDENT_OF_A_TRAINING}`)}</span>
              </span>
            </label>
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <h6>{I18n.t('teampro.manager.select_players')}</h6>
          {props.teams.length === 0 && <p><em>{I18n.t('teampro.manager.there_are_no_teams_click')}</em></p>}
          {props.teams.length > 0 && (
            <ul className='collapsible'>
              {props.teams.map(cteam => renderTeam(cteam))}
            </ul>
          )}
        </div>
      </div>
      <div className='divider' />
      <div className='row'>
        <div className='col s12'>
          <h6>{I18n.t('teampro.manager.navigation')}</h6>
        </div>
        <div className='col s12 l6'>
          <button
            className='waves-effect waves-light btn'
            onClick={e => {
              e.preventDefault()
              checkAndSubmit()
            }}
          >
            <i className='material-icons left'>done</i>
            {I18n.t('teampro.manager.to_players_app')}
          </button>
        </div>
        <div className='col s12 l6'>
          <button
            className={classNames('waves-effect waves-light btn float-right submit-button secondary-button', !props.showCoachQuestionnaireButton && 'disabled')}
            onClick={() => checkAndCoach()}
          >
            <i className='material-icons left'>tune</i>
            {I18n.t('teampro.manager.fill_out_coach_questionnaire')}
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <p>{I18n.t('teampro.manager.or')}</p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <h5>{I18n.t('teampro.manager.send_invites_per_sms')}</h5>
        </div>
        <div className='input-field col s12 l6'>
          <p>
            <label>
              <input
                name='send-sms-when'
                type='radio'
                checked={sendSmsWhen === NOW}
                onChange={() => setSendSmsWhen(NOW)}
              />
              <span>{I18n.t('teampro.manager.now')}</span>
            </label>
          </p>
          <p>
            <label>
              <input
                name='send-sms-when'
                type='radio'
                checked={sendSmsWhen === SCHEDULED}
                onChange={() => setSendSmsWhen(SCHEDULED)}
              />
              <span>{I18n.t('teampro.manager.at')}</span>
            </label>
          </p>
        </div>
      </div>
      {sendSmsWhen === SCHEDULED && (
        scheduledDates.map((scheduledDate, idx) => (
          <div className='row' key={`scheduleddate-${idx}`}>
            <div className='input-field col s12 l6'>
              <ReactDatePicker
                id='scheduled-date'
                {...dateAndTimePickerDefaultOptions}
                selected={scheduledDate}
                onChange={date => setScheduledDate(idx, date)}
                timeCaption={I18n.t('teampro.manager.time')}
                minDate={new Date()}
                locale={I18n.locale === 'en' ? enUS : nl}
                required
              />
              <label
                htmlFor='scheduled-date'
                className={classNames('input-label', { active: scheduledDate })}
              >
                {I18n.t('teampro.manager.date_and_time')}
              </label>
            </div>
            {idx + 1 === scheduledDates.length && (
              <div className='input-field col s12 l6'>
                <button className='waves-effect waves-light btn btn-small' onClick={addDate}>
                  <i className='material-icons'>add</i>
                  {I18n.t('teampro.manager.add_date')}
                </button>
                <button
                  className={classNames('waves-effect waves-light btn margin-left btn-small', { disabled: scheduledDates.length === 1 })}
                  onClick={removeDate}
                >
                  <i className='material-icons'>remove</i>
                </button>
              </div>
            )}
          </div>
        ))
      )}
      <div className='row'>
        <div className='col s12'>
          <button
            className='waves-effect waves-light btn secondary-button'
            onClick={e => {
              e.preventDefault()
              checkAndSendInvites()
            }}
          >
            <i className='material-icons left'>{sendSmsWhen === NOW ? 'send' : 'schedule'}</i>
            {I18n.t(sendSmsWhen === NOW ? 'teampro.manager.send_now' : 'teampro.manager.send_scheduled')}
          </button>
        </div>
      </div>
    </>
  )
}

export default WithSession(WithModal(WithFlashMessages(ManagerInterface)))
