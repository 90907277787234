import React from 'react'
import I18n from 'i18n'
import Info from '../../common/Info'

export default class CheckBox extends React.Component {
  render () {
    const errors = this.props.errors || []
    const { infoBox, component, fieldName, formName, currentValue, defaultValue } = this.props
    const type = this.props.type || 'checkbox'
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    delete fieldProps.infoBox

    let infoBoxText = infoBox
    if (typeof infoBoxText === 'function') {
      infoBoxText = infoBoxText()
    }

    let isChecked = !!currentValue || (defaultValue && currentValue == null) || false
    if (fieldProps.checked === false) {
      isChecked = false
    }
    delete fieldProps.checked

    return (
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            <div>
              <label>
                <input
                  type={type}
                  name={fieldName}
                  id={fieldName}
                  {...fieldProps}
                  checked={isChecked}
                  onChange={(e) => this.props.onChange({ target: { type } }, formName, fieldName)}
                />
                <span>
                  {I18n.t(`${formName}.${component}.${type}.${fieldName}`)}
                </span>
              </label>
              {errors.map(err => (
                <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
                  {this.props.translateErrors ? I18n.t(err) : err}
                </span>
              ))}
              {infoBox && <Info text={infoBoxText} tooltipId={`${formName}.${component}.${type}.${fieldName}-tooltip`} />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
