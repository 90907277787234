import React from 'react'
import { WithSession } from '../session/SessionProvider'
import { withCookies } from 'react-cookie'
import classNames from 'classnames'
import LoggedInRouter from '../router/LoggedInRouter'
import LoggedOutRouter from '../router/LoggedOutRouter'
import FlashMessages from '../flashmessages/FlashMessages'
import MyReactTooltip from '../MyReactTooltip'

class LoginWrapper extends React.Component {
  render () {
    return (
      <>
        <FlashMessages />
        <MyReactTooltip className='high-z' effect='solid' id='higher-z-tooltip' />
        <div className={classNames('wrapper', 'app-wrapper', this.props.loggedOutClass)}>
          {this.props.isLoggedIn && (
            <LoggedInRouter
              isLoggedIn={this.props.isLoggedIn}
              location={this.props.location}
              admin={this.props.myProfile.admin}
            />
          )}

          {!this.props.isLoggedIn && <LoggedOutRouter />}
        </div>
      </>
    )
  }
}

export default WithSession(withCookies(LoginWrapper))
