import React from 'react'
import PlayerName from '../../teampro/layout/PlayerName'
import I18n from 'i18n'
import { questionTitle } from '../../teampro/Utils'
import classNames from 'classnames'
import { getPlayerValue, GRAPHS } from 'components/visualizations/layout/Utils'
import { WithSession } from 'components/session/SessionProvider'
import { calculateClass } from 'components/visualizations/layout/ResilienceMonitoringDashboard'
import { OrderScore, ZScore } from '../../common/types/Player'
import TrainingSession from '../../common/types/TrainingSession'
import User from '../../common/types/User'

interface Props {
  trainingSession: TrainingSession
  smallerHeader?: boolean
  myProfile?: User // From WithSession
}

const TrainingSessionResultsTable: React.FunctionComponent<Props> = ({ trainingSession, myProfile, smallerHeader = false }: Props) => {
  if ((myProfile == null) || Object.keys(myProfile).length === 0) return <></>

  const completedFillOutSessions = trainingSession.fill_out_sessions.filter(fillOutSession => fillOutSession.completed_at.length > 0)
  const anyCompleted = completedFillOutSessions.length > 0
  const anyHasResponse = completedFillOutSessions.some(fillOutSession => fillOutSession.response !== null && fillOutSession.response !== undefined)
  const zScorePeriod = myProfile.z_score_period

  if (!anyHasResponse || !anyCompleted) return <></>

  return (
    <div className='row'>
      <div className='col s12'>
        {smallerHeader && <h6>{I18n.t('training_sessions.edit_training_session.results')}</h6>}
        {!smallerHeader && <h4>{I18n.t('training_sessions.edit_training_session.results')}</h4>}
        <table className='striped training-sessions-table'>
          <thead>
            <tr>
              <th style={{ minWidth: '200px' }}>{I18n.t('training_sessions.edit_training_session.name')}</th>
              {Object.keys(GRAPHS).map(key =>
                <th key={key}>{questionTitle(GRAPHS[key as keyof typeof GRAPHS][0])}</th>)}
            </tr>
          </thead>
          <tbody>
            {trainingSession.fill_out_sessions.map(fillOutSession => {
              return (
                <tr key={fillOutSession.id}>
                  <>
                    <td className={classNames('z-score-colored', calculateClass(fillOutSession.player.order_scores[zScorePeriod]?.order_score ?? 1))}><PlayerName player={fillOutSession.player} /></td>
                    {Object.keys(GRAPHS).map(key => {
                      const graph = GRAPHS[key as keyof typeof GRAPHS]
                      const playerValue = getPlayerValue(fillOutSession, graph)
                      const orderScore: OrderScore = fillOutSession.player.order_scores[zScorePeriod] ?? {
                        self_efficacy: { mean: 0, std: 1 },
                        motivation: { mean: 0, std: 1 },
                        mood: { mean: 0, std: 1 },
                        performance: { mean: 0, std: 1 },
                        enjoyment: { mean: 0, std: 1 },
                        recovery: { mean: 0, std: 1 },
                        intensity: { mean: 0, std: 1 },
                        order_score: 1
                      }
                      return (
                        <td
                          key={key}
                          className={classNames('z-score-colored', determineColorClass(playerValue, orderScore[key as keyof typeof GRAPHS]))}
                        >
                          {playerValue}
                        </td>
                      )
                    })}
                  </>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const determineColorClass = (score: number | null, zScore: ZScore): string => {
  if (score === null) return ''
  if (zScore.std === 0) return 'grey-ranking'

  const num = (score - zScore.mean) / zScore.std
  return calculateClass(num)
}

export default WithSession(TrainingSessionResultsTable) as typeof TrainingSessionResultsTable
