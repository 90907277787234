import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrumbs = props => {
  return (
    <nav>
      <div className='container'>
        <div className='nav-wrapper'>
          <div className='row'>
            <div className='col s12'>
              {Object.entries(props.crumbs).map(([index, val]) => {
                if (parseInt(index) + 1 === props.crumbs.length) {
                  return (
                    <a key={val.name} href='#!' className='breadcrumb' onClick={e => e.preventDefault()}>{val.name}</a>
                  )
                }
                return (
                  <Link
                    key={val.name}
                    to={val.link}
                    className='breadcrumb'
                  >{val.name}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default BreadCrumbs
