import React from 'react'
import { Route } from 'react-router'
import EditTeam from './layout/EditTeam'
import TeamsOverview from './layout/TeamsOverview'
import NewTeam from './layout/NewTeam'
import NotFoundWrapper from '../router/NotFoundWrapper'
import NewPlayer from './layout/NewPlayer'
import EditPlayer from './layout/EditPlayer'

const TeamsRoutes = props => {
  const { overviewProps, editProps, newProps, newPlayerProps, editPlayerProps } = props
  return (
    <NotFoundWrapper slug='teams'>
      <Route
        exact path='/teams'
        render={props => (<TeamsOverview {...props} {...overviewProps} />)}
      />
      <Route
        exact path='/teams/:teamId(\d+)/edit'
        render={props => (<EditTeam {...props} {...editProps} />)}
      />
      <Route
        exact path='/teams/new'
        render={props => (<NewTeam {...props} {...newProps} />)}
      />
      <Route
        exact path='/teams/:teamId(\d+)/players/new'
        render={props => (<NewPlayer {...props} {...newPlayerProps} />)}
      />
      <Route
        exact path='/players/:playerId(\d+)/edit'
        render={props => (<EditPlayer {...props} {...editPlayerProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default TeamsRoutes
