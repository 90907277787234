import React from 'react'
import classNames from 'classnames'
import { optionalTranslate } from '../Utils'

const Radio = (props) => {
  const renderRadio = () => {
    return (
      <>
        {props.options.map(option => {
          if (typeof option !== 'object') {
            option = { value: option, label: option }
          }
          return (
            <p key={option.value}>
              <label>
                <input
                  name={props.id}
                  type='radio'
                  value={option.value}
                  checked={props.value !== undefined && props.value === `${option.value}`}
                  onChange={e => props.setValue(e.target.value)}
                />
                <span>{optionalTranslate(option.label)}</span>
              </label>
            </p>
          )
        })}
      </>
    )
  }

  return (
    <div className='section' ref={props.myRef}>
      <div className={classNames('row', props.required && props.value === undefined && props.showErrors && 'show-errors')} onClick={() => { if (props.showErrors) { props.setShowErrors(false) } }}>
        <div className='col s12 l5'>
          <div className='question-label'>{optionalTranslate(props.label)}</div>
        </div>
        <div className='col s12 l7'>
          {renderRadio()}
        </div>
      </div>
    </div>
  )
}

export default Radio
