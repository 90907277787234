import React from 'react'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithSession } from '../../session/SessionProvider'
import I18n from 'i18n'

import { MyAxios as axios } from '../../MyAxios'

class Confirmation extends React.Component {
  constructor (props) {
    super(props)
    this.state = { message: I18n.t('flashmessages.confirmation.processing') }
  }

  locationParam (key) {
    let paramIdx = -1
    const params = this.props.location.search.replace('?', '').split('=')

    params.forEach((str, i) => {
      if (str === key) {
        paramIdx = i + 1
      }
    })
    return paramIdx >= 0 ? params[paramIdx] : null
  }

  componentDidMount () {
    this.props.updateSession({ sessionToken: '' })
    this.props.setHeight(100)

    axios({ url: `/api/v1/confirmation?confirmation_token=${this.locationParam('confirmation_token')}`, method: 'GET' }).then((success) => {
      this.props.flashMessages.push(
        I18n.t('flashmessages.confirmation.successful'),
        this.props.flashMessages.duration.SHORT,
        this.props.flashMessages.levels.INFO)
      this.props.history.push('/')
    }).catch((err) => {
      console.log('An error occurent when activating your account:')
      console.log(err)
      this.setState({ message: I18n.t('flashmessages.confirmation.failed') })
    })
  }

  render () {
    const { message } = this.state
    return (
      <span>
        {message}
      </span>
    )
  }
}

export default WithFlashMessages(WithSession(Confirmation))
