import React from 'react'
import { MyAxios as axios } from '../MyAxios'
import CallbackRoutes from './CallbackRoutes'
import { WithSession } from '../session/SessionProvider'
import { withRouter } from 'react-router-dom'

const CallbackController = props => {
  const createPolarToken = code => {
    return axios.post('/api/v1/polar_tokens', { code }, { headers: { ...props.authorizationHeaders() } })
  }

  return (
    <CallbackRoutes
      callbackProps={{
        createPolarToken
      }}
    />
  )
}

export default WithSession(withRouter(CallbackController))
