import React from 'react'
import classNames from 'classnames'
import { optionalTranslate } from '../Utils'

const Slider = (props) => {
  let value = props.value
  let notChanged = false
  const minLabel = props.minLabel ? props.minLabel : props.min.toString()
  const maxLabel = props.maxLabel ? props.maxLabel : props.max.toString()
  if (value === undefined) {
    notChanged = true
    // value = Math.trunc((props.min + props.max) / 2)
    if (props.vertical) {
      value = props.max
    } else {
      value = props.min
    }
  }

  const renderHorizontalSlider = () => {
    return (
      <>
        <p className={classNames('range-field', notChanged && 'not-changed')}>
          <input
            type='range' min={props.min} max={props.max} value={value} step={props.step}
            onClick={e => { if (notChanged) { props.setValue(parseInt(e.target.value)) } }}
            onTouchStart={e => { if (notChanged) { props.setValue(parseInt(e.target.value)) } }}
            onChange={e => props.setValue(parseInt(e.target.value))}
          />
        </p>
        <div className='row range-labels'>
          <div className='col s6 left-align'>
            {optionalTranslate(minLabel)}
          </div>
          <div className='col s6 right-align'>
            {optionalTranslate(maxLabel)}
          </div>
        </div>
      </>
    )
  }

  const renderVerticalSlider = () => {
    const reversedLabels = Array.from(props.tickLabels).reverse()
    return (
      <>
        <div className='row'>
          <div className='col s3' style={{ width: '55px' }}>
            <p className={classNames('range-field', notChanged && 'not-changed')}>
              <input
                className='vranger' type='range' min={props.min} max={props.max} value={value} step={props.step}
                onClick={e => { if (notChanged) { props.setValue(parseInt(e.target.value)) } }}
                onTouchStart={e => { if (notChanged) { props.setValue(parseInt(e.target.value)) } }}
                onChange={e => props.setValue(parseInt(e.target.value))}
              />
            </p>
          </div>
          <div className='col s9'>
            <div className='range-labels-vertical'>
              {reversedLabels.map((label, idx) => {
                const val = props.max - idx
                const translatedLabel = optionalTranslate(label)
                return (
                  <React.Fragment key={`${val}-${translatedLabel}`}>
                    <span
                      className={classNames(!notChanged && value === val && 'selected')}
                      onClick={() => props.setValue(val)}
                    >
                      {translatedLabel}
                    </span>
                    {val !== props.min && <br />}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderSlider = () => {
    if (props.vertical) {
      return renderVerticalSlider()
    }
    return renderHorizontalSlider()
  }

  return (
    <div className='section' ref={props.myRef}>
      <div className={classNames('row', props.required && props.value === undefined && props.showErrors && 'show-errors')} onClick={() => { if (props.showErrors) { props.setShowErrors(false) } }}>
        <div className='col s12 l5'>
          <div className='question-label'>{optionalTranslate(props.label)}</div>
        </div>
        <div className='col s12 l7'>
          {renderSlider()}
        </div>
      </div>
    </div>
  )
}

export default Slider
