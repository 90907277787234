import React from 'react'
import I18n from 'i18n'
import { Radio, Select, CheckBox, Field, SelectMultiple, SelectMultipleIcon, Chips, Toggle } from '../common/form'

/**
Form fields are generated to ensure uniformity across forms and reduce the amount of effort required to create forms =)
A field with a single value can be described as follows:

"<fieldName>": {
  "component": "string",
  "properties": {
    "type": "string",
    "name": "string"
  }
}

A field where one value can be chosen for a list can be described like this:

"<fieldName>": {
  "component": "string",
  "properties": {
    "type": "string",
    "name": "string"
    "choices": [{
      "choiceLabel": "string"
      "properties": {"name": "string", "value": "any type"}
    }]
  }
}

field descriptions:
 - fieldName: a string that is used to access the field's value when processing the form
 - fieldName.component: a string that maps to a component in /form/common/<Component>.js
 - fieldName.properties: object that is passed as props to the HTML element
 - fieldName.properties.choices: An optional List of Objects that is required for form fields that
                                 have multiple values such as Select and Radio components. each item
                                 in the list results in 1 value. The first value will always be the
                                 default value
 - fieldName.properties.choices.choiceLabel: Key used for the choice label
 - fieldName.properties.choices.properties: Html properties given to the form field

 Note: the "properties" objects are passed directly as props to the underlying html element. Any prop
       that is valid react/html can be given here, such as 'style' or 'className'. The examples above
       only list the minimal required properties to create a field

 Note 2: I18N labels are created as '<formName>.<component>.<type>.<fieldName>' for fields,
         and '<formName>.<component>.<type>.<fieldName>.<choiceName>' for field options

 Note 3: The 'defaultChecked' option can be given to a checkbox or radio choice.

 Note 4: The 'defaultValue' property can be set on the properties of the encapsulating <Select> element
         for select fields. This value must be equal to that of the 'choice.properties.value' property

Examples:

  form_fields = {
    'terms-default-checked': {
      'component': 'checkbox',
      'properties': {
        'type': 'checkbox',
        'defaultChecked': true
      }
    },
    'terms-default-unchecked': {
      'component': 'checkbox',
      'properties': {
        'type': 'checkbox'
      }
    },
    'email': {
      'component': 'field',
      'properties': {
        'type': 'text'
      }
    },
    'password': {
      'component': 'field',
      'properties': {
        'type': 'password'
      }
    },
    'password-confirm': {
      'component': 'field',
      'properties': {
        'type': 'password'
      }
    },
    'radio-example-with-default': {
      'component': 'radio',
      'properties': {
        'type': 'radio',
        'choices': [
          {"properties": {'name': 'choice1', 'value': 1, 'defaultChecked': true}},
          {"properties": {'name': 'choice2', 'value': 2}}
        ]
      }
    },
    'select-example-with-default': {
      'component': 'select',
      'properties': {
        'type': 'select',
        'defaultValue': 2,
        'choices': [
          {"properties": {'name': 'choice1', 'value': 1}},
          {"properties": {'name': 'choice2', 'value': 2, 'disabled': true}}
        ]
      }
    }
  }
**/

export default class FormFactory extends React.Component {
  constructor (props) {
    super(props)
    /* basic empty form_fields object. Override this in a subclass */
    this.form_fields = {}
  }

  /* default form submit success handler */
  handleSuccess (request) {
    console.log('handleSuccess called on FormFactory. Implement me in a subclass!')
  }

  handleError (request) {
    console.log('handleError called on FormFactory. Implement me in a subclass!')
  }

  allDataFetched () {
    // Don't do anything, should be overridden in subclass
  }

  componentDidMount () {
    this.fetchDefaults()
  }

  groupByEndpoint (fieldsToFetch, getEndpoint) {
    const endpoints = {}
    fieldsToFetch.forEach((key) => {
      if (!(getEndpoint(key) in endpoints)) {
        endpoints[getEndpoint(key)] = []
      }
      endpoints[getEndpoint(key)].push(key)
    })
    return endpoints
  }

  fetchDefaults () {
    let getEndpoint = (key) => this.form_fields[key].from_endpoint
    const fieldsToFetch = Object.keys(this.form_fields).filter((key) => this.form_fields[key].from_endpoint)
    const endpointFieldMapping = this.groupByEndpoint(fieldsToFetch, getEndpoint)

    getEndpoint = (key) => this.form_fields[key].properties.choices
    const choicesToFetch = Object.keys(this.form_fields).filter((key) => (this.form_fields[key].component === 'select_multiple' || this.form_fields[key].component === 'select_multiple_icon') && typeof this.form_fields[key].properties.choices === 'string')
    const choiceEndpointMapping = this.groupByEndpoint(choicesToFetch, getEndpoint)

    // if(this.props.populateFromUrl) {
    Object.keys(endpointFieldMapping).length > 0 && (
      this.props.fetch(this.props.formName, endpointFieldMapping, 'default', this.allDataFetched.bind(this))
    )
    Object.keys(choiceEndpointMapping).length > 0 && (
      this.props.fetch(this.props.formName, choiceEndpointMapping, 'choice')
    )
  }

  /* fetches a GET parameter from the current URL */
  locationParam (key) {
    let paramIdx = -1
    const params = this.props.location.search.replace('?', '').split(/[=&]/)

    params.forEach((str, i) => {
      if (str === key) {
        paramIdx = i + 1
      }
    })
    return paramIdx >= 0 ? params[paramIdx] : null
  }

  // groups the fields that should be rendered per stage
  getFieldsToRender () {
    return this.groupFieldsByStage('render')
  }

  // groups the fields that should be submitted per stage
  getFieldsToSubmit () {
    return this.groupFieldsByStage('submit')
  }

  // key = either 'render' or 'submit'
  groupFieldsByStage (key) {
    const stages = {}
    Object.keys(this.form_fields).map((field) => {
      return this.form_fields[field].stage ? [field, this.form_fields[field].stage[key]] : null
    }).filter((field) => !!field).forEach((field) => {
      return field[1].forEach((stage) => {
        if (!stages[stage]) {
          stages[stage] = []
        }
        stages[stage].push(field[0])
      })
    })
    return stages
  }

  componentWillUnmount () {
    this.props.clearErrors && this.props.clearErrors(this.props.formName)
  }

  /* iterates over the form fields declared in this.form_fields, maps the field name to a
   * React component and then passes props in order to render it */
  renderFields () {
    const { errors, defaults, values } = this.props
    const fieldsToRender = this.getFieldsToRender(this.props.getStage(this.props.formName))
    return Object.keys(this.form_fields).map((field) => {
      const defaultValue = field in defaults ? defaults[field] : null // default value for fields; list of options for select multiple
      const currentValue = field in values ? values[field] : null // value for field entered by user
      const onChange = (e) => this.props.handleChange(e, this.props.formName, field)

      /* wait 1 ms (until render complete), then add GET param as a hidden form field */
      if (!(Object.keys(fieldsToRender).length === 0) && (fieldsToRender && fieldsToRender[this.props.stage] && !fieldsToRender[this.props.stage].includes(field))) {
        return (<div key={field} />)
      }

      const fieldProps = {
        fieldName: field,
        component: this.form_fields[field].component,
        onChange: this.form_fields[field].onChange || onChange,
        formName: this.props.formName,
        translateErrors: this.props.translateErrors,
        errors: errors && errors[field],
        defaultValue,
        currentValue
      }
      return (
        <div className='form-field' key={field}>
          {this.form_fields[field].component === 'hidden-get' && this.props.handleChange(
            { target: { value: this.locationParam(this.form_fields[field].propKey) } }, this.props.formName, field)}

          {this.form_fields[field].component === 'hidden-props' && setTimeout(() => {
            this.props.handleChange(
              { target: { value: this.form_fields[field].properties[this.form_fields[field].propKey] } }, this.props.formName, field)
          }, 0) && errors && errors[field] && (<p>{errors[field]}</p>)}

          {this.form_fields[field].component === 'checkbox' && (
            <CheckBox {...this.form_fields[field].properties} {...fieldProps} />
          )}
          {this.form_fields[field].component === 'toggle' && (
            <Toggle {...this.form_fields[field].properties} {...fieldProps} />
          )}
          {this.form_fields[field].component === 'select' && (
            <Select {...this.form_fields[field].properties} {...fieldProps} choices={(this.props.choices && this.props.choices[field]) || []} />
          )}
          {this.form_fields[field].component === 'select_multiple' && (
            <SelectMultiple {...this.form_fields[field].properties} {...fieldProps} choices={(this.props.choices && this.props.choices[field]) || []} />
          )}
          {this.form_fields[field].component === 'radio' && (
            <Radio {...this.form_fields[field].properties} {...fieldProps} />
          )}
          {this.form_fields[field].component === 'field' && (
            <Field {...this.form_fields[field].properties} {...fieldProps} />
          )}
          {this.form_fields[field].component === 'chips' && (
            <Chips {...this.form_fields[field].properties} {...fieldProps} />
          )}
          {this.form_fields[field].component === 'select_multiple_icon' && (
            <SelectMultipleIcon {...this.form_fields[field].properties} {...fieldProps} choices={(this.props.choices && this.props.choices[field]) || []} />
          )}
        </div>
      )
    })
  }

  /* renders a default submit button */
  renderSubmit (text, props) {
    const handleClick = function (e) {
      this.props.handleSubmit(e, this.props.formName, this.props.submitMethod, this.props.endpoint, this.handleSuccess.bind(this), this.handleError.bind(this), this.getFieldsToSubmit()[this.props.stage])
    }.bind(this)
    const newProps = JSON.parse(JSON.stringify(props))
    delete newProps.submitId
    return (
      <div className='row'>
        <div className='col s6'>
          <button id={props.submitId ? props.submitId : 'submit'} onClick={handleClick} className='waves-effect waves-light text-primary-color btn' {...newProps}>
            {I18n.t(text)}
          </button>
        </div>
      </div>
    )
  }

  renderSubmitAndCancel (submitText, cancelText, props) {
    const handleClick = function (e) {
      this.props.handleSubmit(e, this.props.formName, this.props.submitMethod, this.props.endpoint, this.handleSuccess.bind(this), this.handleError.bind(this), this.getFieldsToSubmit()[this.props.stage])
    }.bind(this)
    const handleCancelClick = function (e) {
      if (props.handleCancel) {
        props.handleCancel()
      } else {
        this.props.history.goBack()
      }
    }.bind(this)
    const newProps = JSON.parse(JSON.stringify(props))
    delete newProps.handleCancel
    delete newProps.submitId
    return (
      <div className='row'>
        <div className='col s12'>
          <button id={props.submitId ? props.submitId : 'submit'} onClick={(e) => { e.preventDefault(); handleClick(e) }} className='waves-effect waves-light btn' {...newProps}>
            {I18n.t(submitText)}
          </button>
          <button onClick={(e) => { e.preventDefault(); handleCancelClick(e) }} className='waves-effect text-m waves-light btn text-medium' {...newProps}>
            {I18n.t(cancelText)}
          </button>
        </div>
      </div>
    )
  }

  render () {
    return (<div>Implement me in a subclass</div>)
  }
}
