import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithModal } from '../../modal/ModalProvider'
import I18n from 'i18n'
import PinCode from '../../pin_code/PinCode'
import TabPanel, { Tab } from '../../common/TabPanel'
import ResilienceMonitoringDashboard from './ResilienceMonitoringDashboard'
import OverviewDashboard from './OverviewDashboard'
import PolarDashboard from 'components/visualizations/layout/PolarDashboard'

const VisualizationsOverview = props => {
  const [selectedTab, setSelectedTab] = useState('overview')

  useEffect(() => {
    props.fetchTeams()
  }, [])

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.visualizations') }]}
      />
      <PinCode>
        <div className='container larger-container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('menu.visualizations')}</h4>
            </div>
          </div>
          <div className='dashboards'>
            <TabPanel id='visualizations-tabs' onShow={tabName => setSelectedTab(tabName)}>
              <Tab id='overview' title={I18n.t('visualizations.tabs.overview.title')}>
                {selectedTab === 'overview' && <OverviewDashboard teams={props.teams} />}
              </Tab>
              <Tab id='resilience_monitoring' title={I18n.t('visualizations.tabs.resilience_monitoring.title')}>
                {selectedTab === 'resilience_monitoring' &&
                  <ResilienceMonitoringDashboard teams={props.teams} onUpdateUser={props.onUpdateUser} />}
              </Tab>
              {process.env.POLAR_ENABLED === 'true' && (
                <Tab id='polar' title={I18n.t('visualizations.tabs.polar.title')}>
                  {selectedTab === 'polar' && <PolarDashboard teams={props.teams} />}
                </Tab>
              )}
            </TabPanel>
          </div>
        </div>
      </PinCode>
    </>
  )
}

export default WithModal(WithFlashMessages(VisualizationsOverview))
