import React, { useState } from 'react'
import { MyAxios as axios } from '../MyAxios'
import ResponsesRoutes from './ResponsesRoutes'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'

const ResponsesController = props => {
  const [responses, setResponses] = useState(undefined)
  const [pageInfo, setPageInfo] = useState(undefined)
  const [teams, setTeams] = useState([])
  const [response, setResponse] = useState(undefined)

  const fetchResponses = (page = 1) => {
    setResponses(undefined)
    setPageInfo(undefined)
    const result = getResponses(page)
    result
      .then(res => {
        setResponses(res.data.data)
        setPageInfo(res.data.page)
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const fetchResponse = (id) => {
    setResponse(undefined)
    const result = getResponse(id)
    result
      .then(res => { setResponse(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const fetchTeams = () => {
    const result = getTeams()
    result
      .then(res => { setTeams(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const getTeams = () => {
    return axios.get('/api/v1/teams', { headers: { ...props.authorizationHeaders() } })
  }

  const getResponses = page => {
    return axios.get(`/api/v1/responses?page=${page}`, { headers: { ...props.authorizationHeaders() } })
  }

  const updateResponse = (id, response) => {
    return axios.patch(`/api/v1/responses/${id}`, response, { headers: { ...props.authorizationHeaders() } })
  }

  const getResponse = id => {
    return axios.get(`/api/v1/responses/${id}`, { headers: { ...props.authorizationHeaders() } })
  }

  const destroyResponse = id => {
    return axios.delete(`/api/v1/responses/${id}`, { headers: { ...props.authorizationHeaders() } })
  }

  return (
    <ResponsesRoutes
      overviewProps={{
        responses,
        pageInfo,
        fetchResponses,
        teams,
        fetchTeams,
        destroyResponse
      }}
      editProps={{
        response,
        fetchResponse,
        teams,
        fetchTeams,
        destroyResponse,
        updateResponse
      }}
    />
  )
}

export default WithFlashMessages(WithSession(ResponsesController))
