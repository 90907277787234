import React from 'react'
import { MyAxios as axios } from '../MyAxios'
import SubAccountRoutes from './SubAccountRoutes'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'
import { PIN_CODE } from '../pin_code/PinCode'
import { WithSession } from '../session/SessionProvider'
import { withRouter } from 'react-router-dom'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'

const SubAccountController = props => {
  const fetchSession = (subTokenId, subTokenToken) => {
    return axios.get(`/api/v1/sub_tokens/${subTokenId}/${subTokenToken}`)
      .then(res => {
        props.updateSession({
          sessionToken: res.headers.authorization,
          sessionAud: res.data.aud
        })
        window.localStorage.setItem(PIN_CODE, PIN_CODE)
        props.history.push('/')
      }).catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  return (
    <SubAccountRoutes
      fillOutProps={{
        fetchSession
      }}
    />
  )
}

export default WithFlashMessages(WithSession(withRouter(SubAccountController)))
