import React from 'react'
import I18n from 'i18n'
import { questionnaires } from '../../teampro/Constants'
import { allTrainingMoments, trainingMomentHint, translateTrainingMoment } from '../../teampro/Utils'
import { capitalizeFirstLetter } from '../../common/Utils'

const QuestionnaireQuestions = ({ disabledQuestionnaireKeys, setDisabledQuestionnaireKeys, updateTeamAttribute }) => {
  const toggleSelection = questionId => {
    let newDisabledQuestionnaireKeys
    if (disabledQuestionnaireKeys.includes(questionId)) {
      newDisabledQuestionnaireKeys = disabledQuestionnaireKeys.filter(qId => qId !== questionId)
    } else {
      newDisabledQuestionnaireKeys = [...disabledQuestionnaireKeys, questionId]
    }
    setDisabledQuestionnaireKeys(newDisabledQuestionnaireKeys)
    updateTeamAttribute({ disabled_questionnaire_keys: newDisabledQuestionnaireKeys })
  }

  const questionLabel = question => {
    if (I18n.locale === 'nl') {
      return question.label.nl
    }
    return question.label.en
  }

  return (
    <>
      {allTrainingMoments().map(trainingMoment => (
        <div className='row' key={trainingMoment}>
          <div className='col s12'>
            <h6>{capitalizeFirstLetter(translateTrainingMoment(trainingMoment))}</h6>
            <p><em>{trainingMomentHint(trainingMoment)}</em></p>
            {questionnaires[trainingMoment].filter(question => question.id).map(question => (
              <p key={question.id}>
                <label>
                  <input
                    type='checkbox'
                    checked={!disabledQuestionnaireKeys.includes(question.id)}
                    onChange={() => toggleSelection(question.id)}
                  />
                  <span>{questionLabel(question)}</span>
                </label>
              </p>
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

export default QuestionnaireQuestions
