import React, { useState, useEffect, useMemo } from 'react'
import Logo from '../../teampro/layout/logo.svg'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { datePickerDefaultOptions } from '../../common/Constants'
import SpinnerWrapper from '../../common/SpinnerWrapper'
import classNames from 'classnames'
import I18n from 'i18n'
import SmallGraph from './SmallGraph'
import { questionTitle } from '../../teampro/Utils'
import { useResponsesPlayerResponsesQuery } from '../../backend/Queries'
import Teams, { Team } from '../../common/types/Teams'
import { Period, PERIODS } from './Utils'
import Player from '../../common/types/Player'
import { WithSession } from '../../session/SessionProvider'
import User from '../../common/types/User'
import { flatten } from 'lodash'
import Info from '../../common/Info'
import ReactTooltip from 'react-tooltip'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import FlashMessages from '../../common/types/FlashMessages'

/* eslint-disable @typescript-eslint/explicit-function-return-type, @typescript-eslint/strict-boolean-expressions, react/jsx-boolean-value */

interface UpdateZscoreObject {
  z_score_period: Period
}

interface UpdateDynamicZScoreObject {
  dynamic_z_score: boolean
}

interface UpdateDaysDifferenceObject {
  resilience_days_difference: number
}

interface Props {
  teams: Teams
  myProfile?: User // From WithSession
  flashMessages: FlashMessages // From FlashMessageProvider
  onUpdateUser: (obj: UpdateZscoreObject | UpdateDaysDifferenceObject | UpdateDynamicZScoreObject) => void
}

const ResilienceMonitoringDashboard: React.FunctionComponent<Props> = (props: Props) => {
  // Initialize only once, when we are loading the page
  const minDate = useMemo(() => moment().subtract(12, 'months').startOf('day').toDate(), [])
  const maxDate = useMemo(() => moment(new Date()).endOf('day').toDate(), [])

  const [startDate, setStartDate] = useState<Date | null>(props.myProfile?.resilience_days_difference !== null ? moment(maxDate).subtract(props.myProfile?.resilience_days_difference, 'days').startOf('day').toDate() : minDate)
  const [endDate, setEndDate] = useState<Date | null>(maxDate)
  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([])
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<number[]>([])
  const [showZScores, setShowZScores] = useState<boolean>(false)
  const [zScorePeriod, setZScorePeriod] = useState<Period>(props.myProfile?.z_score_period ?? 'three_months')
  const [dynamicZScore, setDynamicZScore] = useState<boolean>(props.myProfile?.dynamic_z_score ?? false)

  const { data: responses = [], refetch, isSuccess, isError, error } = useResponsesPlayerResponsesQuery(selectedPlayerIds, startDate ?? minDate, endDate ?? maxDate, false)

  useEffect(() => {
    // TODO: don't ignore errors for refetch
    void refetch()
  }, [startDate, endDate, selectedPlayerIds])

  const updateStartDate = (date: Date | null) => {
    setStartDate(date)
    // If the start date exists, then set it to something.
    const daysDifference = moment(endDate ?? maxDate).diff(moment(date), 'days')
    if (daysDifference >= 0) {
      props.onUpdateUser({ resilience_days_difference: daysDifference })
    }
  }

  // Initialize date slider
  useEffect(() => {
    const dateSlider = document.getElementById('slider-date')
    if (dateSlider == null) return

    const options = {
      // Create two timestamps to define a range.
      range: {
        min: timestamp2('2010'),
        max: timestamp2('2016')
      },

      format: {
        to: formatter,
        from: function (value: any) {
          return (value)
        }
      },
      // Steps of one week
      step: 7 * 24 * 60 * 60 * 1000,

      // Two more timestamps indicate the handle starting positions.
      start: [timestamp2('2011'), timestamp2('2015')]

      // No decimals
      // format: wNumb({
      //   decimals: 0
      // })
    };

    (window as any).noUiSlider.create(dateSlider, options);
    (dateSlider as any).noUiSlider.on('slide', (values: string[], handle: number) => {
      if (handle === 0) { updateStartDate(reverseFormatterStart(values[handle])) } else if (handle === 1) { setEndDate(reverseFormatterEnd(values[handle])) } else {
        console.log('handle not recognized:')
        console.log(handle)
      }
    })
  }, [])

  useEffect(() => {
    const rangeSlider = document.getElementById('slider-date');
    (rangeSlider as any)?.noUiSlider?.updateOptions({
      range: {
        min: timestamp(minDate),
        max: timestamp(maxDate)
      },
      start: [timestamp(startDate ?? minDate), timestamp(endDate ?? maxDate)]
    }, true)
  }, [startDate, endDate])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [showZScores])

  useEffect(() => {
    if (!isError) return

    const errorTitles = Object.entries((error as any).response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k) as string}: ${(v as string[]).map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
    props.flashMessages.push(
      errorTitles,
      props.flashMessages.duration.LONG,
      props.flashMessages.levels.ERROR)
  }, [isError])

  const toggleTeamSelection = (teamId: number) => {
    if (selectedTeamIds.includes(teamId)) {
      setSelectedTeamIds(selectedTeamIds.filter(cTeamId => cTeamId !== teamId))
      const newSelectedPlayerIds = []
      const teamPlayers = props.teams.filter(team => team.id === teamId)[0]?.players
      if (!teamPlayers) return

      for (const playerId of selectedPlayerIds) {
        const player = teamPlayers.find(player => player.id === playerId)
        if (player) continue

        newSelectedPlayerIds.push(playerId)
      }
      setSelectedPlayerIds(newSelectedPlayerIds)
    } else {
      setSelectedTeamIds([...selectedTeamIds, teamId])
    }
  }

  const togglePlayerSelection = (playerId: number) => {
    if (selectedPlayerIds.includes(playerId)) {
      setSelectedPlayerIds(selectedPlayerIds.filter(cPlayerId => cPlayerId !== playerId))
    } else {
      setSelectedPlayerIds([...selectedPlayerIds, playerId])
    }
  }

  const renderTeamCheckbox = (team: Team) => {
    return (
      <p key={team.id}>
        <label>
          <input
            type='checkbox'
            checked={selectedTeamIds.includes(team.id)}
            onChange={() => toggleTeamSelection(team.id)}
          />
          <span>{team.name}</span>
        </label>
      </p>
    )
  }

  const renderPlayers = (players: Player[]) => {
    if (!players) return <></>

    return (
      players.sort((a: Player, b: Player) => (a.order_scores[zScorePeriod]?.order_score ?? 0) - (b.order_scores[zScorePeriod]?.order_score ?? 0)).map(player => (
        <a
          key={player.id}
          className={classNames('waves-effect waves-light btn-small', calculateClass(player.order_scores[zScorePeriod]?.order_score ?? 0), { selected: selectedPlayerIds.includes(player.id) })}
          onClick={() => togglePlayerSelection(player.id)}
        >
          <span>{player.name}</span>
        </a>
      )
      )
    )
  }

  const teamToPlayers = (teamId: number): Player[] => {
    return props.teams.filter(team => team.id === teamId)[0]?.players || []
  }

  const updateZScorePeriod = (period: Period) => {
    setZScorePeriod(period)
    props.onUpdateUser({ z_score_period: period })
  }

  const updateDynamicZScore = (dynamic: boolean) => {
    setDynamicZScore(dynamic)
    props.onUpdateUser({ dynamic_z_score: dynamic })
  }

  const { isClearable, ...theRest } = datePickerDefaultOptions

  return (
    <>
      <div className='row'>
        <div className='col s12 m6 l3'>
          <div className='logo'>
            <img src={Logo} alt='' />
          </div>
          <p className='no-margin-bottom'>{I18n.t('visualizations.resilience_monitoring.date')}</p>
          <div className='shorter-date-inputs'>
            <DatePicker
              id='start-date' {...theRest}
              selected={startDate}
              onChange={date => updateStartDate(date ? moment(date).startOf('day').toDate() : date)}
              selectsStart={true}
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              maxDate={maxDate}
              openToDate={startDate ?? minDate}
            />
            <DatePicker
              id='end-date' {...theRest}
              selected={endDate}
              onChange={date => setEndDate(date ? moment(date).endOf('day').toDate() : date)}
              selectsEnd={true}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate ?? minDate}
              maxDate={maxDate}
              openToDate={endDate ?? maxDate}
            />
          </div>
          <div className='slider-date-container'>
            <div id='slider-date' className='slider-date' />
          </div>
          <p>
            <label>
              <input
                type='checkbox'
                checked={showZScores}
                onChange={e => setShowZScores(e.target.checked)}
              />
              <span>{I18n.t('visualizations.resilience_monitoring.show_zscores')}</span>
            </label>
          </p>
          {showZScores && (
            <>
              {PERIODS.map(period => (
                <p key={period}>
                  <label>
                    <input
                      name='z-score-period'
                      type='radio'
                      value={period}
                      checked={zScorePeriod === period}
                      onChange={e => updateZScorePeriod(e.target.value as Period)}
                    />
                    <span>{I18n.t(`visualizations.resilience_monitoring.period.${period}`)}</span>
                  </label>
                </p>
              ))}
              <p>
                <label>
                  <input
                    type='checkbox'
                    checked={dynamicZScore}
                    onChange={e => updateDynamicZScore(e.target.checked)}
                  />
                  <span>
                    {I18n.t('visualizations.resilience_monitoring.dynamic_z_score')}
                    {' '}
                    <Info
                      text={I18n.t('visualizations.resilience_monitoring.dynamic_z_score_tooltip')}
                      tooltipId='higher-z-tooltip'
                      reuseTooltip
                    />
                  </span>
                </label>
              </p>
            </>
          )}
          <p>{I18n.t('visualizations.resilience_monitoring.teams')}</p>
          <div className='teams-checkboxes'>
            {props.teams.length === 0 && <p><em>{I18n.t('teampro.manager.there_are_no_teams_click')}</em></p>}
            {props.teams.length > 0 && props.teams.map(cteam => renderTeamCheckbox(cteam))}
          </div>
        </div>
        <div className='col s12 m12 l6'>
          <p>{I18n.t('visualizations.resilience_monitoring.players')}</p>
          <div className='player-buttons'>
            {renderPlayers(flatten(selectedTeamIds.map(teamId => teamToPlayers(teamId))))}
          </div>
        </div>
        <div className='col s12 m6 l3 margin-bottom'>
          <p className='plot-title'>{questionTitle('v2')}</p>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <SmallGraph
              graph='self_efficacy' data={responses} showZScores={showZScores} teams={props.teams} zScorePeriod={zScorePeriod}
              dynamicZScore={dynamicZScore}
            />
          </SpinnerWrapper>
        </div>
        <div className='col s12 m6 l3 margin-bottom'>
          <p className='plot-title'>{questionTitle('v3')}</p>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <SmallGraph
              graph='motivation' data={responses} showZScores={showZScores} teams={props.teams}
              zScorePeriod={zScorePeriod} dynamicZScore={dynamicZScore}
            />
          </SpinnerWrapper>
        </div>
        <div className='col s12 m6 l3 margin-bottom'>
          <p className='plot-title'>{questionTitle('v4')}</p>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <SmallGraph
              graph='mood' data={responses} showZScores={showZScores} teams={props.teams}
              zScorePeriod={zScorePeriod} dynamicZScore={dynamicZScore}
            />
          </SpinnerWrapper>
        </div>
        <div className='col s12 m6 l3 margin-bottom'>
          <p className='plot-title'>{questionTitle('v6')}</p>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <SmallGraph
              graph='performance' data={responses} showZScores={showZScores} teams={props.teams}
              zScorePeriod={zScorePeriod} dynamicZScore={dynamicZScore}
            />
          </SpinnerWrapper>
        </div>
        <div className='col s12 m6 l3 margin-bottom'>
          <p className='plot-title'>{questionTitle('v7')}</p>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <SmallGraph
              graph='enjoyment' data={responses} showZScores={showZScores} teams={props.teams}
              zScorePeriod={zScorePeriod} dynamicZScore={dynamicZScore}
            />
          </SpinnerWrapper>
        </div>
        <div className='col s12 m6 l3 margin-bottom'>
          <p className='plot-title'>{questionTitle('v5')}</p>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <SmallGraph
              graph='intensity' data={responses} showZScores={showZScores} teams={props.teams}
              zScorePeriod={zScorePeriod} dynamicZScore={dynamicZScore} yMax={20} yMin={6}
            />
          </SpinnerWrapper>
        </div>
        <div className='col s12 m6 l3 margin-bottom'>
          <p className='plot-title'>{questionTitle('v1')}</p>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <SmallGraph
              graph='recovery' data={responses} showZScores={showZScores} teams={props.teams}
              zScorePeriod={zScorePeriod} dynamicZScore={dynamicZScore} yMax={20} yMin={6}
            />
          </SpinnerWrapper>
        </div>
      </div>
    </>
  )
}

const timestamp = (dte: Date): number => {
  return dte.getTime()
}

const timestamp2 = (str: string): number => {
  return new Date(str).getTime()
}

const formatter = (seconds: moment.MomentInput): string => {
  return moment(seconds).format('DD-MM-YYYY')
}

const reverseFormatterStart = (str: string): Date => {
  return moment(str, 'DD-MM-YYYY').startOf('day').toDate()
}
const reverseFormatterEnd = (str: string): Date => {
  return moment(str, 'DD-MM-YYYY').endOf('day').toDate()
}

export const calculateClass = (num: number): string => {
  if (num < -2) return 'red-ranking'
  if (num < -1) return 'yellow-ranking'
  if (Math.abs(num) < 0.0001) return ''
  if (num < 1) return 'green-ranking'
  return ''
}

export default WithSession(WithFlashMessages(ResilienceMonitoringDashboard)) as typeof ResilienceMonitoringDashboard
