import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../common/Breadcrumbs'
import PlayerName from '../../teampro/layout/PlayerName'
import { WithModal } from '../../modal/ModalProvider'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import I18n from 'i18n'
import PinCode from '../../pin_code/PinCode'
import Info from '../../common/Info'
import QuestionnaireQuestions from './QuestionnaireQuestions'

const EditTeam = (props) => {
  const { match: { params: { teamId } } } = props
  const team = props.teams.filter(team => `${team.id}` === teamId)[0]
  const [teamName, setTeamName] = useState('')
  const [disabledQuestionnaireKeys, setDisabledQuestionnaireKeys] = useState([])

  useEffect(() => {
    props.fetchTeams()
  }, [])

  useEffect(() => {
    const elems = document.querySelectorAll('.fixed-action-btn')
    window.M.FloatingActionButton.init(elems, {})
  }, [])

  useEffect(() => {
    window.M.updateTextFields()
  })

  useEffect(() => {
    if (team === undefined) return
    setTeamName(team.name)
    setDisabledQuestionnaireKeys(team.disabled_questionnaire_keys)
  }, [props.teams])

  if (team === undefined) return <></>

  const updateTeam = e => {
    e.preventDefault()
    updateTeamAttribute({ name: teamName })
  }

  const updateTeamAttribute = teamObj => {
    props.updateTeam(team.id, { team: teamObj })
      .then(() => {
        props.flashMessages.push(
          I18n.t('teams.flash_messages.team_updated'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.INFO)
        props.fetchTeams()
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const editPlayer = id => {
    props.history.push(`/players/${id}/edit`)
  }

  const destroyPlayer = id => {
    props.destroyPlayer(id)
      .then(() => {
        props.flashMessages.push(
          I18n.t('teams.flash_messages.player_deleted'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.INFO)
        props.fetchTeams()
      })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const showDestroyPlayerDialog = id => {
    props.setConfirmationDialog({
      onConfirmation: () => destroyPlayer(id),
      target: `${I18n.t('teams.edit_team.player')} ${team.players.filter(player => player.id === id)[0].name}`,
      action: 'delete',
      noQuotes: true
    })
  }

  const newPlayer = (e) => {
    e.preventDefault()
    props.history.push(`/teams/${team.id}/players/new`)
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: I18n.t('menu.manager_dashboard'), link: '/' }, { name: I18n.t('menu.teams'), link: '/teams' }, { name: team.name }]}
      />
      <PinCode>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h4>{I18n.t('teams.edit_team.edit_team', { name: team.name })}</h4>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6'>
              <input
                id='name'
                type='text'
                className='validate'
                value={teamName}
                onChange={e => setTeamName(e.target.value)}
              />
              <label htmlFor='name'>{I18n.t('teams.new_team.name')}</label>
            </div>
            <div className='input-field col s12 m6'>
              <button type='submit' className='btn waves-effect waves-light' onClick={updateTeam}>
                {I18n.t('teams.edit_team.update_team_name')}
              </button>
            </div>
          </div>
          <div className='fixed-action-btn'>
            <a className='btn-floating btn-large red' onClick={newPlayer}>
              <i className='large material-icons'>add</i>
            </a>
          </div>
          <div className='row'>
            <div className='col s12'>
              <ul className='collection with-header'>
                <li className='collection-header'><h4>{I18n.t('teams.edit_team.players')}</h4></li>
                {team.players.length === 0 && (
                  <div className='col s12'>
                    <p><em>{I18n.t('teams.edit_team.there_are_no_players_click_to_add')}</em></p>
                  </div>
                )}
                {team.players.map(player => {
                  return (
                    <li key={player.id} className='collection-item'>
                      <div>
                        <span className='pointer' onClick={() => editPlayer(player.id)}>
                          <PlayerName player={player} />
                        </span>
                        <a
                          href='#!'
                          className='secondary-content margin-left'
                          title={I18n.t('teams.edit_team.edit_player')}
                          onClick={e => {
                            e.preventDefault()
                            editPlayer(player.id)
                          }}
                        >
                          <i className='material-icons'>edit</i>
                        </a>
                        <a
                          href='#!'
                          className='secondary-content'
                          title={I18n.t('teams.edit_team.delete_player')}
                          onClick={e => {
                            e.preventDefault()
                            showDestroyPlayerDialog(player.id)
                          }}
                        >
                          <i className='material-icons'>delete</i>
                        </a>
                        {player.polar_players.length > 0 && (
                          <span className='secondary-content' style={{ marginRight: '10px' }}>
                            {player.polar_players.map(polarPlayer => `${polarPlayer.player_number} ${polarPlayer.first_name} ${polarPlayer.last_name}`).join(', ')}
                          </span>
                        )}
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          {process.env.ENABLE_QUESTIONNAIRE_SELECTIONS === 'true' && (
            <div className='row section'>
              <div className='col s12'>
                <h5>{I18n.t('profile.edit.questionnaire_questions')} <Info text={I18n.t('profile.edit.questionnaire_questions_tooltip')} tooltipId='questionnaire-questions-tooltip' /></h5>
              </div>
              <div className='col s12'>
                <QuestionnaireQuestions
                  disabledQuestionnaireKeys={disabledQuestionnaireKeys}
                  setDisabledQuestionnaireKeys={setDisabledQuestionnaireKeys}
                  updateTeamAttribute={updateTeamAttribute}
                />
              </div>
            </div>
          )}
        </div>
      </PinCode>
    </>
  )
}

export default WithModal(WithFlashMessages(EditTeam))
